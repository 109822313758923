import { isArray, isFunction, isNil } from 'lodash';

import checkIsMobile from '@ftf-old/util/checkIsMobile';

export function trackEvent({ event: eventName, ...rest }, callback) {
  if (isArray(window.dataLayer)) {
    const event = {
      event: eventName,
      eventCallback: callback,
      ...rest,
    };

    window.dataLayer.push(event);
  }

  if (window.isGTMLoaded) {
    return;
  }

  if (isFunction(callback)) {
    callback();
  }
}

export function trackGAEvent({ category, action, label, value }, callback) {
  if (isNil(window.ga) || !window.ga.loaded) {
    if (isFunction(callback)) {
      callback();
    }

    return;
  }

  const isMobile = checkIsMobile();

  const event = {
    event: 'fireAway',
    eventAction: action,
    eventCategory: isMobile ? `mobile-${category}` : category,
    eventLabel: label,
    eventValue: value,
  };

  trackEvent(event, callback);
}
