import { Controller } from 'stimulus';

export default class BuyBoxComparison extends Controller {
  static targets = [
    'buyBoxMatchDisplay',
    'bbMatchChevron',
    'toolPanel',
    'fullMatchTab',
    'partialMatchTab',
    'noMatchTab',
  ];

  connect() {
    this.toolPanelTargets.forEach(el => {
      el.addEventListener('click', this.toggleToolPanels);
    });

    this.setBbVisibility();

    window.addEventListener('SendBuyBoxData', this.dispatchBuyBox);
  }

  disconnect() {
    this.toolPanelTargets.forEach(el => {
      el.removeEventListener('click', this.toggleToolPanels);
    });

    window.removeEventListener('SendBuyBoxData', this.dispatchBuyBox);
  }

  toggleToolPanels = e => {
    if (e.target.dataset.name === 'showBuyBoxMatches') {
      this.toggleBbMatches();
    }
    if (e.target.dataset.name === 'refreshMatches') {
      this.showMatches();
    }
  };

  toggleBbMatches() {
    this.buyBoxMatchDisplayTarget.classList.toggle('d-none');
    this.bbMatchChevronTarget.classList.toggle('chev-rotated');
    this.setBbVisibility();
  }

  showMatches() {
    this.buyBoxMatchDisplayTarget.classList.remove('d-none');
  }

  // keeps buy box open on version change
  setBbVisibility() {
    if (this.buyBoxMatchDisplayTarget.classList.contains('d-none')) {
      localStorage.setItem('buyBoxVisibility', 'closed');
    } else {
      localStorage.setItem('buyBoxVisibility', 'open');
    }
  }

  dispatchBuyBox = e => {
    const self = this;
    this.buyBoxMatchDisplayTarget.classList.remove('d-none');
    const buyBoxLoanData = e.detail;

    $.ajax({
      url: '/admin/buyer_metrics/compare_loan_to_buybox',
      method: 'GET',
      data: buyBoxLoanData,
      success: function(res) {
        self.populateMatchTabs(res);
        self.populateNoMatchTabContainer(res);
        self.populatePartialMatchTabContainer(res);
        self.populateFullMatchTabContainer(res);
      },
      error: function(xhr, status, error) {
        /* eslint-disable */
        console.error('AJAX request failed:', error);
        /* eslint-enable */
      },
    });
  };

  populateMatchTabs = res => {
    const textForFullMatches = `(${
      res.full_matches.length === 0 ? 0 : res.full_matches.length
    })`;
    const textForPartialMatches = `(${
      res.partial_matches.length === 0 ? 0 : res.partial_matches.length
    })`;
    const textforNoMatches = `(${
      res.no_matches.length === 0 ? 0 : res.no_matches.length
    })`;

    this.fullMatchTabTarget.textContent = textForFullMatches;
    this.partialMatchTabTarget.textContent = textForPartialMatches;
    this.noMatchTabTarget.textContent = textforNoMatches;
  };

  populateNoMatchTabContainer = res => {
    const container = document.querySelector('#no-matches-container');
    const noMatches = res.no_matches;

    container.innerHTML = '';

    if (noMatches.length === 0) {
      const noMatchesElement = document.createElement('div');
      noMatchesElement.textContent =
        'There are no fully incompatible buy boxes for this deal.';
      noMatchesElement.classList.add('container');

      container.appendChild(noMatchesElement);
    } else {
      noMatches.forEach((buyBox, buyBoxIndex) => {
        const noMatchCard = document.createElement('div');
        noMatchCard.classList.add('w-100', 'border', 'p-1', 'my-2', 'rounded');
        noMatchCard.id = `no-match-card-${buyBoxIndex}`;

        const buyBoxPartnerName = document.createElement('h5');
        buyBoxPartnerName.classList.add('text-primary', 'mb-1');
        buyBoxPartnerName.innerText = `${
          buyBox.data.attributes.institution
            ? buyBox.data.attributes.institution.name
            : 'Retail Platform'
        }`;
        noMatchCard.appendChild(buyBoxPartnerName);

        if (
          buyBox.data.attributes.metric_matches.unmatched_metrics.length > 0
        ) {
          const incompatibleMatches = document.createElement('div');
          incompatibleMatches.classList.add('text-danger', 'mb-2');
          incompatibleMatches.innerText = 'INCOMPATIBLE METRICS:';
          noMatchCard.appendChild(incompatibleMatches);
        }

        const tabsContainer = document.createElement('div');
        tabsContainer.id = `no-matches-${
          buyBox.data.attributes.institution
            ? buyBox.data.attributes.institution.id
            : 'retail-platform'
        }`;
        noMatchCard.appendChild(tabsContainer);
        container.appendChild(noMatchCard);
        this.buildVerticalTabElements(tabsContainer.id);

        const tabsArr = [];
        buyBox.data.attributes.metric_matches.unmatched_metrics.forEach(i => {
          tabsArr.push(i.metric_label);
        });
        this.buildVerticalTabs(tabsArr, `${tabsContainer.id}`);

        const noMatchContent = buyBox.data.attributes.metric_matches.unmatched_metrics.map(
          i =>
            `
          <div class="d-flex flex-column justify-center align-items-center">
            <div class="d-flex justify-content-between my-1 w-100">
              <div>Selected ${i.metric_label}:</div>
              <div class="text-right">${this.formattedBbVal(
                i.metric_label,
                i.metric_value,
              )}</div>
            </div>
            <div class="d-flex justify-content-between my-1 w-100">
              <div>Acceptable ${i.metric_label}:</div>
              <div class="text-success text-right">${i.bb_values}</div>
            </div>
          </div>
          `,
        );
        this.buildVerticalTabContent(
          tabsArr,
          `${tabsContainer.id}`,
          noMatchContent,
        );
      });
    }
  };

  populatePartialMatchTabContainer = res => {
    const container = document.querySelector('#partial-matches-container');
    container.innerHTML = '';
    const partialMatches = res.partial_matches;

    if (partialMatches.length === 0) {
      const noMatchesElement = document.createElement('div');
      noMatchesElement.textContent =
        'There are no partial matches for this deal.';
      noMatchesElement.classList.add('container');

      container.appendChild(noMatchesElement);
    } else {
      partialMatches.forEach(buyBox => {
        const partialItem = document.createElement('div');
        partialItem.classList.add('w-100', 'border', 'p-1', 'my-2', 'rounded');
        partialItem.id = `partial-match-${buyBox.data.attributes.name}`;

        const buyBoxPartnerName = document.createElement('div');
        buyBoxPartnerName.classList.add('text-primary', 'font-weight-bold');
        buyBoxPartnerName.innerText = `${
          buyBox.data.attributes.institution
            ? buyBox.data.attributes.institution.name
            : 'Retail Platform'
        }`;
        partialItem.appendChild(buyBoxPartnerName);

        const buyBoxMatchedMetrics = document.createElement('div');
        const numberOfMetricsMatched =
          buyBox.data.attributes.metric_matches.matched_metrics.length;
        const totalMetricCount = buyBox.data.attributes.metrics_count;
        buyBoxMatchedMetrics.classList.add('text-secondary');
        const percOfMetricsMatched = Math.round(
          (numberOfMetricsMatched / totalMetricCount) * 100,
        );
        buyBoxMatchedMetrics.innerText = `${numberOfMetricsMatched}/${totalMetricCount} METRICS MATCHED (${percOfMetricsMatched}%)`;
        partialItem.appendChild(buyBoxMatchedMetrics);

        const outOfRangeMetrics = document.createElement('div');
        outOfRangeMetrics.classList.add('text-danger', 'mb-2');
        outOfRangeMetrics.innerText = 'OUT OF RANGE:';
        partialItem.appendChild(outOfRangeMetrics);

        container.appendChild(partialItem);

        const partialMatchesTabsContainer = document.createElement('div');
        partialMatchesTabsContainer.id = `partial-matches-${
          buyBox.data.attributes.institution
            ? buyBox.data.attributes.institution.id
            : 'retail-platform'
        }`;

        partialItem.appendChild(partialMatchesTabsContainer);
        this.buildVerticalTabElements(partialMatchesTabsContainer.id);

        const tabsArr = [];

        buyBox.data.attributes.metric_matches.unmatched_metrics.forEach(i => {
          tabsArr.push(i.metric_label);
        });
        this.buildVerticalTabs(tabsArr, `${partialMatchesTabsContainer.id}`);

        const partialMatchesTabContent = buyBox.data.attributes.metric_matches.unmatched_metrics.map(
          i => {
            let bbMetricSpecificPrefix = '';
            let bbMetricSpecificSuffix = '';
            let assumedMetricSpecificPrefix = '';
            let metricName = '';

            // Sets the prefix / suffixes to describe the buy box metrics being compared
            if (['INTEREST RATE', 'CREDIT SCORE'].includes(i.metric_label)) {
              bbMetricSpecificPrefix = 'Minimum';
            } else if (
              ['LTARV', 'LTAIV', 'LTC', 'LTCAC'].includes(i.metric_label)
            ) {
              bbMetricSpecificPrefix = 'Maximum';
            } else if (['LOAN AMOUNT'].includes(i.metric_label)) {
              bbMetricSpecificSuffix = 'Range';
            } else {
              bbMetricSpecificPrefix = 'Acceptable';
            }

            //Sets the prefixes to describe the user metrics being compared to bb metrics
            if (['CREDIT SCORE'].includes(i.metric_label)) {
              assumedMetricSpecificPrefix = 'Assumed';
            } else if (
              ['Interest Rate', 'Loan Amount', 'Credit Score'].includes(
                i.metric_label,
              )
            ) {
              assumedMetricSpecificPrefix = 'Proposed';
            } else if (
              ['LTARV', 'LTAIV', 'LTC', 'LTCAC'].includes(i.metric_label)
            ) {
              assumedMetricSpecificPrefix = 'Calculated';
            } else if (
              ['Property Type', 'Project Type'].includes(i.metric_label)
            ) {
              assumedMetricSpecificPrefix = 'Selected';
            }

            //Sets the full metric names to be displayed in the buy box itself
            switch (i.metric_label.toLowerCase()) {
              case 'ltaiv':
                metricName = 'Loan To As-Is Value (LTAIV)';
                break;
              case 'ltarv':
                metricName = 'Loan To After Repair Value (LTARV)';
                break;
              case 'ltc':
                metricName = 'Loan To Cost (LTC)';
                break;
              case 'ltcac':
                metricName = 'Loan To Cost At Close (LTCAC)';
                break;
              case 'loan amount':
                metricName = 'Loan Amount';
                break;
              case 'credit score':
                metricName = 'Credit Score';
                break;
              case 'interest rate':
                metricName = 'Interest Rate';
                break;
              case 'project type':
                metricName = 'Project Type';
                break;
              case 'property type':
                metricName = 'Property Type';
                break;
              default:
                metricName = i.metric_label.toLowerCase();
            }

            return `
            <div class="d-flex flex-column justify-center align-items-center">
              <div class="d-flex justify-content-between my-1 w-100">
                <div>${assumedMetricSpecificPrefix} ${metricName}:</div>
                <div>
                  <span class="oneline-text">
                    ${this.formattedBbVal(i.metric_label, i.metric_value)}
                  </span>
                </div>
              </div>
              <div class="d-flex justify-content-between my-1 w-100">
                <div>${bbMetricSpecificPrefix} ${metricName}${' ' +
              bbMetricSpecificSuffix}:</div>
                <div class="text-success text-right">${this.partialMatchValue(
                  i.metric_label,
                  i.bb_values,
                )}</div>
              </div>
            </div>
            `;
          },
        );
        this.buildVerticalTabContent(
          tabsArr,
          `${partialMatchesTabsContainer.id}`,
          partialMatchesTabContent,
        );
      });
    }
  };

  partialMatchValue(metricLabel, bbValues) {
    if (bbValues?.length > 1) {
      let firstValue =
        '$' +
        Number(bbValues[0]).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });

      let secondValue =
        '$' +
        Number(bbValues[1]).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      return firstValue + ' - ' + secondValue;
    } else if (!bbValues || bbValues[0] === '' || bbValues[0] === null) {
      return 'Not Set';
    } else {
      return this.formattedBbVal(metricLabel, bbValues);
    }
  }

  formattedBbVal(metricLabel, value) {
    let displayVal = '';

    if (value === null || value === '') {
      displayVal = 'Not Set';
      return displayVal;
    }

    // handles display of prop & proj type values
    if (isNaN(value)) {
      if (
        metricLabel.toLowerCase() === 'property type' ||
        metricLabel.toLowerCase() === 'project type'
      ) {
        displayVal = value;
      } else {
        displayVal = 'Not Set';
      }
      return displayVal;
    }

    switch (metricLabel.toLowerCase()) {
      case 'ltaiv':
      case 'ltarv':
      case 'ltc':
      case 'ltcac':
      case 'interest rate':
        displayVal =
          Number(value).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) + '%';
        break;
      case 'credit score':
        displayVal = Number(value).toLocaleString(undefined, {
          maximumFractionDigits: 0,
        });
        break;
      case 'loan amount':
        displayVal =
          '$' +
          Number(value).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        break;
      default:
        displayVal = value;
        break;
    }
    return displayVal;
  }

  populateFullMatchTabContainer = res => {
    const container = document.querySelector('#full-matches-container');
    container.innerHTML = '';
    const fullMatches = res.full_matches;

    if (fullMatches.length === 0) {
      const fullMatchesElement = document.createElement('div');
      fullMatchesElement.textContent =
        'There are no full matches for this deal.';
      fullMatchesElement.classList.add('container');

      const potentialBuyersText = '0 Possible Buyers';
      const potentialBuyersElement = document.querySelector(
        '#potential-buyers-count',
      );
      potentialBuyersElement.innerHTML = '';
      potentialBuyersElement.textContent = potentialBuyersText;

      container.appendChild(fullMatchesElement);
    } else {
      const potentialBuyersText = `${fullMatches.length} Possible Buyers`;
      const potentialBuyersElement = document.querySelector(
        '#potential-buyers-count',
      );
      potentialBuyersElement.innerHTML = '';
      potentialBuyersElement.textContent = potentialBuyersText;

      fullMatches.forEach(buyBox => {
        const buyerContainer = document.createElement('div');
        buyerContainer.classList.add('bb-match-display', 'container');

        const institutionName = buyBox.data.attributes.institution.name;
        const institutionElement = document.createElement('h5');
        institutionElement.textContent = institutionName;
        const numberOfMetricsMatched =
          buyBox.data.attributes.metric_matches.matched_metrics.length;
        const totalMetricCount = buyBox.data.attributes.metrics_count;
        const percOfMetricsMatched = Math.round(
          (numberOfMetricsMatched / totalMetricCount) * 100,
        );
        const matchedMetricsText = `${numberOfMetricsMatched}/${totalMetricCount} METRICS MATCHED (${percOfMetricsMatched}%)`;
        const matchedMetricsElement = document.createElement('div');
        matchedMetricsElement.textContent = matchedMetricsText;

        buyerContainer.appendChild(institutionElement);
        buyerContainer.appendChild(matchedMetricsElement);
        container.appendChild(buyerContainer);
      });
    }
  };

  buildVerticalTabElements = tabsContainerId => {
    const tabsContainer = document.querySelector(`#${tabsContainerId}`);
    const parentContainer = document.createElement('div');
    parentContainer.classList.add('d-flex');
    parentContainer.id = `${tabsContainerId}-vertical-tab`;
    tabsContainer.appendChild(parentContainer);

    const navContainer = document.createElement('div');
    navContainer.classList.add('nav', 'flex-column', 'nav-pills');
    navContainer.id = `${tabsContainerId}-v-pills-tab`;
    navContainer.setAttribute('role', 'tablist');
    navContainer.setAttribute('aria-orientation', 'vertical');
    parentContainer.appendChild(navContainer);

    const tabContentContainer = document.createElement('div');
    tabContentContainer.classList.add(
      'tab-content',
      'px-2',
      'flex-grow-1',
      'd-flex',
      'flex-column',
      'justify-content-center',
      'rounded',
    );
    tabContentContainer.style.backgroundColor = '#F0FAFA';
    tabContentContainer.id = `${tabsContainerId}-v-pills-tabContent`;
    parentContainer.appendChild(tabContentContainer);
  };

  buildVerticalTabs = (tabs, id) => {
    tabs.forEach((tab, index) => {
      const tabName = tab;
      const tabNameFormatted = tabName.toLowerCase().replace(/\s+/g, '-');
      const tabContainer = document.querySelector(`#${id}-v-pills-tab`);
      const activeClass = index === 0 ? 'active' : '';

      const tabLink = `<a class="nav-link p-1 ${activeClass}" id="v-pills-${tabNameFormatted}-${id}" data-action="click->BuyBoxComparison#toggleVerticalTab" data-toggle="pill" href="#v-pills-${tabNameFormatted}-${id}" role="tab" aria-controls="v-pills-${tabNameFormatted}-${id}" aria-selected="${activeClass ===
        'active'}">${tabName}</a>`;
      tabContainer.insertAdjacentHTML(
        index === 0 ? 'afterbegin' : 'beforeend',
        tabLink,
      );
    });
  };

  buildVerticalTabContent = (tabs, id, contents) => {
    tabs.forEach((tab, index) => {
      const tabName = tab;
      const tabNameFormatted = tabName.toLowerCase().replace(/\s+/g, '-');
      const tabContentContainer = document.querySelector(
        `#${id}-v-pills-tabContent`,
      );
      const activeClass = index === 0 ? 'show active' : '';

      const tabContentWrapper = `<div class="tab-pane fade ${activeClass}" id="v-pills-${tabNameFormatted}-${id}" role="tabpanel">${contents[index]}</div>`;
      tabContentContainer.insertAdjacentHTML(
        index === 0 ? 'afterbegin' : 'beforeend',
        tabContentWrapper,
      );
    });
  };

  toggleVerticalTab(ele) {
    const tabNav = document.querySelector(`#${ele.target.parentNode.id}`);
    const tabs = tabNav.querySelectorAll('[role="tab"]');

    tabs.forEach(tab => {
      tab.classList.remove('show', 'active');
    });

    const formattedId = ele.target.parentNode.id.replace('tab', 'tabContent');
    const contentContainer = document.querySelector(`#${formattedId}`);
    const contents = contentContainer.querySelectorAll('[role="tabpanel"]');

    contents.forEach(content => {
      content.classList.remove('show', 'active');
    });

    const selectedContent = ele.target.href.split('#')[1];

    const content = contentContainer.querySelector(`#${selectedContent}`);
    content.classList.add('show', 'active');
  }
}
