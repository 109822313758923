import React from 'react';
import { string } from 'prop-types';

import { Root, Spinner } from './styles';

export function SpinnerWithMessage({ className, message }) {
  return (
    <Root className={className}>
      <Spinner className="fa fa-spinner fa-spin fa-1x fa-fw" />
      {message}
    </Root>
  );
}

SpinnerWithMessage.propTypes = {
  className: string,
  message: string.isRequired,
};
