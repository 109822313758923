import { Controller } from 'stimulus';

const withRequiredOption = ['closing_scheduled', 'closing_specialist_audit'];
export default class NewTaskTemplate extends Controller {
  static targets = ['requiredTaskCheckbox', 'workflowStatus'];

  connect() {
    this.workflowStatusTarget.addEventListener(
      'change',
      this.addRequiredCheckbox,
    );

    if (withRequiredOption.includes(this.workflowStatusTarget.value)) {
      this.requiredTaskCheckboxTarget.classList.remove('hidden');
    }
  }

  disconnect() {
    this.workflowStatusTarget.removeEventListener(
      'change',
      this.addRequiredCheckbox,
    );
  }

  addRequiredCheckbox = e => {
    if (withRequiredOption.includes(e.target.value)) {
      this.requiredTaskCheckboxTarget.classList.remove('hidden');
    } else {
      this.requiredTaskCheckboxTarget.classList.add('hidden');
    }
  };
}
