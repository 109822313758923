import { Controller } from 'stimulus';
import flatpickr from 'flatpickr';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';

export default class ChargeFilters extends Controller {
  static targets = ['datePicker'];

  flatpickrInstances = [];

  connect() {
    this.datePickerTargets.forEach((dateInput, index) => {
      this.flatpickrInstances[index] = flatpickr(dateInput, {
        minDate: '2000-01',
        allowInput: true,
        plugins: [
          new monthSelectPlugin({
            dateFormat: 'Y-m',
            shorthand: true,
          }),
        ],
      });
    });
  }

  enableAmount() {
    $(this.amountInputTarget).prop('disabled', false);
  }

  disableAmount() {
    $(this.amountInputTarget).prop('disabled', true);
  }

  disconnect() {
    this.flatpickrInstances.forEach(instance => {
      instance.destroy();
    });
  }
}
