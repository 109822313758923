import { Controller } from 'stimulus';

import checkIsMobile from '@ftf-old/util/checkIsMobile';
export default class AllTasksFilter extends Controller {
  static targets = ['assignee', 'status', 'dealStatus'];

  connect() {
    $(this.assigneeTarget).selectpicker({
      mobile: checkIsMobile(),
      noneSelectedText: 'Assignee',
    });

    $(this.statusTarget).selectpicker({
      mobile: checkIsMobile(),
      noneSelectedText: 'Task Statuses',
    });

    $(this.dealStatusTarget).selectpicker({
      mobile: checkIsMobile(),
      noneSelectedText: 'Deal Statuses',
    });
  }

  disconnect() {
    $(this.assigneeTarget).selectpicker('destroy');

    $(this.statusTarget).selectpicker('destroy');

    $(this.dealStatusTarget).selectpicker('destroy');
  }
}
