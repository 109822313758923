import queryString from 'query-string';
import { Controller } from 'stimulus';

import { trackEvent, trackGAEvent } from '@ftf-old/main/tracking';
import ApiCaller from '@ftf-old/util/ApiCaller';
import { reportError } from '@ftf/lib/reporting';

export default class InvestNowModal extends Controller {
  static targets = ['iframe'];

  amount = undefined;

  connect() {
    window.addEventListener('InvestNowModal:open', this.open);
    window.addEventListener('InvestNowModal:close', this.close);
    window.addEventListener('message', this.handleIframeMessage);

    $(this.element).on('shown.bs.modal', this.handleOpen);
    $(this.element).on('hidden.bs.modal', this.handleClose);
  }

  disconnect() {
    window.removeEventListener('InvestNowModal:open', this.open);
    window.removeEventListener('InvestNowModal:close', this.close);
    window.removeEventListener('message', this.handleIframeMessage);

    $(this.element).off('shown.bs.modal', this.handleOpen);
    $(this.element).off('hidden.bs.modal', this.handleClose);
  }

  open = event => {
    this.amount = event.detail.amount;

    const {
      radixUrl,
      offeringRadixId,
      offeringApr,
      offeringTermRemaining,
      userRadixId,
      userRadixToken,
    } = this.element.dataset;

    const query = queryString.stringify({
      token: userRadixToken,
      offering_apr: offeringApr,
      offering_term_remaining: offeringTermRemaining,
      'investment[offering_id]': offeringRadixId,
      'investment[investor_id]': userRadixId,
      'investment[amount]': event.detail.amount,
    });
    this.iframeTarget.src = `${radixUrl}/new?${query}`;

    $(this.element).modal('show');
  };

  handleOpen = () => {
    window.dispatchEvent(new Event('InvestNowModal:onSuccess'));
  };

  close = () => {
    $(this.element).modal('hide');
  };

  handleClose = () => {
    window.dispatchEvent(new Event('InvestNowModal:onClose'));
  };

  handleIframeMessage = ({ data }) => {
    switch (data.message) {
      case 'radix/investment/close':
        this.close();
        break;

      case 'radix/entity/new':
        this.handleCreateEntity();
        break;

      case 'radix/account/new':
        this.handleCreateAccount();
        break;

      case 'radix/investment/create':
        this.trackInvestmentCreated();
        break;

      case 'radix/investment/submitted':
        this.handleInvestMessage(data);
        break;

      default:
        break;
    }
  };

  handleCreateEntity = () => {
    const { userId } = this.element.dataset;
    const url = `/dashboard/${userId}/lender/entities/new`;

    trackGAEvent({ category: 'invest', action: 'clicked-add-entity' }, () => {
      document.location = url;
    });
  };

  handleCreateAccount = () => {
    const { userId } = this.element.dataset;
    const url = `/dashboard/${userId}/lender/entities`;

    trackGAEvent(
      { category: 'invest', action: 'clicked-add-bank-account' },
      () => {
        document.location = url;
      },
    );
  };

  trackInvestmentCreated = () => {
    trackGAEvent({ category: 'invest', action: 'clicked-sign-and-invest' });
  };

  handleInvestMessage = async ({ investment }) => {
    if (!investment) return;

    let persistedInvestmentId;
    let accreditationTypesCategories = [];

    try {
      const response = await ApiCaller.post('radix/investments', {
        investment,
      });
      persistedInvestmentId = response.data.investment.id;
      accreditationTypesCategories = response.data.accreditation_types;
    } catch (error) {
      reportError(error);
    }

    // @note: set investor accreditation or accreditation type on investor for Horizon Fund doc.
    // @see: User#current_accreditation_status
    const accreditationTypes = accreditationTypesCategories.reduce(
      (accTypes, category) => {
        accTypes += `&accreditation_type_${category}=true`;
        return accTypes;
      },
      '',
    );

    const {
      redirectUrl,
      legacyRedirectUrl,
      offeringId,
      offeringType,
    } = this.element.dataset;

    // @note: redirectTo user lands back on FTF "investment summary" page after signing document.
    const redirectTo = persistedInvestmentId
      ? `${redirectUrl}/${persistedInvestmentId}/summary`
      : legacyRedirectUrl;

    // @note: backwards-compatibility for Horizon Fund.
    //  if underlying offering is not a BDN or Series Note route thru old flow; show modal.
    const baseUrl = `${investment.subscription_agreement.sign_url}&redirect_url=${redirectTo}`;

    // @note: redirects investor to capture his signature
    // @note: conditionally include accreditation type for managed
    const url = `${baseUrl}${
      accreditationTypesCategories.length && offeringType === 'managed_fund'
        ? `${accreditationTypes}`
        : ''
    }`;

    // sends investment creation event to our analytics service
    trackEvent(
      {
        event: 'investment_created',
        investmentAmount: this.amount,
        offeringId,
        offeringType,
      },
      () => {
        document.location = url;
        setTimeout(() => {
          $('#invest-now-modal').modal('hide');
        }, 45000);
      },
    );
  };
}
