import { Controller } from 'stimulus';
import { debounce } from 'lodash';

import { handleAJAXError, handleInfo } from '@ftf/lib/alerts';

export default class TasksForm extends Controller {
  initialize() {
    this.debouncedHandleInput = debounce(this.handleInput, 300);
  }

  connect() {
    $(this.element).on({
      input: this.debouncedHandleInput,
      'ajax:before': this.handleAJAXBefore,
      'ajax:error': handleAJAXError,
      'ajax:success': this.handleAJAXSuccess,
    });
  }

  disconnect() {
    $(this.element).off({
      input: this.debouncedHandleInput,
      'ajax:before': this.handleAJAXBefore,
      'ajax:error': handleAJAXError,
      'ajax:success': this.handleAJAXSuccess,
    });
  }

  handleInput = e => {
    if (e.target.name === 'allow_copy') return;

    e.target.setAttribute('data-dirty', '');

    const fields = e.target
      .closest('tr')
      .querySelectorAll('input, select, textarea');

    for (const field of fields) {
      field.setAttribute('data-dirty', '');
    }
  };

  handleAJAXBefore = () => {
    if (this.element.querySelectorAll('[data-dirty]').length === 0) {
      return false;
    }

    for (const field of this.element.elements) {
      if (field.name.indexOf('_method') > -1) continue;

      if (field.hasAttribute('data-dirty')) continue;

      field.setAttribute('disabled', '');
    }

    return true;
  };

  handleAJAXSuccess = () => {
    handleInfo('Tasks have been updated successfully!', {
      autoDismissible: true,
    });

    for (const field of this.element.elements) {
      field.removeAttribute('disabled');
      field.removeAttribute('data-dirty');
    }
  };
}
