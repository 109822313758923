import { Controller } from 'stimulus';
import { debounce } from 'lodash';

import { buildEndpoint } from '@ftf/lib/api';
import { reportError } from '@ftf/lib/reporting';

export default class InvestmentsListFilter extends Controller {
  static targets = ['filter', 'loader'];

  initialize() {
    this.fetchInvestments = debounce(this.fetchInvestments.bind(this), 300);
  }

  connect() {
    this.filterTarget.addEventListener('keyup', this.fetchInvestments);
    this.filterTarget.addEventListener('paste', this.fetchInvestments);
  }

  disconnect() {
    this.filterTarget.removeEventListener('keyup', this.fetchInvestments);
    this.filterTarget.removeEventListener('paste', this.fetchInvestments);
  }

  fetchInvestments = async () => {
    const query = this.filterTarget.value;
    const path = this.element.dataset.investmentsPath;
    const url = buildEndpoint(path, { query });

    try {
      this.toggleLoader(true);

      const response = await fetch(url);
      const html = await response.text();

      window.dispatchEvent(
        new CustomEvent('InvestmentsList:onUpdated', { detail: html }),
      );
    } catch (error) {
      reportError(error);
    }

    this.toggleLoader(false);
  };

  toggleLoader = visible => {
    this.loaderTarget.style.visibility = visible ? 'visible' : 'hidden';
  };
}
