import { Controller } from 'stimulus';

export default class InsurancePolicyForm extends Controller {
  static targets = ['status', 'cancellationDate', 'dop'];

  connect() {
    // defaults to false on create page
    this.dopTarget.checked = false;

    this.statusTarget.addEventListener(
      'change',
      this.handleStatusChange,
      false,
    );
  }

  disconnect() {
    this.statusTarget.removeEventListener(
      'change',
      this.handleStatusChange,
      false,
    );
  }

  handleStatusChange = e => {
    this.cancellationDateTarget.required = e.target.value === 'cancelled';
  };
}
