import { Controller } from 'stimulus';

export default class ExtensionForm extends Controller {
  static targets = [
    'upfrontPercentage',
    'afterPayoffPercentage',
    'status',
    'paymentStatus',
    'paymentType',
    'submit',
    'statusErrorMessage',
    'paymentTypeLabel',
    'paymentStatusLabel',
  ];

  connect() {
    this.upfrontPercentageTarget.addEventListener(
      'input',
      this.handlePercentageChange,
      false,
    );
  }

  disconnect() {
    this.upfrontPercentageTarget.removeEventListener(
      'input',
      this.handlePercentageChange,
      false,
    );
  }

  handlePercentageChange = () => {
    this.afterPayoffPercentageTarget.setAttribute(
      'value',
      100 - this.upfrontPercentageTarget.value,
    );
  };

  validatePaymentInformation = () => {
    let status = this.statusTarget.value;
    this.togglePaymentOptions(status);

    if (status !== 'payment_initiated') {
      this.enableSubmit();
      this.statusErrorMessageTarget.innerHTML = '';
      return;
    }
    let paymentStatus = this.paymentStatusTarget.value;
    let paymentType = this.paymentTypeTarget.value;
    if (paymentStatus === '' || paymentType === '') {
      this.disableSubmit();
      this.statusErrorMessageTarget.innerHTML =
        'Payment status and Payment type must be selected to move to this status';
    } else {
      this.enableSubmit();
      this.statusErrorMessageTarget.innerHTML = '';
    }
  };

  togglePaymentOptions = status => {
    if (
      status === 'approved' ||
      status === 'payment_initiated' ||
      status === 'complete'
    ) {
      this.enablePaymentOptions();
    } else {
      this.paymentTypeTarget.value = '';
      this.paymentStatusTarget.value = '';
      this.disablePaymentOptions();
    }
  };

  enableSubmit = () => {
    this.submitTarget.disabled = false;
  };

  disableSubmit = () => {
    this.submitTarget.disabled = true;
  };

  enablePaymentOptions = () => {
    this.paymentStatusTarget.disabled = false;
    this.paymentTypeTarget.disabled = false;
    this.enabledPaymentFields();
  };

  disablePaymentOptions = () => {
    this.paymentStatusTarget.disabled = true;
    this.paymentTypeTarget.disabled = true;
    this.disabledPaymentFields();
  };

  enabledPaymentFields = () => {
    this.paymentStatusTarget.classList.remove('disabled-select-carat');
    this.paymentStatusLabelTarget.classList.remove('disabled-grey');
    this.paymentTypeTarget.classList.remove('disabled-select-carat');
    this.paymentTypeLabelTarget.classList.remove('disabled-grey');
  };

  disabledPaymentFields = () => {
    this.paymentStatusTarget.classList.add('disabled-select-carat');
    this.paymentStatusLabelTarget.classList.add('disabled-grey');
    this.paymentTypeTarget.classList.add('disabled-select-carat');
    this.paymentTypeLabelTarget.classList.add('disabled-grey');
  };
}
