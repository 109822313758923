import { Controller } from 'stimulus';

import { handleAJAXError, handleInfo } from '@ftf/lib/alerts';

export default class ConstructionDrawsTable extends Controller {
  static targets = ['createUpdateLink'];

  connect() {
    this.createUpdateLinkTargets.forEach(el => {
      $(el).on({
        'ajax:success': this.handleCreateUpdateSuccess,
        'ajax:error': handleAJAXError,
      });
    });
  }

  disconnect() {
    this.createUpdateLinkTargets.forEach(el => {
      $(el).off({
        'ajax:success': this.handleCreateUpdateSuccess,
        'ajax:error': handleAJAXError,
      });
    });
  }

  handleCreateUpdateSuccess = () => {
    handleInfo('Update has been created successfully!', {
      autoDismissible: true,
    });

    $.ajax({
      url: this.element.dataset.reloadUrl,
      dataType: 'script',
    });
  };
}
