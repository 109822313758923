import { Controller } from 'stimulus';
import { isNil } from 'lodash';

let request = undefined;

export default class BorrowerProfileTrigger extends Controller {
  connect() {
    this.element.addEventListener('mouseenter', this.handleMouseEnter, false);
    this.element.addEventListener('mouseleave', this.handleMouseLeave, false);
  }

  disconnect() {
    this.element.removeEventListener(
      'mouseenter',
      this.handleMouseEnter,
      false,
    );
    this.element.removeEventListener(
      'mouseleave',
      this.handleMouseLeave,
      false,
    );

    this.abortRequest();
    this.closeProfileModal();
  }

  get borrowerProfileModal() {
    return document.querySelector("[data-controller='BorrowerProfileModal']");
  }

  handleMouseEnter = () => {
    if (isNil(this.borrowerProfileModal)) {
      return;
    }

    this.makeRequest();
  };

  handleMouseLeave = e => {
    this.abortRequest();

    if (e.relatedTarget === this.borrowerProfileModal) {
      return;
    }

    this.closeProfileModal();
  };

  makeRequest = () => {
    request = $.ajax({
      url: `/admin/borrowers/${this.element.dataset.borrowerId}.js`,
    }).done(this.showProfileModal);
  };

  abortRequest = () => {
    if (isNil(request)) {
      return;
    }

    request.abort();
  };

  showProfileModal = () => {
    window.dispatchEvent(
      new CustomEvent('BorrowerProfileModal:show', {
        detail: { target: this.element },
      }),
    );
  };

  closeProfileModal = () => {
    window.dispatchEvent(new Event('BorrowerProfileModal:close'));
  };
}
