import { Controller } from 'stimulus';

export default class UprightHeader extends Controller {
  static targets = [
    'dropdown',
    'dropdownList',
    'appSwitcher',
    'navBarIcon',
    'logoutSection',
    'signupButton',
  ];

  isOpened = false;

  connect() {
    this.removeCodeAndStateQueryParams();
    document.addEventListener('click', this.handleOutsideClick);

    if (this.hasAppSwitcherTarget) {
      this.appSwitcherTarget.addEventListener(
        'click',
        this.handleToggleButtonClick,
      );
    }

    if (this.hasLogoutSectionTarget) {
      this.logoutSectionTarget.addEventListener(
        'click',
        this.handleLogoutButtonClick,
      );
    }

    if (this.hasSignupButtonTarget) {
      this.signupButtonTarget.addEventListener(
        'click',
        this.handleContinueSignupButton,
      );
    }
  }

  disconnect() {
    document.removeEventListener('click', this.handleOutsideClick);

    if (this.hasAppSwitcherTarget) {
      this.appSwitcherTarget.removeEventListener(
        'click',
        this.handleToggleButtonClick,
      );
    }

    if (this.hasLogoutSectionTarget) {
      this.logoutSectionTarget.removeEventListener(
        'click',
        this.handleLogoutButtonClick,
      );
    }

    if (this.hasSignupButtonTarget) {
      this.signupButtonTarget.removeEventListener(
        'click',
        this.handleContinueSignupButton,
      );
    }
  }

  handleToggleButtonClick = e => {
    const isElement = (el, tagName) => el.tagName.toLowerCase() === tagName;

    const isAnchorEl = isElement(e.target, 'a');
    const isAnchorImageEl =
      isElement(e.target, 'img') && isElement(e.target.parentNode, 'a');

    if (!isAnchorEl && !isAnchorImageEl) {
      e.preventDefault();
    }

    this.toggleMenu();
  };

  handleLogoutButtonClick = e => {
    const signoutLink = e.target.querySelector('a');

    if (signoutLink) {
      signoutLink.click();
    }
  };

  toggleMenu = () => {
    this.dropdownTarget.classList.toggle('show', !this.isOpened);
    this.navBarIconTarget.classList.toggle('active', !this.isOpened);
    this.isOpened = !this.isOpened;
  };

  handleOutsideClick = e => {
    if (!this.isOpened) {
      return;
    }
    if (
      !this.appSwitcherTarget.contains(e.target) &&
      !this.dropdownTarget.contains(e.target)
    ) {
      this.toggleMenu();
    }
  };

  removeCodeAndStateQueryParams = () => {
    const url = new URL(location.href);
    url.searchParams.delete('code');
    url.searchParams.delete('state');
    window.history.replaceState({}, document.title, url.href);
  };

  handleContinueSignupButton = () => {
    document
      .getElementById('already-have-account-button')
      .classList.add('display-none');
  };
}
