import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
import useValidation from '@lysyi3m/stimulus-use-validation';

import { formatNumbers, formatNumbersAfterTyped } from '@ftf-old/main/helpers';
import {
  enforceValueToAtLeastOne,
  enforcePrecisionTwo,
  payoffAmountValidators,
  enforcePrecisionTwoForOptionalField,
} from '@ftf/lib/validators';

export default class AnalysisVersionForm extends Controller {
  static targets = [
    'appraisedAiv',
    'appraisedArv',
    'borrowerArv',
    'constructionAfterClose',
    'constructionAtClose',
    'constructionHoldback',
    'debtServicingType',
    'devProfileCreditScore',
    'form',
    'ftfArv',
    'interestRate',
    'isOwnWithLoanType',
    'loanAmount',
    'loanToAfterRepairValue',
    'loanToAsIsValue',
    'loanToCost',
    'loanToCostAtClose',
    'propertyType',
    'projectType',
    'purchaseDistAmt',
    'purchasePrice',
    'refreshBuyBoxMatches',
    'saveButton',
    'selectedArv',
    'selectedAiv',
    'versionNoteError',
  ];

  connect() {
    formatNumbers();
    formatNumbersAfterTyped();

    this.setBuyBoxDisplay();

    const ownWithLoan = this.isOwnWithLoanTypeTarget.value;

    const defaultValidators = {
      as_is_appraised_value: enforcePrecisionTwoForOptionalField,
      as_is_borrower_ftf_value: enforcePrecisionTwoForOptionalField,
      prepaid_interest_period: enforceValueToAtLeastOne,
      construction_completed_at_close: enforcePrecisionTwo,
      construction_to_be_completed_after_close: enforcePrecisionTwo,
      earnest_money_deposit: enforcePrecisionTwo,
      liquidity_to_borrower: enforcePrecisionTwo,
      payoff_amount: enforcePrecisionTwo,
      purchase_price: enforcePrecisionTwo,
    };

    const payoffValidators = {
      payoff_amount: payoffAmountValidators,
    };

    const combinedValidators = {
      ...defaultValidators,
      ...payoffValidators,
    };

    if (ownWithLoan) {
      useValidation(this, {
        disable: false,
        errorClassName: 'is-invalid',
        errorSelector: '.invalid-feedback',
        validators: combinedValidators,
        form: this.formTarget,
      });
    } else {
      useValidation(this, {
        disable: false,
        errorClassName: 'is-invalid',
        errorSelector: '.invalid-feedback',
        validators: defaultValidators,
        form: this.formTarget,
      });
    }

    this.refreshBuyBoxMatchesTarget.addEventListener('click', () => {
      this.sendBuyBoxDataForAnalysisVersion();
    });
  }

  disconnect() {
    this.refreshBuyBoxMatchesTarget.removeEventListener('click', () => {
      this.sendBuyBoxDataForAnalysisVersion();
    });
  }

  // very similar to buy box code in deal_builder.js
  sendBuyBoxDataForAnalysisVersion() {
    const projectType = this.projectTypeTarget.value;
    const propertyType = this.propertyTypeTarget.value;
    const creditScore = this.devProfileCreditScoreTarget.value
      ? parseFloat(this.devProfileCreditScoreTarget.value)
      : null;
    const interestRate = this.interestRateTarget.value
      ? parseFloat(this.interestRateTarget.value)
      : null;
    const loanAmount = this.loanAmountTarget.value
      ? parseFloat(this.loanAmountTarget.value.replace(/,/g, ''))
      : null;
    // LTARV
    const selectedArvVal = this.ltarvCalcValue();
    const loanToAfterRepair = parseFloat((loanAmount * 100) / selectedArvVal);
    // LTAIV
    const constructionAtClose = parseFloat(
      this.constructionAtCloseTarget.value.replace(/,/g, ''),
    );
    const purchaseDistribution = parseFloat(
      this.purchaseDistAmtTarget.innerHTML.substring(1).replace(/,/g, ''),
    );
    const selectedAivVal = this.ltaivCalcValue();
    const loanToAsIsValue = parseFloat(
      ((purchaseDistribution + constructionAtClose) * 100) / selectedAivVal,
    );
    // LTC
    const constructionAfterClose = parseFloat(
      this.constructionAfterCloseTarget.value.replace(/,/g, ''),
    );
    const purchasePrice = parseFloat(
      this.purchasePriceTarget.value.replace(/,/g, ''),
    );
    const loanToCost = parseFloat(
      (loanAmount * 100) /
        (purchasePrice + constructionAtClose + constructionAfterClose),
    );
    // LTCAC
    const loanToCostAtClose = parseFloat(
      ((purchaseDistribution + constructionAtClose) * 100) /
        (purchasePrice + constructionAtClose),
    );

    const loanComparisonObj = {
      project_type: projectType,
      property_type: propertyType,
      loan_amount: loanAmount,
      interest_rate: interestRate,
      loan_to_cost_at_close: loanToCostAtClose,
      loan_to_as_is_value: loanToAsIsValue,
      loan_to_cost: loanToCost,
      loan_to_after_repair_value: loanToAfterRepair,
      credit_score: creditScore,
    };

    window.dispatchEvent(
      new CustomEvent('SendBuyBoxData', {
        detail: loanComparisonObj,
      }),
    );
  }

  ltarvCalcValue() {
    const currentArv = this.selectedArvTarget.value;
    let arvValue;

    if (typeof currentArv !== 'undefined') {
      if (currentArv === 'appraised') {
        arvValue = this.appraisedArvTarget.value;
      } else if (currentArv === 'ftf') {
        arvValue = this.ftfArvTarget.value;
      } else {
        arvValue = this.borrowerArvTarget.value; // borrower
      }
    }

    return parseFloat(arvValue.replace(/,/g, ''));
  }

  ltaivCalcValue() {
    const currentAiv = this.selectedAivTarget.value;
    let aivVal;

    if (currentAiv === 'appraised') {
      aivVal = this.appraisedAivTarget.value;
    } else {
      aivVal = this.loanToAsIsValueTarget.value;
    }

    return aivVal === '' ? null : parseFloat(aivVal.replace(/,/g, ''));
  }

  toggleTaskStatus = e => {
    const taskTargetAttribute = e.target.name;
    const taskStatusToToggleTo =
      e.target.value === 'not_started' ? 'complete' : 'not_started';
    const taskAjaxRequest = `${document.location.pathname}?task_name=${taskTargetAttribute}&status=${taskStatusToToggleTo}`;
    Rails.ajax({
      type: 'get',
      url: taskAjaxRequest,
    });

    e.target.value = taskStatusToToggleTo;
  };

  setBuyBoxDisplay = () => {
    const buyBoxMatches = document.getElementById('buy-box-matches');
    const buyBoxVisibility = localStorage.getItem('buyBoxVisibility'); // open or closed
    if (buyBoxVisibility === 'open') {
      buyBoxMatches.classList.remove('d-none');
    } else {
      buyBoxMatches.classList.add('d-none');
    }
  };

  handleSelectedArv = e => {
    this.selectedArvTarget.value = e.target.value;
  };

  handleSelectedAiv = e => {
    this.selectedAivTarget.value = e.target.value;
  };

  showConfirmModal = e => {
    if (!e.target.checked) return;

    const toggleCase = e.target.id;
    const modalTitle =
      toggleCase === 'term_sheet_approved'
        ? 'Cleared to Term Sheet Confirmation'
        : 'Cleared to Close Confirmation';
    const selectedIdx = this.debtServicingTypeTarget.options.selectedIndex;
    const servicingTypeSelection = this.debtServicingTypeTarget.options[
      selectedIdx
    ].value;
    // TODO: add totalConstructionHoldback check to this, not added yet becus it is a calc value
    const modalMessage = this.getConfirmationMessage(
      toggleCase,
      servicingTypeSelection,
    );
    $('#analysis-version-modal-title').html(modalTitle);
    $('#analysis-version-modal-message').html(modalMessage);
    new Promise((resolve, reject) => {
      $('#analysis-version-modal').modal('show');
      $('#analysis-version-modal-save-button').on('click', () => resolve());
      $('#analysis-version-modal-cancel-button').on('click', () => reject());
    }).catch(() => {
      // undo toggle
      e.target.checked = false;
    });
  };

  getConfirmationMessage(toggleCase, servicingTypeSelection) {
    let message = '';
    const constructionHoldback = Number(
      this.constructionHoldbackTarget.innerHTML.replace(/[^0-9.-]+/g, ''),
    );
    if (toggleCase === 'term_sheet_approved') {
      $('#analysis-version-modal-case').val('term_sheet');
      message =
        servicingTypeSelection === 'at_closing' && constructionHoldback > 100000
          ? 'This assigns the values in this analysis version to the deal. This deal has an interest type of "At Close" but the construction holdback is greater than $100,000.00. Do you want to proceed?'
          : 'This assigns the values in this analysis version to the deal.';
    } else {
      $('#analysis-version-modal-case').val('underwrite');
      message =
        servicingTypeSelection === 'at_closing' && constructionHoldback > 100000
          ? 'This finalizes the deal using this analysis version. This deal has an interest type of "At Close" but the construction holdback is greater than $100,000.00. Do you want to proceed?'
          : 'This finalizes the deal using this analysis version.';
    }
    return message;
  }

  toggleVersionNoteError = e => {
    if (!e.target.value || e.target.value === '') {
      this.versionNoteErrorTarget.classList.remove('d-none');
      this.saveButtonTarget.classList.add('disabled');
      this.saveButtonTarget.disabled = true;
    } else {
      this.versionNoteErrorTarget.classList.add('d-none');
      this.saveButtonTarget.classList.remove('disabled');
      this.saveButtonTarget.disabled = false;
    }
  };

  handleAvValidation = () => {
    this.formTarget.validateForm();

    const hasErrors = this.formTarget.hasErrors();

    if (hasErrors) return;
  };
}
