import { styled, Colors } from '@ftf/styles';

export const Root = styled.div`
  color: ${Colors.darkBlue};
  font-size: 20px;
  line-height: 30px;
  margin: 40px 0;
  text-align: center;
`;

export const Spinner = styled.i`
  color: ${Colors.darkBlue};
  display: inline-block;
  margin-right: 10px;
`;
