import { Controller } from 'stimulus';

export default class SelectMultipleForm extends Controller {
  static targets = ['email', 'checkbox', 'submit'];

  roles = {};
  persistedRoles = {};

  email = '';
  persistedEmail = '';

  isSubmitted = false;

  connect() {
    this.emailTargets.forEach(input => {
      input.addEventListener('keyup', this.handleEmailChange, false);
    });

    this.checkboxTargets.forEach(checkbox => {
      checkbox.addEventListener('change', this.handleCheckboxChange, false);

      // Create lists of persisted and checked roles that we can diff when enabling buttons
      if (checkbox.checked) {
        this.persistedRoles = {
          ...this.persistedRoles,
          [checkbox.value]: checkbox.checked,
        };
      }
    });

    this.roles = this.persistedRoles;
  }

  disconnect() {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.removeEventListener('change', this.handleCheckboxChange, false);
    });

    this.emailTargets.forEach(input => {
      input.removeEventListener('keyup', this.handleEmailChange, false);
    });
  }

  handleCheckboxChange = e => {
    // if e.target.checked is false, only update the value stored in this.roles if e.target.value
    // is in this.persistedLabels. otherwise, remove it from this.roles.
    if (
      e.target.checked === true ||
      (e.target.checked === false && this.persistedRoles[e.target.value])
    ) {
      this.roles = {
        ...this.roles,
        [e.target.value]: e.target.checked,
      };
    } else if (
      e.target.checked === false &&
      !this.persistedRoles[e.target.value]
    ) {
      delete this.roles[e.target.value];
    }

    this.updateUI();
  };

  handleEmailChange = e => {
    this.email = e.target.value;
    this.updateUI();
  };

  updateUI = () => {
    this.updateSubmitButton();
  };

  updateSubmitButton = () => {
    // Disable the submit button if the selected checkboxes match the document requests that have
    // already been persisted. It should only be clickable if there is new data to persist.
    if (
      JSON.stringify(this.roles) !== JSON.stringify(this.persistedRoles) ||
      this.email !== this.persistedEmail
    ) {
      this.submitTargets.forEach(el => {
        el.classList.toggle('disabled', false);
      });

      return;
    }

    this.submitTargets.forEach(el => {
      el.classList.toggle('disabled', true);
    });
  };
}
