import { Controller } from 'stimulus';

export default class RemoteModal extends Controller {
  connect() {
    $(this.element).modal('show');

    $(this.element).on('hidden.bs.modal', this.handleModalHidden);
  }

  disconnect() {
    $(this.element).off('hidden.bs.modal', this.handleModalHidden);
  }

  handleModalHidden = () => {
    $(this.element).remove();
  };
}
