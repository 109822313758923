import Turbolinks from 'turbolinks';
import flatpickr from 'flatpickr';

import '../../main/blocks';
import '../../vendor';
import initApp from '../../main/init';
import initYoutubeLazyLoad from '../../main/video';
import stickyAddressInit from '../../main/sticky';
import '../../stylesheets/application.scss';

if (!$("body.user_dashboard[class*='lender']").length) {
  Turbolinks.start();
}

/**
 * Number.isNan polyfill for ie.
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/isNaN
 */
Number.isNaN =
  Number.isNaN ||
  function(value) {
    // Eslint: Comparing to itself is potentially pointless
    // MDN: The following works because NaN is the only value in javascript which is not equal to itself.
    // eslint-disable-next-line no-self-compare
    return value !== value;
  };

$(document).on('turbolinks:load', () => {
  flatpickr("[data-behavior='flatpickr']", {
    minDate: '2000-01',
    allowInput: true,
  });

  let removeDealEvents;
  let isDeals = false;
  initApp();

  if (document.querySelectorAll('.yt-player').length) {
    initYoutubeLazyLoad();
  }

  if ($('#sticky_address').length) {
    stickyAddressInit();
  }

  // ****************************************************
  // Notifications
  // ****************************************************

  if ($('.notifications.index').length || $('.notifications.archived').length) {
    import('../../main/notifications').then(module => {
      module.default();
    });
  }

  // ****************************************************
  // Term Sheet Preview
  // ****************************************************

  if ($('.term_sheet_preview.show').length) {
    import('../../main/term_sheet_preview/show').then(module => {
      module.default();
    });
  }

  // ****************************************************
  // Deals
  // ****************************************************

  if (
    $('.deals.index').length &&
    !$('body').hasClass('user_dashboard') &&
    !$('body').hasClass('admin')
  ) {
    import('../../main/deals/index').then(module => {
      module.default();
    });
  }

  if (
    $('.deals.show').length &&
    !$('body').hasClass('user_dashboard') &&
    !$('body').hasClass('admin')
  ) {
    import('../../main/deals/show').then(module => {
      module.default();
      removeDealEvents = module.removeDealEvents;
      isDeals = true;
    });
  } else if (isDeals) {
    // This is precautionary. Might never be used
    isDeals = false;
    removeDealEvents();
  }

  if ($('.offerings.show').length) {
    import('../../main/offerings/show').then(module => {
      module.default();
    });
  }

  if ($('.registrations.edit').length) {
    import('../../main/users/edit').then(module => {
      module.default();
    });
  }

  if ($('#user_settings-resubscribe').length) {
    import('../../main/users/resubscribe').then(module => {
      module.default();
    });
  }

  // ****************************************************
  // User Dashboards
  // ****************************************************

  if ($('.user_dashboard.borrower').length) {
    import('../../main/user_dashboard/borrower').then(module => {
      module.default();
    });
  } else if ($('.user_dashboard.broker').length) {
    import('../../main/user_dashboard/broker').then(module => {
      module.default();
    });
  }
});
