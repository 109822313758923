import { Controller } from 'stimulus';
import flatpickr from 'flatpickr';

import { formatNumbersAfterTyped, formatNumbers } from '@ftf-old/main/helpers';

export default class EditInvestmentModal extends Controller {
  static targets = ['form', 'accrualDateField'];

  flatpickrInstance = undefined;

  connect() {
    formatNumbers();
    formatNumbersAfterTyped();

    this.flatpickrInstance = flatpickr(this.accrualDateFieldTarget, {
      minDate: '2000-01',
      allowInput: true,
    });

    $(this.formTarget).on('ajax:success', this.handleFormSubmitSuccess);
  }

  disconnect() {
    this.flatpickrInstance.destroy();

    $(this.formTarget).off('ajax:success', this.handleFormSubmitSuccess);
  }

  handleFormSubmitSuccess = () => {
    window.dispatchEvent(new Event('InvestmentsTables:reload'));

    $(this.element).modal('hide');
  };
}
