import { Controller } from 'stimulus';
import { isNil } from 'lodash';
import { parse } from 'query-string';

export default class AuthModal extends Controller {
  static targets = ['mode'];

  connect() {
    this.handleUrlParams();

    $(this.element).on('show.bs.modal', this.handleShowModal);
  }

  disconnect() {
    $(this.element).off('show.bs.modal', this.handleShowModal);
  }

  handleUrlParams = () => {
    if (window.location.hash === '#auth') {
      this.switchMode('signin');
      this.show();
    }

    const { ref } = parse(window.location.search);

    if (ref === 'unbounce') {
      this.switchMode('signup');
      this.show();
    }
  };

  handleShowModal = e => {
    if (isNil(e.relatedTarget)) {
      return;
    }

    const { mode } = e.relatedTarget.dataset;

    this.switchMode(mode);
  };

  switchMode = (mode = 'signin') => {
    this.modeTargets.forEach(element => {
      const isHidden = element.dataset.mode !== mode;

      $(element).toggleClass('hidden', isHidden);
    });
  };

  show = () => $(this.element).modal('show');
}
