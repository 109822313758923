import { Controller } from 'stimulus';

import { handleError } from '@ftf/lib/alerts';

export default class ArchiveDocumentsConfirmationModal extends Controller {
  static targets = ['archive'];

  connect() {
    this.archiveTarget.addEventListener('click', this.showModal, false);
  }

  disconnect() {
    this.archiveTarget.removeEventListener('click', this.showModal, false);
  }

  showModal() {
    let $checkedDocuments = $('#selected_documents input:checkbox:checked');
    if ($checkedDocuments.length > 0) {
      let message = `${$checkedDocuments.length} document(s) selected for archiving`;
      $('#archive-confirmation-modal').modal('show');
      $('#archive-confirmation-modal-document-count').html(message);
    } else {
      handleError('You must select a document to archive.', false);
    }
  }
}
