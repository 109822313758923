import { Controller } from 'stimulus';
import useValidation from '@lysyi3m/stimulus-use-validation';

import Uploader from '@ftf/lib/uploader';
import { handleError, handleInfo } from '@ftf/lib/alerts';

export default class DocumentsUploadsForm extends Controller {
  static targets = [
    'filePicker',
    'filePickerInput',
    'fieldsTemplate',
    'form',
    'fieldsContainer',
    'addFieldsButton',
    'submitButton',
  ];

  fieldsTemplate = undefined;
  allowedFileTypes = [];

  connect() {
    useValidation(this, {
      form: this.formTarget,
      disable: false,
      errorClassName: 'is-invalid',
      errorSelector: '.invalid-feedback',
    });

    this.element.addEventListener('click', this.toggleFormElements, false);

    this.fieldsTemplate = this.fieldsTemplateTarget.firstElementChild.cloneNode(
      true,
    );

    this.allowedFileTypes = this.filePickerInputTarget
      .getAttribute('accept')
      .split(', ');

    this.filePickerInputTarget.addEventListener(
      'change',
      this.handleFilePickerChange,
      false,
    );

    this.addFieldsButtonTarget.addEventListener(
      'click',
      this.handleAddFieldsButtonClick,
      false,
    );

    this.element.addEventListener('dragenter', this.handleDragEnter, false);
    this.element.addEventListener('dragover', this.handleDragOver, false);
    this.element.addEventListener('dragleave', this.handleDragLeave, false);
    this.element.addEventListener('drop', this.handleDrop, false);
  }

  disconnect() {
    this.element.removeEventListener('click', this.toggleFormElements, false);

    this.filePickerInputTarget.removeEventListener(
      'change',
      this.handleFilePickerChange,
      false,
    );

    this.addFieldsButtonTarget.removeEventListener(
      'click',
      this.handleAddFieldsButtonClick,
      false,
    );

    this.element.removeEventListener('dragenter', this.handleDragEnter, false);
    this.element.removeEventListener('dragover', this.handleDragOver, false);
    this.element.removeEventListener('dragleave', this.handleDragLeave, false);
    this.element.removeEventListener('drop', this.handleDrop, false);
  }

  toggleFormElements = () => {
    const hasFiles = this.fieldsContainerTarget.hasChildNodes();

    this.filePickerTarget.classList.toggle('d-none', hasFiles);
    this.addFieldsButtonTarget.classList.toggle('d-none', !hasFiles);
    this.submitButtonTarget.disabled = !hasFiles;
  };

  processFile = async file => {
    try {
      if (!this.allowedFileTypes.includes(file.type)) return;

      const blob = await new Uploader(file).start();

      const newFields = this.fieldsTemplate.cloneNode(true);

      newFields.querySelector("[data-name='filename']").value = blob.filename;
      newFields.querySelector("[data-name='signed_id']").value = blob.signed_id;

      this.fieldsContainerTarget.appendChild(newFields);
      this.toggleFormElements();
    } catch (error) {
      handleError(error);
    }
  };

  processFiles = files => {
    const allowedFiles = [...files].filter(file =>
      this.allowedFileTypes.includes(file.type),
    );

    handleInfo(`Uploading ${allowedFiles.length} file(s)`, {
      autoDismissible: true,
    });

    allowedFiles.forEach(file => this.processFile(file));
  };

  handleFilePickerChange = e => this.processFiles(e.target.files);

  handleAddFieldsButtonClick = () => this.filePickerInputTarget.click();

  handleDragEnter = e => {
    e.preventDefault();
    e.stopPropagation();

    this.element.classList.add('is-droppable');
  };

  handleDragOver = e => {
    e.preventDefault();
    e.stopPropagation();

    this.element.classList.add('is-droppable');
  };

  handleDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();

    this.element.classList.remove('is-droppable');
  };

  handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();

    this.element.classList.remove('is-droppable');

    this.processFiles(e.dataTransfer.files);
  };
}
