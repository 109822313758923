import { Controller } from 'stimulus';
import { isEmpty } from 'lodash';
import useValidation from '@lysyi3m/stimulus-use-validation';

import { originationDateValidator } from '@ftf/lib/validators';

export default class DealIntake extends Controller {
  static targets = [
    'form',
    'borrowerEmail',
    'borrowerFirstName',
    'borrowerLastName',
    'toggleSubmit',
  ];

  connect() {
    useValidation(this, {
      form: this.formTarget,
      validators: {
        'deal_intake[origination_date]': originationDateValidator,
      },
    });

    this.borrowerEmailTarget.addEventListener(
      'quicksearch:selected',
      this.handleQuicksearchSelected,
      false,
    );

    this.toggleSubmitTarget.addEventListener(
      'click',
      this.handleToggleSubmitClick,
      false,
    );
  }

  disconnect() {
    this.borrowerEmailTarget.removeEventListener(
      'quicksearch:selected',
      this.handleQuicksearchSelected,
      false,
    );

    this.toggleSubmitTarget.removeEventListener(
      'click',
      this.handleToggleSubmitClick,
      false,
    );
  }

  handleQuicksearchSelected = e => {
    const { suggestion } = e.detail;
    if (isEmpty(suggestion)) {
      return;
    }

    this.borrowerFirstNameTarget.focus();
    this.borrowerFirstNameTarget.value = suggestion.first_name;
    this.borrowerFirstNameTarget.blur();

    this.borrowerLastNameTarget.focus();
    this.borrowerLastNameTarget.value = suggestion.last_name;
    this.borrowerLastNameTarget.blur();
  };

  handleToggleSubmitClick = () => {
    this.formTarget.validateForm();

    if (this.formTarget.hasErrors()) {
      return;
    }

    $('#confirmationModal').modal('show');
  };
}
