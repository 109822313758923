import { Controller } from 'stimulus';

import { handleError, handleInfo } from '@ftf/lib/alerts';
import { APICaller } from '@ftf/lib/api';

export default class UnderwritingRequestModal extends Controller {
  static targets = ['requestTypeVal'];

  sendVersionToUnderwriting = () => {
    let requestType = this.requestTypeValTarget.value;
    this.handleSendVersion(requestType);
  };

  handleSendVersion = async requestType => {
    let selectedTab = $('.is-active');
    let requestModal = $('#underwritingRequestModal');
    let versionId = selectedTab[0].dataset.key;
    try {
      const response = await APICaller.post(
        `v2/analysis_versions/${versionId}/send_version_to_uw`,
        { data: { request_type: requestType } },
      );
      if (response.status === 200) {
        requestModal.modal('hide');
        handleInfo('Analysis version has been sent to underwriting!');
      } else {
        handleError('Failed to send analysis version');
      }
    } catch (err) {
      handleError(err);
    }
  };
}
