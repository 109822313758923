import { Controller } from 'stimulus';

export default class ConnectionsTable extends Controller {
  static targets = ['checkBox', 'modalButton'];

  connect() {
    this.checkBoxTargets.forEach(checkBox => {
      checkBox.addEventListener('click', this.showAddButton, false);
    });

    this.modalButtonTargets.forEach(modalButton => {
      modalButton.addEventListener('click', this.showModal, false);
    });

    this.showAddButton();
  }

  disconnect() {
    this.checkBoxTargets.forEach(checkBox => {
      checkBox.removeEventListener('click', this.showAddButton, false);
    });
  }

  showAddButton = () => {
    const checkedBoxes = document.querySelectorAll(
      'input[name="connections[]"]:checked',
    );

    if (checkedBoxes.length > 0) {
      $('#modal-span').hide();
      $('#modal-button')
        .text('+ ' + checkedBoxes.length + ' to Deal')
        .show();
    } else {
      $('#modal-button')
        .text('Edit')
        .hide();
      $('#modal-span').show();
    }
  };

  showModal = () => {
    const checkedBoxes = document.querySelectorAll(
      'input[name="connections[]"]:checked',
    );

    $('.number-of-connections').text(checkedBoxes.length);
    $('#connections-modal').modal('show');
  };
}
