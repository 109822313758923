import { Controller } from 'stimulus';
import { debounce, isNil } from 'lodash';

import checkIsMobile from '@ftf-old/util/checkIsMobile';

const MAP_STYLES = [
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e9e9e9',
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5',
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ffffff',
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#ffffff',
      },
      {
        lightness: 29,
      },
      {
        weight: 0.2,
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
      {
        lightness: 18,
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5',
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dedede',
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#ffffff',
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        saturation: 36,
      },
      {
        color: '#333333',
      },
      {
        lightness: 40,
      },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f2f2f2',
      },
      {
        lightness: 19,
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#fefefe',
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#fefefe',
      },
      {
        lightness: 17,
      },
      {
        weight: 1.2,
      },
    ],
  },
];

const OFFICES = {
  cleveland: {
    address: '1300 E. 9th Street, Ste. 800',
    stateAndZip: 'Cleveland, OH 44114',
    title: 'CLEVELAND',
    position: { lat: 41.504438, lng: -81.690867 },
    infoWindow: true,
  },
  nyc: {
    address: '10 E. 23rd Street, 5th FL',
    stateAndZip: 'New York, NY 10010',
    title: 'NEW YORK CITY',
    position: { lat: 40.741097, lng: -73.988588 },
    infoWindow: true,
  },
  'Scott Canosa-SC': {
    position: { lat: 34.0007, lng: -81.0348 },
  },
  'Dan Rohrer-Columbus': {
    position: { lat: 39.9612, lng: -82.9988 },
  },
  'Dave Kansagor-NC': {
    position: { lat: 35.7796, lng: -78.6382 },
  },
  'Justin Smithers-Dallas/Fort Worth': {
    position: { lat: 32.7767, lng: -96.797 },
  },
  'David Duggan-Cleveland': {
    position: { lat: 41.4993, lng: -81.6944 },
  },
  '': {
    position: { lat: 32.7767, lng: -96.797 },
  },
  'Luke Hanscom-Boston': {
    position: { lat: 42.3601, lng: -71.0589 },
  },
  'Antonio Campanelli-Philly': {
    position: { lat: 39.9526, lng: -75.1652 },
  },
  'Ryan Studner-DC/Baltimore': {
    position: { lat: 38.9072, lng: -77.0369 },
  },
  'David Garramone-Nashville': {
    position: { lat: 36.1627, lng: -86.7816 },
  },
  'Jerome Green-Atlanta': {
    position: { lat: 33.749, lng: -84.388 },
  },
  'Alex Robertson-Indianapolis': {
    position: { lat: 39.7684, lng: -86.1581 },
  },
  'Bryan Sullivan-Houston': {
    position: { lat: 29.7604, lng: -95.3698 },
  },
  'Matthew Kelly-Kansas City': {
    position: { lat: 39.0997, lng: -94.5786 },
  },
  Orlando: {
    position: { lat: 28.5384, lng: -81.3789 },
  },
};

export default class AboutMap extends Controller {
  mapInstance = null;
  markers = {};

  initialize() {
    this.debouncedHandleResize = debounce(this.handleResize, 300);
  }

  connect() {
    window.addEventListener('resize', this.debouncedHandleResize);

    if (typeof google === 'object') {
      this.initMap();

      return;
    }

    document.addEventListener('places.autofill.load', this.initMap, false);
  }

  disconnect() {
    window.removeEventListener('resize', this.debouncedHandleResize);
    document.removeEventListener('places.autofill.load', this.initMap, false);
  }

  handleResize = () => {
    if (checkIsMobile()) {
      return;
    }

    if (isNil(this.mapInstance)) {
      this.initMap();

      return;
    }

    this.setMarkersPosition();
  };

  addInfoWindows = () => {
    Object.keys(OFFICES).forEach(key => {
      const marker = this.markers[key];

      if (isNil(marker)) {
        return;
      }
      const content = `
        <div class="about-map__tooltip">
          <h1 class="about-map__tooltip-title">${OFFICES[key].title}</h1>
          <p class="about-map__tooltip-text">${OFFICES[key].address}</p>
          <p class="about-map__tooltip-text">${OFFICES[key].stateAndZip}</p>
        </div>
      `;

      const infoWindow = new google.maps.InfoWindow({
        content,
        maxWidth: 260,
        disableAutoPan: true,
        pixelOffset:
          OFFICES[key].title === 'NEW YORK CITY'
            ? new google.maps.Size(80, 0)
            : null,
      });
      if (OFFICES[key].infoWindow) infoWindow.open(this.mapInstance, marker);
    });
  };

  setMarkersPosition = () => {
    const bounds = new google.maps.LatLngBounds();

    Object.keys(this.markers).forEach(key => {
      bounds.extend(this.markers[key].position);
    });
  };

  addMarkers = () => {
    Object.keys(OFFICES).forEach(key => {
      const marker = new google.maps.Marker({
        clickable: false,
        icon: {
          url: require('./images/marker.svg'),
          scale: 1,
          scaledSize: new google.maps.Size(30, 40),
        },
        map: this.mapInstance,
        position: OFFICES[key].position,
      });

      this.markers[key] = marker;
    });
  };

  initMap = () => {
    if (checkIsMobile()) {
      return;
    }

    this.mapInstance = new google.maps.Map(this.element, {
      center: OFFICES.cleveland.position,
      clickableIcons: false,
      disableDefaultUI: true,
      styles: MAP_STYLES,
      zoom: 5,
    });

    this.addMarkers();
    this.setMarkersPosition();
    this.addInfoWindows();

    this.mapInstance.addListener('tilesloaded', function() {
      document
        .querySelectorAll('.gm-style-iw-t')[1]
        .classList.add('about-map__nyc-info-window');
    });
  };
}
