import { Controller } from 'stimulus';
import { createConsumer } from '@rails/actioncable';

export default class LoanApplicationWebSocket extends Controller {
  connect() {
    this.subscription = createConsumer().subscriptions.create(
      { channel: 'LoanApplicationsChannel' },
      {
        connected: () => {},

        disconnected: () => {},

        received: data => {
          let containerId = data.container_id;
          let container = document.querySelector(
            `[data-container-id="${containerId}"]`,
          );
          if (!container) {
            return;
          }
          container.innerHTML = data.activate_new_account;
        },
      },
    );
  }

  disconnect() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
