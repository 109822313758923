import { Controller } from 'stimulus';

export default class EventForm extends Controller {
  static targets = ['followUpRequired', 'followUpDate'];

  connect() {
    this.followUpRequiredTarget.addEventListener(
      'change',
      this.handleEnableFollowUpDate,
      false,
    );

    this.handleEnableFollowUpDate();
  }

  handleEnableFollowUpDate = () => {
    if (this.followUpRequiredTarget.checked) {
      this.followUpDateTarget.disabled = false;

      //only run this if the current follow-up date is null
      if (!this.followUpDateTarget.value) {
        var today = new Date(); //today's date

        let dd = String(today.getDate() + 1).padStart(2, '0'); //format day to the following day
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //format month

        if (today.getDate() >= 30) {
          dd = '01'; //set the day to the first day of the month
          mm = String(today.getMonth() + 2).padStart(2, '0'); // set month to the following month
        }

        var yyyy = today.getFullYear(); //format year

        today = yyyy + '-' + mm + '-' + dd;

        this.followUpDateTarget.value = today;
      }

      this.followUpDateTarget.required = 'required';
    } else {
      this.followUpDateTarget.disabled = true;
      this.followUpDateTarget.value = null;
      this.followUpDateTarget.required = false;
    }
  };
}
