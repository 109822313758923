import { Controller } from 'stimulus';
import { stringifyUrl } from 'query-string';

export default class TasksBatchesForm extends Controller {
  connect() {
    window.addEventListener('RemoteTabs:switchTab', this.handleSwitchTab);
  }

  disconnect() {
    window.removeEventListener('RemoteTabs:switchTab', this.handleSwitchTab);
  }

  handleSwitchTab = e => {
    const action = stringifyUrl({
      url: this.element.getAttribute('action'),
      query: { tab: e.detail.key },
    });

    this.element.setAttribute('action', action);
  };
}
