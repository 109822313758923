import { Controller } from 'stimulus';
import { isNil, has } from 'lodash';

import { processAJAXError } from '@ftf/lib/errors';

export default class TwoFactorAuthForm extends Controller {
  static targets = [
    'channel',
    'channelControl',
    'channelField',
    'emailControl',
    'emailLabel',
    'errors',
    'requestForm',
    'resendLink',
    'smsControl',
    'smsLabel',
    'step',
    'tokenField',
    'verifyForm',
  ];

  isPersistent = false;

  connect() {
    if (has(window, ['gon', 'twoFactorAuth'])) {
      this.fill(window.gon.twoFactorAuth);
    }

    window.addEventListener('TwoFactorAuthForm:fill', this.handleFill);

    $(this.requestFormTarget).on('ajax:success', this.handleRequestFormSuccess);

    this.channelControlTargets.forEach(element => {
      element.addEventListener('change', this.handleChannelChange, false);
    });

    $(this.verifyFormTarget).on({
      'ajax:success': this.handleVerifyFormSuccess,
      'ajax:error': this.handleVerifyFormError,
    });

    this.resendLinkTarget.addEventListener(
      'click',
      this.handleResendLinkClick,
      false,
    );

    this.stepTargets.forEach(element => {
      $(element).validator();
    });
  }

  disconnect() {
    window.removeEventListener('TwoFactorAuthForm:fill', this.handleFill);

    $(this.requestFormTarget).off(
      'ajax:success',
      this.handleRequestFormSuccess,
    );

    this.channelControlTargets.forEach(element => {
      element.removeEventListener('change', this.handleChannelChange, false);
    });

    this.resendLinkTarget.removeEventListener(
      'click',
      this.handleResendLinkClick,
      false,
    );

    $(this.verifyFormTarget).off({
      'ajax:success': this.handleVerifyFormSuccess,
      'ajax:error': this.handleVerifyFormError,
    });

    this.stepTargets.forEach(element => {
      $(element).validator('destroy');
    });
  }

  fill = ({ email, sms, step = 'request', isPersistent = false }) => {
    this.isPersistent = isPersistent;

    const hasEmail = !isNil(email) && email.length > 0;
    const hasSms = !isNil(sms) && sms.length > 0;

    this.channelTarget.innerText = hasEmail ? 'Email address' : 'SMS Number';

    this.channelFieldTarget.value = hasEmail ? 'email' : 'sms';

    this.emailLabelTarget.innerText = email;
    this.emailControlTarget.classList.toggle('hidden', !hasEmail);

    this.smsLabelTarget.innerText = sms;
    this.smsControlTarget.classList.toggle('hidden', !hasSms);

    if (hasEmail && !hasSms) {
      this.emailControlTarget.querySelector('input').checked = true;
    }

    if (!hasEmail && hasSms) {
      this.smsControlTarget.querySelector('input').checked = true;
    }

    this.switchStep(step);
  };

  switchStep = (step = 'request') => {
    this.stepTargets.forEach(element => {
      const isHidden = element.dataset.step !== step;

      element.classList.toggle('hidden', isHidden);
    });

    this.tokenFieldTarget.value = '';

    this.errorsTarget.innerText = '';
  };

  handleFill = e => this.fill(e.detail);

  handleChannelChange = e => {
    this.channelTarget.innerText =
      e.target.value === 'sms' ? 'SMS number' : 'Email address';

    this.channelFieldTarget.value = e.target.value;
  };

  handleRequestFormSuccess = () => this.switchStep('verify');

  handleResendLinkClick = e => {
    e.preventDefault();

    this.switchStep('request');
  };

  handleVerifyFormSuccess = (
    e,
    { csrf_token: csrfToken, redirect_to: redirectTo } = {},
  ) => {
    if (csrfToken) {
      $('meta[name="csrf-token"]').attr('content', csrfToken);
    }

    if (this.isPersistent) return;

    if (redirectTo) {
      window.location.href = `${window.location.origin}${redirectTo}`;
    }
  };

  handleVerifyFormError = (e, xhr) => {
    this.errorsTarget.innerText = processAJAXError(xhr);
  };
}
