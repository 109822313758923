import { Controller } from 'stimulus';

import { urlMaker } from '../../../../../../javascript/deals/admin_edit/ducks/helpers/termSheetDataHelpers';

export default class AnalysisVersionContainer extends Controller {
  showUnderwritingModal() {
    $('#underwritingRequestModal').modal('show');
  }

  showGenerateTermSheet = e => {
    $('#generateTermSheetModal').modal('show');
    const dealId = e.target.dataset.attr;
    this.fetchTermSheetData(dealId);
  };

  displaySSIframe = srcUrl => {
    const iframe = `<iframe src=${srcUrl}
    id="deal_frame"
    title="Deal Frame"
    height="75vh"
    width="100%"
    height="100%"
    frameBorder="0"
    scrolling="yes"
    marginHeight="0"
    marginWidth="0"
    style="min-height: inherit"></iframe>`;
    $('#term-sheet-val-display').html(iframe);
  };

  displayBrokerNameError = () => {
    const errorFrame = `<p>
        Please make sure the borrower email and the broker email are not the
        same.
      </p>`;
    $('#term-sheet-val-display').html(errorFrame);
  };

  fetchTermSheetData = dealId => {
    $.ajax({
      method: 'GET',
      url: `/signature_document/v1/deal/${dealId}`,
      success: res => {
        const tsData = res.term_sheet_data;
        const hasBroker = tsData.broker_name !== null;
        if (tsData.Broker === tsData.Borrower) {
          this.displayBrokerNameError();
        } else {
          let uri = urlMaker(tsData, hasBroker);
          this.displaySSIframe(uri);
        }
      },
    });
  };

  createNewDealFromVersion = e => {
    const dealId = e.target.dataset.attr;
    const versionId = e.target.dataset.key;

    const createNewDealUrl = `/admin/sales/deal_submissions/new?copy_from_deal_id=true&deal_id=${dealId}&version_id=${versionId}`;

    window.open(createNewDealUrl, '_blank');
  };
}
