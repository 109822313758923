import { Controller } from 'stimulus';
import { debounce } from 'lodash';

export default class Documents extends Controller {
  static targets = ['filterInput', 'filterLabel'];

  initialize() {
    this.debouncedHandleInput = debounce(this.handleInput, 300);
  }

  connect() {
    this.filterInputTarget.addEventListener(
      'input',
      this.debouncedHandleInput,
      false,
    );
  }

  disconnect() {
    this.filterInputTarget.removeEventListener(
      'input',
      this.debouncedHandleInput,
      false,
    );
  }

  handleInput = e => {
    this.filterLabelTargets.forEach(el => {
      const valueLowerCased = e.target.value
        .toLowerCase()
        .replace(/[\s-]+/g, '_');
      const isMatch = el.dataset.label.indexOf(valueLowerCased) > -1;

      el.classList.toggle('d-none', !isMatch);
    });
  };
}
