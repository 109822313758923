import { Controller } from 'stimulus';

import { validators } from '@ftf-old/main/helpers';

export default class UserSettingsForm extends Controller {
  static targets = ['submitButton'];

  isDirty = false;

  connect() {
    this.toggleSubmitButton();

    $(this.element).validator({
      custom: {
        phone: validators.validations.phone,
        email: validators.validations.email,
      },
      errors: {
        phone: validators.errors.phone,
        email: validators.errors.email,
      },
    });

    $(this.element).on('change input', ':input', this.handleFormChange);

    if (window.location.pathname === '/users/edit') {
      // add is-active class to the email preferences tab
      $('.email-preferences-link').addClass('is-active-page');
    }
  }

  disconnect() {
    $(this.element).validator('destroy');

    $(this.element).off('change input', ':input', this.handleFormChange);
  }

  handleFormChange = () => {
    if (this.isDirty) {
      return;
    }

    this.isDirty = true;

    this.toggleSubmitButton();
  };

  toggleSubmitButton = () => {
    $(this.submitButtonTarget)
      .toggleClass('disabled')
      .prop('disabled', !$(this.submitButtonTarget).prop('disabled'));
  };
}
