import { Application } from 'stimulus';

import { html } from '@ftf/lib/templateTags';

import ChargeForm from '../charge_form';

function renderApp() {
  document.body.innerHTML = html`
    <div data-controller="ChargeForm"></div>
  `;

  const app = Application.start();
  app.register('ChargeForm', ChargeForm);
}

function getBlock() {
  return document.body.querySelector('[data-controller="ChargeForm"]');
}

describe('ChargeForm block', () => {
  beforeEach(renderApp);
  afterEach(() => (document.body.innerHTML = ''));

  it('has root', () => {
    const block = getBlock();

    expect(block).toBeInTheDocument();
  });
});
