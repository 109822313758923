import { debounce } from 'lodash';

import { validators } from '@ftf-old/main/helpers';

import LoanApplicationFormStep from '../loan_application_form_step/loan_application_form_step';

export default class FormStepCreateAccount extends LoanApplicationFormStep {
  static targets = ['email', 'termsCheckbox', 'phone'];
  currentStep = 'account';
  previousStep = 'experience';

  initialize() {
    this.emailChangeDebounce = debounce(async () => {
      await this.verifyEmail();
      this.toggleSubmitButton();
    }, 150);
    this.accountExists = false;
  }

  connect() {
    $(this.element).validator({
      custom: {
        phone: validators.validations.phone,
      },
      errors: {
        phone: validators.errors.phone,
      },
    });

    $(this.phoneTarget).bfhphone(this.phoneTarget.dataset);
  }

  disconnect() {
    $(this.phoneTarget).removeData('bfhphone');
  }

  async handleEmailChange() {
    this.emailChangeDebounce();
  }

  verifyEmail = async () => {
    const encodedEmail = encodeURIComponent(this.emailTarget.value);

    try {
      const response = await fetch(`/loan_applications?email=${encodedEmail}`);
      if (response.ok) {
        const data = await response.json();
        if (data.exists === true) {
          this.accountExists = true;
          this.accountExistsSpanTarget.textContent = data.message;
        } else {
          this.accountExists = false;
          this.accountExistsSpanTarget.textContent = '';
        }
      }
    } catch (error) {
      return;
    }
  };

  toggleSubmitButton = () => {
    setTimeout(
      () => (this.submitButtonTarget.disabled = !this.allChecksPassed()),
      1,
    );
  };

  allChecksPassed = () => {
    return (
      !this.accountExists &&
      this.termsCheckboxTarget.checked &&
      this.phoneTarget.value.length === 17 &&
      this.isValid()
    );
  };

  handleSubmit = () => {
    $(this.element).validator('validate');

    return this.allChecksPassed();
  };
}
