import { Controller } from 'stimulus';
import { isNil } from 'lodash';

const MODAL_TOP_PADDING = 25;
const HEADER_HEIGHT = 70;

export default class BorrowerProfileModal extends Controller {
  connect() {
    window.addEventListener('BorrowerProfileModal:show', this.show);
    window.addEventListener('BorrowerProfileModal:close', this.close);
    this.element.addEventListener('mouseleave', this.handleMouseLeave, false);
  }

  disconnect() {
    window.removeEventListener('BorrowerProfileModal:show', this.show);
    window.removeEventListener('BorrowerProfileModal:close', this.close);
    this.element.removeEventListener(
      'mouseleave',
      this.handleMouseLeave,
      false,
    );
  }

  show = e => {
    if (isNil(e.detail) || isNil(e.detail.target)) {
      return;
    }

    this.element.classList.add('is-active');

    const { left, top, width } = e.detail.target.getBoundingClientRect();

    const targetLeftOffset =
      left +
      width +
      (window.pageXOffset || document.documentElement.scrollLeft);

    const { height } = this.element.getBoundingClientRect();

    const targetTopOffset =
      top + (window.pageYOffset || document.documentElement.scrollTop);

    const topOffset =
      targetTopOffset < height / 2 + HEADER_HEIGHT
        ? targetTopOffset - MODAL_TOP_PADDING
        : targetTopOffset - height / 2;

    this.element.style.left = `${targetLeftOffset}px`;
    this.element.style.top = `${topOffset}px`;
  };

  close = () => {
    this.element.classList.remove('is-active');
  };

  handleMouseLeave = e => {
    if (
      e.relatedTarget &&
      e.relatedTarget.querySelector(
        "[data-controller='BorrowerProfileTrigger']",
      )
    ) {
      return;
    }

    this.close();
  };
}
