import { Controller } from 'stimulus';
import flatpickr from 'flatpickr';

export default class ChargeForm extends Controller {
  static targets = ['amountInput', 'autocalculatedInput', 'datePicker'];

  connect() {
    this.datePickerTargets.forEach(dateInput => {
      flatpickr(dateInput, { minDate: '2000-01', allowInput: true });
    });
  }
  enableAmount() {
    $(this.amountInputTarget).prop('disabled', false);
  }

  disableAmount() {
    $(this.amountInputTarget).prop('disabled', true);
  }
}
