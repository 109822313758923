import { Controller } from 'stimulus';

export default class Deals extends Controller {
  static targets = ['rowToggle'];

  connect() {
    this.rowToggleTargets.forEach(el => {
      el.addEventListener('click', this.handleRowToggleClick, false);
    });
  }

  disconnect() {
    this.rowToggleTargets.forEach(el => {
      el.removeEventListener('click', this.handleRowToggleClick, false);
    });
  }

  handleRowToggleClick = e => {
    e.currentTarget.closest('tr').classList.toggle('is-active');
  };
}
