import { Controller } from 'stimulus';

export default class AnalysisVersionBuyBox extends Controller {
  static targets = [
    'atToolsWidget',
    'atToolsPanel',
    'buyBoxPanel',
    'toolsPanelIconPrimary',
    'toolsPanelIconWhite',
  ];

  connect() {
    this.atToolsWidgetTarget.addEventListener(
      'click',
      this.toggleBuyBox,
      false,
    );

    const buyBoxWasVisible = localStorage.getItem('buyBoxVisibility');
    if (buyBoxWasVisible === 'open') {
      this.toggleBuyBox();
    }
  }

  disconnect() {
    this.atToolsWidgetTarget.removeEventListener(
      'click',
      this.toggleBuyBox,
      false,
    );
  }

  toggleBuyBox = () => {
    this.buyBoxPanelTarget.classList.toggle('hidden');
    this.atToolsWidgetTarget.classList.toggle('open-tool-panel-bg');
    this.atToolsPanelTarget.classList.toggle('pb-4');
    this.toggleToolsPanelIcon();
    this.openBuyBoxFullMatches();
  };

  toggleToolsPanelIcon = () => {
    this.toolsPanelIconPrimaryTarget.classList.toggle('pull-right');
    this.toolsPanelIconPrimaryTarget.classList.toggle('hidden');
    this.toolsPanelIconWhiteTarget.classList.toggle('pull-right');
    this.toolsPanelIconWhiteTarget.classList.toggle('hidden');
  };

  openBuyBoxFullMatches = () => {
    const expandBuyBox = document.getElementById('tool-panel-chevron');
    const showFullMatches = document.getElementById('full-match-tab');
    expandBuyBox.click();
    showFullMatches.click();
  };
}
