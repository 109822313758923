import {
  formatNumbersAfterTyped,
  loadAutoComplete,
} from '@ftf-old/main/helpers';

import LoanApplicationFormStep from '../loan_application_form_step/loan_application_form_step';

export default class FormStepProject extends LoanApplicationFormStep {
  static targets = [
    'afterRepairValue',
    'calculatedValue',
    'calculatedValueText',
    'downPaymentCapital',
    'initCalcText',
    'purchasePrice',
    'rehabBudget',
    'calcField',
    'ltarvAcceptanceText',
  ];
  nextStep = 'experience';

  initialize() {
    this.skipPrompt = document.querySelector('#skip-prompt');
    this.ltarvAcceptanceDiv = this.ltarvAcceptanceTextTarget;
    this.ltarvValid = false;
  }

  connect() {
    super.connect();
    formatNumbersAfterTyped();
    this.calcFieldTargets.forEach(el => {
      el.addEventListener('input', this.calculateLTARV);
    });

    document.addEventListener(
      'places.autofill.load',
      this.addAutocompleteToForm,
      false,
    );
  }

  disconnect() {
    super.disconnect();
    this.calcFieldTargets.forEach(el => {
      el.removeEventListener('input', this.calculateLTARV);
    });

    document.removeEventListener(
      'places.autofill.load',
      this.addAutocompleteToForm,
      false,
    );
  }

  addAutocompleteToForm = () => {
    const $form = $(this.element);

    loadAutoComplete($form);
  };

  // currently: shows LTARV text, grab values needed for LTARV calc, display calculated LTARV
  // need to show the different messages based on the calculated LTARV value
  calculateLTARV = () => {
    const purchasePrice = parseInt(
      this.purchasePriceTarget.value.replace(/\D/g, ''),
      10,
    );

    const rehabBudget = parseInt(
      this.rehabBudgetTarget.value.replace(/\D/g, ''),
      10,
    );

    const afterRepairValue = parseInt(
      this.afterRepairValueTarget.value.replace(/\D/g, ''),
      10,
    );

    const downPaymentCapital = parseInt(
      this.downPaymentCapitalTarget.value.replace(/\D/g, ''),
      10,
    );

    const LTARV =
      (purchasePrice - downPaymentCapital + rehabBudget) / afterRepairValue;

    this.initCalcTextTarget.classList.add('d-none');
    this.calculatedValueTextTarget.classList.remove('d-none');
    this.calculatedValueTarget.textContent = Number.isNaN(LTARV)
      ? ' --'
      : ' ' + (LTARV * 100).toFixed(1) + '%';

    this.ltarvResultText(LTARV);

    if (Number.isNaN(LTARV)) {
      this.ltarvAcceptanceDiv.classList.add('d-none');
    } else {
      this.ltarvAcceptanceDiv.classList.remove('d-none');
    }
  };

  ltarvResultText = LTARV => {
    if (!LTARV || LTARV === '-') {
      return;
    } else {
      if (LTARV > 0.7) {
        this.ltarvValid = false;
        this.ltarvAcceptanceDiv.style.color = 'red';
        this.ltarvAcceptanceDiv.innerText =
          'x These numbers look too low for us.';
      } else if (LTARV >= 0.6 && LTARV <= 0.7) {
        this.ltarvValid = true;
        this.ltarvAcceptanceDiv.style.color = 'green';
        this.ltarvAcceptanceDiv.innerText =
          '✓ These numbers look within a range that we can work with!';
      } else if (LTARV < 0) {
        this.ltarvValid = false;
        this.ltarvAcceptanceDiv.style.color = 'red';
        this.ltarvAcceptanceDiv.innerText =
          'x Hmmm, something looks wrong here. Take a second look at these numbers.';
      } else if (LTARV < 0.6) {
        this.ltarvValid = true;
        this.ltarvAcceptanceDiv.style.color = 'green';
        this.ltarvAcceptanceDiv.innerText = '✓ Your numbers look great!';
      }
      this.toggleSubmitButton();
    }
  };

  // overwritting
  toggleSubmitButton = () => {
    const validForm = this.isValid() && this.ltarvValid;
    this.skipPrompt.style.display = validForm ? 'none' : 'block';
    this.submitButtonTarget.disabled = !validForm;
  };

  skipStep = e => {
    e.preventDefault();
    this.goNext();
    const ids = [
      'loan_application_street_1',
      'loan_application_street_2',
      'loan_application_state',
      'loan_application_city',
      'loan_application_zip',
      'loan_application_flexible_purchase_price',
      'loan_application_flexible_expected_rehab_budget',
      'loan_application_flexible_borrower_arv',
      'loan_application_flexible_down_payment',
    ];

    ids.forEach(id => {
      const inputElement = document.getElementById(id);
      if (inputElement) {
        inputElement.removeAttribute('required');
      }
    });
  };
}
