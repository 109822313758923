/**
 * A helper to allow prettier to format HTML template strings
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Template_literals
 * @param {TemplateStringsArray} strings
 * @param  {...any[]} values
 * @returns string — HTML string
 */

export function html(strings, ...values) {
  let output = '';
  let index;

  for (index = 0; index < values.length; index++) {
    output += strings[index] + values[index];
  }

  output += strings[index];

  return output;
}
