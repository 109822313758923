import { Controller } from 'stimulus';

export default class LoanApplicationFormStep extends Controller {
  static targets = ['submitButton', 'field', 'backButton', 'accountExistsSpan'];

  connect() {
    $(this.element).validator({ disable: false });
    this.toggleSubmitButton();
    this.fieldTargets.forEach(el => {
      el.addEventListener('input', this.toggleSubmitButton);
    });

    this.submitButtonTarget.addEventListener('click', this.handleSubmit, false);

    if (this.hasBackButtonTarget) {
      this.backButtonTarget.addEventListener('click', this.goBack, false);
    }
  }

  disconnect() {
    $(this.element).validator('destroy');

    this.submitButtonTarget.removeEventListener(
      'click',
      this.handleSubmit,
      false,
    );
  }

  isValid = () => {
    let validFieldsCount = 0;

    this.fieldTargets.forEach(elem => {
      if (elem.checkValidity() === true) {
        validFieldsCount += 1;
      }
    });

    return validFieldsCount === this.fieldTargets.length;
  };

  goBack = e => {
    e.preventDefault();

    window.dispatchEvent(
      new CustomEvent('LoanApplicationContainer:goToStep', {
        detail: this.previousStep,
      }),
    );
  };

  goNext = () => {
    window.dispatchEvent(
      new CustomEvent('LoanApplicationContainer:goToStep', {
        detail: this.nextStep,
      }),
    );
  };

  toggleSubmitButton = () => {
    this.submitButtonTarget.disabled = !this.isValid();
  };

  handleSubmit = e => {
    if (e.target.dataset.submitForm) return;

    e.preventDefault();

    $(this.element).validator('validate');

    if (!this.isValid()) return;

    this.goNext();
  };
}
