import { Controller } from 'stimulus';

export default class AccountOptions extends Controller {
  static targets = ['accountItem', 'submitButton'];

  connect() {
    this.hideOrShowError(true);

    const mutationObserver = new MutationObserver(this.mutationCallback);

    this.accountItemTargets.forEach(checkBox => {
      mutationObserver.observe(checkBox, { attributes: true });
    });

    this.submitButtonTarget.addEventListener('click', this.validateForm, false);
  }

  disconnect() {
    this.submitButtonTarget.removeEventListener(
      'click',
      this.validateForm,
      false,
    );
  }

  mutationCallback = mutationsList => {
    mutationsList.forEach(mutation => {
      if (
        mutation.attributeName === 'class' &&
        Array.from(mutation.target.classList).includes('selectedOption')
      ) {
        this.hideOrShowError(true);
      }
    });
  };

  validateForm = e => {
    if (document.querySelectorAll('.selectedOption').length < 1) {
      e.preventDefault();
      this.hideOrShowError(false);
    }
  };

  hideOrShowError(hideAlert) {
    const errorAlert = document.getElementsByClassName(
      'account-options-form__error',
    );
    if (hideAlert) {
      errorAlert[0].classList.add('hidden');
    } else {
      errorAlert[0].classList.remove('hidden');
    }
  }
}
