import { Controller } from 'stimulus';

import { formatNumbers, formatNumbersAfterTyped } from '@ftf-old/main/helpers';

export default class BuyBoxForm extends Controller {
  connect() {
    formatNumbers();
    formatNumbersAfterTyped();
  }
}
