import axios from 'axios';

const instance = axios.create({
  headers: {
    common: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': (
        document.getElementsByTagName('meta')['csrf-token'] || {}
      ).content,
    },
  },
});

export default instance;
