import { Controller } from 'stimulus';

export default class BulkSelectForm extends Controller {
  static targets = ['checkbox', 'submit'];

  ids = {};

  isSubmitted = false;

  connect() {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.addEventListener('change', this.handleCheckboxChange, false);
    });

    this.submitTarget.addEventListener('click', this.handleSubmit, false);

    window.addEventListener('BulkSelectForm:clear', this.clearIds);
  }

  disconnect() {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.removeEventListener('change', this.handleCheckboxChange, false);
    });

    this.submitTarget.removeEventListener('click', this.handleSubmit, false);

    window.removeEventListener('BulkSelectForm:clear', this.clearIds);
  }

  handleCheckboxChange = e => {
    this.ids = {
      ...this.ids,
      [e.target.value]: e.target.checked,
    };

    this.updateUI();
  };

  clearIds = () => {
    this.ids = {};

    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = false;
    });

    this.isSubmitted = false;

    this.updateUI();
  };

  updateUI = () => {
    const { label } = this.submitTarget.dataset;

    if (Object.keys(this.ids).length > 0) {
      const activeIds = Object.keys(this.ids).filter(id =>
        Boolean(this.ids[id]),
      );

      this.submitTarget.textContent = this.isSubmitted
        ? `Update ${label} (${activeIds.length})`
        : `Add to ${label} (${activeIds.length})`;
      this.submitTarget.disabled = false;

      return;
    }

    this.submitTarget.textContent = `Add to ${label}`;
    this.submitTarget.disabled = true;
  };

  handleSubmit = () => {
    window.dispatchEvent(
      new CustomEvent('BulkSelectButton:update', { detail: { ids: this.ids } }),
    );

    this.isSubmitted = true;

    this.updateUI();
  };
}
