import * as helpers from './helpers';

const configureTablesorter = () => {
  $.tablesorter.defaults.groupSeparator = ',';
  $.tablesorter.formatFloat = function sFormat(s) {
    if (typeof s === 'string' && s.includes(',')) {
      const regexp = new RegExp(`[${this.defaults.groupSeparator}]`, 'g');
      const i = parseFloat(s.replace(regexp, ''));
      return Number.isNaN(i) ? 0 : i;
    } else if (typeof s === 'string' && s.includes('.')) {
      const i = parseFloat(s);
      return Number.isNaN(i) ? 0 : i;
    } else if (typeof s === 'number') {
      const i = parseInt(s, 10);
      return Number.isNaN(i) ? 0 : i;
    }
    return '';
  };
};

function ready() {
  configureTablesorter();

  // These methods initialize the tablesorter
  // library with the provides options
  $('#adminBorrowerTable').tablesorter({
    sortList: [[7, 1]],
  });
  $('#adminLenderTable').tablesorter({
    sortList: [[6, 1]],
  });
  $('#adminCombinedTable').tablesorter({
    sortList: [[8, 1]],
  });
  $('#adminUnknownTable').tablesorter({
    sortList: [[8, 1]],
  });
  $('#adminRecentUsersTable').tablesorter({
    sortList: [[9, 1]],
  });
  $('#adminRecentDealsTable').tablesorter({
    sortList: [[7, 1]],
  });

  // OPEN PROJECTS TABLE
  /** commenting out because empty tables cause the whole open projects page to crash  */
  $('.adminOpenDealsTable').tablesorter({
    // sortList: [[5, 1]]
  });

  $('#adminLineOfCreditTable').tablesorter({
    sortList: [[4, 1]],
  });

  $('#adminLoanLedgerActiveLoansTable').tablesorter({
    sortList: [[1, 1]],
  });

  $('#adminLoanLedgerRepaidLoansTable').tablesorter({
    sortList: [[3, 1]],
  });

  $('#adminBrokerApplicationsTable').tablesorter({
    sortList: [[6, 1]],
  });

  $('#current-investment-table').tablesorter({
    sortList: [[5, 1]],
  });

  $('#repaid-investment-table').tablesorter({
    sortList: [[4, 0]],
  });

  $('#inactive-investment-table').tablesorter({
    sortList: [[3, 1]],
  });

  $('#investor-payments-table').tablesorter({
    sortList: [[5, 1]],
  });

  $('#admin-deal-investment-list').tablesorter({
    sortList: [[5, 0]],
  });

  $('#redeveloper-projects-table').tablesorter({
    sortList: [[4, 0]],
  });

  $('.adminMyDealsStatusTable').tablesorter({
    sortList: [[7, 1]],
  });

  $('.adminMyDealsRoleTable').tablesorter({
    sortList: [[4, 1]],
  });

  // Bootstrap Javascript Features
  $("[data-toggle*='dropdown']").dropdown();
  $('[data-toggle="popover"]').popover();

  // initialize tooltip
  const tooltipPages = [
    '.trustee.deals.index',
    '.deals.show',
    '.offerings.show',
    '.users.dashboard',
  ];

  tooltipPages.forEach(page => {
    $(`${page} [data-toggle="tooltip"]`).tooltip({ trigger: 'hover' });
  });
}

// lazy load all images on each page
function addImageLazyLoad() {
  if ($('.deals.index').length) {
    $('img.lazy').lazyload({
      threshold: 800,
      skip_invisible: true,
    });
  } else {
    $('img.lazy').lazyload({
      threshold: 800,
      skip_invisible: true,
      effect: 'fadeIn',
    });
  }
}

function addAutocompleteHelpers(e) {
  const $form = $('.new-deal-hidden-form');
  helpers.loadAutoComplete($form);
  helpers.detectAddressAndSubmitForm($form);

  if (e) {
    document.removeEventListener(
      'places.autofill.load',
      addAutocompleteHelpers,
      false,
    );
  }
}

function init() {
  addImageLazyLoad();
  ready();

  if ($('.new-deal-hidden-form').length) {
    if (typeof google === 'object') {
      addAutocompleteHelpers();
    } else {
      document.addEventListener(
        'places.autofill.load',
        addAutocompleteHelpers,
        false,
      );
    }
  }
}

export default init;
