import React, { Component } from 'react';
import {
  array,
  arrayOf,
  string,
  func,
  number,
  oneOfType,
  node,
  bool,
} from 'prop-types';
import Dropzone from 'react-dropzone';
import pluralize from 'pluralize';

import Uploader from '@ftf/lib/uploader';
import { addAlert, handleError } from '@ftf/lib/alerts';
import { mbToBytes } from '@ftf-old/util/helpers';

import {
  StyledSpinner,
  Placeholder,
  style,
  activeStyle,
  disabledStyle,
  rejectStyle,
} from './styles';

export class DropArea extends Component {
  static propTypes = {
    accept: arrayOf(string),
    disabled: bool,
    children: oneOfType([arrayOf(node), node]),
    images: array,
    maxSize: number,
    onDropAccepted: func,
  };

  static defaultProps = {
    accept: ['image/jpeg', 'image/png'],
    maxSize: mbToBytes(10),
  };

  state = {
    acceptedFiles: [],
  };

  handleDropAccepted = acceptedFiles => {
    this.setState({ acceptedFiles }, async () => {
      const files = [];

      for (const file of acceptedFiles) {
        try {
          // eslint-disable-next-line no-await-in-loop
          const blob = await new Uploader(file).start();

          files.push({ ...blob, preview: file.preview });
        } catch (error) {
          handleError(error);
        }
      }

      this.setState({ acceptedFiles: [] }, () => {
        this.props.onDropAccepted(files);
      });
    });
  };

  handleDropRejected = rejectedFiles => {
    if (rejectedFiles.length === 0) {
      return;
    }

    const { accept, maxSize } = this.props;

    const message = rejectedFiles.map(file => {
      if (accept.indexOf(file.type) < 0) {
        return `File ${file.name} rejected: invalid file type`;
      }

      if (file.size > maxSize) {
        return `File ${file.name} rejected: file is too large`;
      }

      return `File ${file.name} rejected`;
    });

    addAlert({ message, type: 'error', autoDismissible: true });
  };

  render() {
    const { accept, children, disabled, images, maxSize } = this.props;

    const { acceptedFiles } = this.state;

    return (
      <Dropzone
        accept={accept}
        activeStyle={activeStyle}
        disableClick
        disabled={disabled}
        disabledStyle={disabledStyle}
        maxSize={maxSize}
        onDropAccepted={this.handleDropAccepted}
        onDropRejected={this.handleDropRejected}
        rejectStyle={rejectStyle}
        style={style}
      >
        {acceptedFiles.length > 0 && (
          <StyledSpinner
            message={`Uploading ${pluralize(
              'file',
              acceptedFiles.length,
              true,
            )}...`}
          />
        )}

        {images.length > 0 ? (
          children
        ) : (
          <Placeholder>Drag and drop images here</Placeholder>
        )}
      </Dropzone>
    );
  }
}
