import { Controller } from 'stimulus';

import { formatCurrency } from '@ftf/lib/formatters';

export default class RequestedFormFields extends Controller {
  static targets = [
    'eligibleToFundAmountField',
    'constructionExpenseField',
    'constructionExpense',
    'suggestedDisbursementAmount',
  ];

  connect() {
    this.eligibleToFundAmountFieldTarget.addEventListener(
      'input',
      this.handleEligibleToFundAmountInput,
      false,
    );

    this.populateFields();
  }

  disconnect() {
    this.eligibleToFundAmountFieldTarget.removeEventListener(
      'input',
      this.handleEligibleToFundAmountInput,
      false,
    );
  }

  populateFields = (constructionExpense = 0) => {
    const { lastEligibleToFundAmount, percentOfTotal } = this.element.dataset;

    const newConstructionExpense =
      (parseFloat(constructionExpense) || 0) -
      (parseFloat(lastEligibleToFundAmount) || 0);

    this.constructionExpenseFieldTarget.value = newConstructionExpense;
    this.constructionExpenseTarget.innerText = formatCurrency(
      newConstructionExpense,
    );

    const suggestedDisbursementAmount =
      newConstructionExpense * (parseFloat(percentOfTotal) || 0);

    this.suggestedDisbursementAmountTarget.innerText = formatCurrency(
      suggestedDisbursementAmount,
    );
  };

  handleEligibleToFundAmountInput = e => this.populateFields(e.target.value);
}
