import { Controller } from 'stimulus';
import { loadParallel } from '@parallelmarkets/vanilla';

import { handleError, handleSuccess } from '@ftf/lib/alerts';

export default class Launcher extends Controller {
  static targets = ['spinnerText'];

  async connect() {
    const {
      returnTo,
      clientId,
      email,
      callbackPath,
      environment,
    } = window.gon.parallelMarkets;

    const opts = this.getOpts(email);

    const parallel = await loadParallel({
      client_id: clientId,
      environment: environment,
      flow_type: 'overlay',
      scopes: opts.external_id
        ? ['profile identity']
        : ['profile accreditation_status'],
    });

    parallel.subscribe('auth.statusChange', result => {
      if (result.status === 'connected') {
        const urlParams = new URLSearchParams(window.location.search);
        const entityId = urlParams.get('entity_id');
        this.handleSuccess(returnTo, callbackPath, entityId);
      } else if (result.status === 'not_authorized') {
        this.handleError(returnTo);
      }
    });

    parallel.login(opts);
  }

  getOpts(email) {
    const urlParams = new URLSearchParams(window.location.search);
    const entityId = urlParams.get('entity_id');
    const externalId =
      (entityId && `investor-legal-entity:${entityId}`) || null;
    const firstName = urlParams.get('first_name') || 'John';
    const lastName = urlParams.get('last_name') || 'Doe';
    const expectedBusinessName = urlParams.get('business_name') || null;

    const getBusinessType = () => {
      // TODO map our entity type to parallel markets expected_entity_type
      return 'Limited Liability Company';
    };

    const opts = {
      email: email,
      handoff_id: !externalId ? email : null,
      external_id: externalId,
      expected_entity_type: expectedBusinessName ? getBusinessType() : 'self',
      first_name: externalId && !expectedBusinessName ? firstName : null,
      last_name: externalId && !expectedBusinessName ? lastName : null,
      expected_business_name: externalId ? expectedBusinessName : null,
    };

    for (let key in opts) {
      if (!opts[key]) {
        delete opts[key];
      }
    }

    return opts;
  }

  handleError(returnTo) {
    handleError('Accreditation application failed');
    this.spinnerTextTarget.style.display = 'block';
    window.location.href = returnTo;
  }

  async handleSuccess(returnTo, callbackPath, entityId) {
    const isLegalEntityIdentityFlow = !!entityId;

    // first show spinner msg
    this.spinnerTextTarget.style.display = 'block';

    const body = {
      type: isLegalEntityIdentityFlow ? 'identity' : 'accreditation',
      entity_id: isLegalEntityIdentityFlow ? entityId : null,
    };

    // create pending accreditation
    const resp = await fetch(callbackPath, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': (
          document.getElementsByTagName('meta')['csrf-token'] || {}
        ).content,
      },
      body: JSON.stringify(body),
    });

    // if creation of pending accreditation fails, show error message
    // but still redirect to returnTo(dashboard)
    if (!resp.ok) {
      const message = isLegalEntityIdentityFlow
        ? 'Something went wrong, please resubmit identity verification'
        : 'Something went wrong, please resubmit application';
      handleError(message);
    } else {
      const message = isLegalEntityIdentityFlow
        ? 'Identity verification submitted successfully'
        : 'Accreditation application submitted successfully';
      handleSuccess(message);
    }

    window.location.href = returnTo;
  }

  disconnect() {}
}
