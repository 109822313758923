import { Controller } from 'stimulus';

const FIVE_MS = 5;

export default class SelectionFields extends Controller {
  connect() {
    setTimeout(
      function() {
        const checkboxElement = this.checkBoxElement(this.element.id);

        if (checkboxElement.checked) {
          document
            .getElementById(this.element.id)
            .classList.toggle('selectedOption');
        }
      }.bind(this),
      FIVE_MS,
    );
  }

  toggle() {
    const name = this.selectionText(this.element);

    if (name.includes('none of the above')) {
      this.unselectAll();
    }

    const checkboxElement = this.checkBoxElement(this.element.id);
    checkboxElement.checked = !checkboxElement.checked;
    document.getElementById(this.element.id).classList.toggle('selectedOption');

    if (!name.includes('none of the above')) {
      const noneOfAboveId = this.allSelectionOptions().filter(option =>
        option.toLowerCase().includes('none of the above'),
      )[0];

      if (!noneOfAboveId) return null;

      const noneCheckboxElement = this.checkBoxElement(noneOfAboveId);
      if (noneCheckboxElement.checked) {
        noneCheckboxElement.checked = false;
        document
          .getElementById(noneOfAboveId)
          .classList.toggle('selectedOption');
      }
    }

    return null;
  }

  checkBoxElement(id) {
    return document.getElementById(id).getElementsByTagName('input')[0];
  }

  allSelectionOptions() {
    return document
      .getElementById('sign_up_selection_field_ids')
      .value.split('#')
      .map(el => `${el}_option`);
  }

  selectionText(el) {
    return `${el.querySelector('header').innerText}_option`.toLowerCase();
  }

  unselectAll() {
    const optionIds = this.allSelectionOptions();
    optionIds.forEach(optionId => {
      const checkboxElement = this.checkBoxElement(optionId);

      if (checkboxElement.checked) {
        checkboxElement.checked = !checkboxElement.checked;
        document.getElementById(optionId).classList.toggle('selectedOption');
      }
    });
  }
}
