import { Controller } from 'stimulus';
import { isNil } from 'lodash';
import { Cropper } from 'react-image-cropper';
import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import Dropzone from 'react-dropzone';

import Uploader from '@ftf/lib/uploader';
import { dataURItoBlob } from '@ftf-old/util/helpers';

export default class PictureCropper extends Controller {
  static targets = ['preview', 'modal', 'cropContainer', 'cropConfirmBtn'];

  previewUrl = null;
  droppedUrl = null;
  cropper = null;

  ACCEPTABLE_FILE_TYPES = ['image/jpeg', 'image/png'];

  connect() {
    this.cropConfirmBtnTarget.addEventListener(
      'click',
      this.handleCropConfirm,
      false,
    );
    $(this.modalTarget).on('shown.bs.modal', this.handleModalOpen);
    $(this.modalTarget).on('hidden.bs.modal', this.handleModalClose);

    this.previewUrl = this.element.dataset.previewUrl;

    this.renderDropzone();
  }

  disconnect() {
    this.cropConfirmBtnTarget.removeEventListener(
      'click',
      this.handleCropConfirm,
      false,
    );
    $(this.modalTarget).off('shown.bs.modal', this.handleModalOpen);
    $(this.modalTarget).on('hidden.bs.modal', this.handleModalClose);
  }

  handleDrop = files => {
    const [file] = files;

    if (isNil(file)) {
      return;
    }

    this.droppedUrl = window.URL.createObjectURL(file);

    $(this.modalTarget).modal('show');
  };

  previewStyle = () => {
    return this.previewUrl
      ? {
          backgroundImage: `url(${this.previewUrl}`,
        }
      : {};
  };

  renderDropzone = () => {
    render(
      <Dropzone
        onDropAccepted={this.handleDrop}
        accept={this.ACCEPTABLE_FILE_TYPES.join(',')}
        className="picture-cropper__preview"
        style={this.previewStyle()}
      >
        {!this.previewUrl && (
          <p className="picture-cropper__preview-label">
            Drag and drop image here
          </p>
        )}
      </Dropzone>,
      this.previewTarget,
    );
  };

  handleModalOpen = () => {
    render(
      <Cropper
        src={this.droppedUrl}
        ratio={1.33}
        width={600}
        ref={cr => {
          this.cropper = cr;
        }}
      />,
      this.cropContainerTarget,
    );
  };

  handleModalClose = () => {
    unmountComponentAtNode(this.cropContainerTarget);
    window.URL.revokeObjectURL(this.dropperUrl); // free memory

    this.renderDropzone();
  };

  handleCropConfirm = async () => {
    const cropUri = this.cropper.crop();
    const blob = await this.convertToJpegBlob(cropUri);
    const uploadedBlob = await new Uploader(blob).start();

    this.previewUrl = cropUri;

    $(this.modalTarget).modal('hide');

    window.dispatchEvent(
      new CustomEvent('PictureCropper:BlobUploaded', {
        detail: { ...uploadedBlob },
      }),
    );
  };

  convertToJpegBlob = imgUri => {
    return new Promise(resolve => {
      const canvas = document.createElement('canvas');
      const imageObj = new Image();
      imageObj.src = imgUri;

      imageObj.onload = () => {
        canvas.width = imageObj.width;
        canvas.height = imageObj.height;
        canvas.getContext('2d').drawImage(imageObj, 0, 0);
        const newDataURL = canvas.toDataURL('image/jpeg', 0.7);

        resolve(dataURItoBlob(newDataURL, 'thumbnail.jpg'));
      };
    });
  };
}
