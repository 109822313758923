import { Controller } from 'stimulus';

import { trackGAEvent } from '@ftf-old/main/tracking';

export default class Track extends Controller {
  isTracked = false;

  connect() {
    this.element.addEventListener(this.trigger, this.handleTrigger, false);
  }

  disconnect() {
    this.element.removeEventListener(this.trigger, this.handleTrigger, false);
  }

  get trigger() {
    return this.element.dataset.trigger || 'click';
  }

  eventCallback = ({ ctrlKey, shiftKey, altKey, metaKey }) => {
    this.isTracked = true;

    this.element.dispatchEvent(
      new MouseEvent(this.trigger, {
        bubbles: true,
        cancelable: true,
        view: window,
        ctrlKey,
        shiftKey,
        altKey,
        metaKey,
      }),
    );
  };

  handleTrigger = e => {
    if (this.isTracked) {
      this.isTracked = false;

      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const {
      dataset: { category, action, label },
    } = this.element;

    if (action && action === 'clicked-logout' && window.Intercom) {
      window.Intercom('shutdown');
    }

    const event = {
      category,
      action,
      label,
    };

    trackGAEvent(event, () => this.eventCallback(e));
  };
}
