import '@stimulus/polyfills';
import { Application } from 'stimulus';
import { pascalCase } from 'change-case';

import { reportError } from '@ftf/lib/reporting';

const application = Application.start();

const defaultErrorHandler = application.handleError;

application.handleError = (error, message, detail) => {
  defaultErrorHandler(error, message, detail);
  reportError(error, {
    message,
    detail,
  });
};

function requireBlocks(context) {
  context.keys().forEach(key => {
    const [dirname, filename] = key.split('/').slice(-2);
    const [blockname] = filename.split('.');

    if (blockname === dirname) {
      application.register(pascalCase(blockname), context(key).default);
    }
  });
}

requireBlocks(require.context('@ftf-blocks', true, /\.js$/));
