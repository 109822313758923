import { Controller } from 'stimulus';

export default class NotesUpdateForm extends Controller {
  static targets = ['note'];

  toggleEditableNote = e => {
    const isNoteLocked = e.target.classList.contains('fa-pencil');
    if (isNoteLocked) {
      // toggle to editable state
      this.noteTarget.readOnly = false;
      e.target.classList.remove('fa-pencil');
      e.target.classList.add('fa-lock');
    } else {
      // toggle to locked state
      this.noteTarget.readOnly = true;
      e.target.classList.remove('fa-lock');
      e.target.classList.add('fa-pencil');
    }
  };
}
