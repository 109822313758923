import { Controller } from 'stimulus';
import { createConsumer } from '@rails/actioncable';

export default class DocumentWebSocket extends Controller {
  connect() {
    this.subscription = createConsumer().subscriptions.create(
      { channel: 'DocumentsChannel', deal_id: this.element.dataset.dealId },
      {
        connected: () => {},

        disconnected: () => {},

        received: data => {
          let span = document.querySelector('#documents-download-buttons');
          span.innerHTML = data.download_buttons;
        },
      },
    );
  }

  disconnect() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
