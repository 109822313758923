import { Controller } from 'stimulus';

import { loadAutoComplete } from '@ftf-old/main/helpers';
import { trackGAEvent } from '@ftf-old/main/tracking';

export default class ApplyForm extends Controller {
  static targets = ['addressField', 'submitButton'];

  isInitialized = false;

  connect() {
    this.addressFieldTarget.addEventListener(
      'keydown',
      this.handleAddressKeydown,
      false,
    );

    if (typeof google === 'object') {
      this.addAutocompleteToForm();
      return;
    }

    document.addEventListener(
      'places.autofill.load',
      this.addAutocompleteToForm,
      false,
    );

    this.submitButtonTarget.addEventListener('click', this.handleSubmit, false);
  }

  disconnect() {
    this.addressFieldTarget.removeEventListener(
      'keydown',
      this.handleAddressKeydown,
      false,
    );

    document.removeEventListener(
      'places.autofill.load',
      this.addAutocompleteToForm,
      false,
    );

    this.submitButtonTarget.removeEventListener(
      'click',
      this.handleSubmit,
      false,
    );
  }

  addAutocompleteToForm = () => {
    if (this.isInitialized) {
      return;
    }

    const $form = $(this.element);
    loadAutoComplete($form);

    this.isInitialized = true;
  };

  handleAddressKeydown = e => {
    if (e.which !== 13) {
      return;
    }

    e.preventDefault();
  };

  handleSubmit = () => {
    const { category, action, label } = this.submitButtonTarget.dataset;

    trackGAEvent({ category, action, label });
  };
}
