import { DirectUpload } from '@rails/activestorage';

const UPLOAD_URL = '/rails/active_storage/direct_uploads';

class Uploader {
  constructor(file, onProgress = () => {}) {
    this.onProgress = onProgress;
    this.upload = new DirectUpload(file, UPLOAD_URL, this);
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener('progress', this.onProgress);
  }

  start() {
    return new Promise((resolve, reject) => {
      this.upload.create((error, blob) => {
        if (error) {
          reject(error);
        } else {
          resolve(blob);
        }
      });
    });
  }
}

export default Uploader;
