import { Controller } from 'stimulus';
import flatpickr from 'flatpickr';
import moment from 'moment';

import { validators } from '@ftf-old/main/helpers';
import checkIsMobile from '@ftf-old/util/checkIsMobile';

export default class ConnectionForm extends Controller {
  static targets = [
    'phone',
    'states',
    'datePicker',
    'dropdown',
    'dateValidation',
  ];

  connect() {
    if (this.dropdownTarget.value === '') {
      this.setCreditDisplay('none');
    }

    if (this.datePickerTarget.value) {
      this.checkDateValidation(this.datePickerTarget.value);
    }

    this.dropdownTarget.addEventListener(
      'change',
      this.toggleCreditDisplay,
      false,
    );

    this.datePickerTargets.forEach(dateInput => {
      flatpickr(dateInput, { minDate: '2000-01', allowInput: true });
      dateInput.addEventListener('change', this.checkDateValidation, false);
    });

    $(this.element).validator({
      custom: {
        phone: validators.validations.phone,
      },
      errors: {
        phone: validators.errors.phone,
      },
    });

    $(this.phoneTarget).bfhphone(this.phoneTarget.dataset);

    $(this.stateTargets).selectpicker({
      hideDisabled: true,
      mobile: checkIsMobile(),
    });
  }

  disconnect() {
    $(this.element).validator('destroy');

    this.dropdownTarget.removeEventListener(
      'change',
      this.toggleCreditDisplay,
      false,
    );

    $(this.phoneTarget).removeData('bfhphone');

    $(this.selectTargets).selectpicker('destroy');
  }

  checkDateValidation = e => {
    const diff = e.target
      ? moment().diff(moment(e.target.value), 'days')
      : moment().diff(moment(e), 'days');
    document.querySelector('.date-validation').style.paddingTop = '12px';
    if (diff <= 30) {
      document.querySelector('.date-validation').innerText = 'valid';
      document.querySelector('.date-validation').style.color = 'green';
    } else {
      document.querySelector('.date-validation').innerText = 'invalid';
      document.querySelector('.date-validation').style.color = 'red';
    }
  };

  toggleCreditDisplay = e => {
    if (e.target.value !== 'personal_guarantor') {
      this.setCreditDisplay('none', false);
    } else {
      this.setCreditDisplay('block', true);
    }
  };

  setCreditDisplay = (value, required) => {
    document.querySelectorAll('.credit-score').forEach(item => {
      item.style.display = value;
      if (required === true) {
        document.querySelector('.score').setAttribute('required', '');
        document.querySelector('.report-date').setAttribute('required', '');
      } else {
        document.querySelector('.score').removeAttribute('required');
        document.querySelector('.report-date').removeAttribute('required');
      }
    });
  };
}
