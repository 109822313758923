import { Controller } from 'stimulus';
import useValidation from '@lysyi3m/stimulus-use-validation';

export default class NewTaskModal extends Controller {
  static targets = ['form', 'workflowStatus', 'requiredTaskCheckbox'];

  connect() {
    useValidation(this, {
      errorClassName: 'is-invalid',
      errorSelector: '.invalid-feedback',
      form: this.formTarget,
    });

    this.workflowStatusTarget.addEventListener(
      'change',
      this.addRequiredCheckbox,
    );
  }

  disconnect() {
    this.workflowStatusTarget.removeEventListener(
      'change',
      this.addRequiredCheckbox,
    );
  }

  addRequiredCheckbox = e => {
    const withRequiredOption = [
      'closing_scheduled',
      'closing_specialist_audit',
    ];

    if (withRequiredOption.includes(e.target.value)) {
      this.requiredTaskCheckboxTarget.classList.remove('d-none');
    } else {
      this.requiredTaskCheckboxTarget.classList.add('d-none');
    }
  };
}
