import { Controller } from 'stimulus';
import { debounce } from 'lodash';

export default class BorrowerSearchProjects extends Controller {
  static targets = ['addressSearching', 'form'];

  initialize() {
    this.clearSearch = document.getElementById('clear-address-searching');
    this.clearSearch.addEventListener('click', () => {
      this.addressSearchingTarget.value = '';
      this.formTarget.submit();
    });
    this.debouncedHandleInput = debounce(this.handleInput, 300);
  }

  connect() {
    $(this.element).on('input', this.debouncedHandleInput);
  }

  disconnect() {
    $(this.element).off('input', this.debouncedHandleInput);
  }

  // FOR THE FUTURE, SEARCH FOR THE ADDRESS HERE
  handleInput = () => {};
}
