import { Controller } from 'stimulus';
import Chart from 'chart.js/auto';
import { set } from 'lodash';

export default class DefaultChart extends Controller {
  initialize() {
    this.ctx = null;
    this.chart = null;
    this.dataset = this.element.dataset;
  }

  config = () => {
    const dataset = this.dataset;

    let cfg = {
      ...JSON.parse(dataset.chart),
      type: dataset.chartType || 'line',
    };

    this.configFormatters(cfg, dataset);
    set(cfg, 'options.plugins.title.display', true);
    set(
      cfg,
      'options.plugins.title.text',
      dataset.title || 'Set title in dataset',
    );
    set(cfg, 'options.plugins.title.font.size', dataset.fontSize || '22');
    set(cfg, 'options.plugins.title.padding', 0);
    set(cfg, 'options.plugins.title.color', '#333333');
    set(cfg, 'options.plugins.title.font.family', 'Lato');

    set(cfg, 'options.plugins.subtitle.display', true);
    set(cfg, 'options.plugins.subtitle.font.size', dataset.fontSize - 9 || 14);
    set(
      cfg,
      'options.plugins.subtitle.text',
      dataset.subtitle || 'Set subtitle in dataset',
    );
    set(cfg, 'options.plugins.subtitle.padding', 20);

    set(cfg, 'options.plugins.legend.display', false);

    set(cfg, 'options.scales.x.grid.display', false);
    set(cfg, 'options.scales.y.grid.display', false);

    set(cfg, 'options.maintainAspectRatio', false);

    return cfg;
  };

  configFormatters(cfg, dataset) {
    set(
      cfg,
      'options.scales.x.ticks.callback',
      this.formatters[dataset.xFormatter] || null,
    );
    set(
      cfg,
      'options.scales.y.ticks.callback',
      this.formatters[dataset.yFormatter] || null,
    );

    if (dataset.xAxisData) {
      this.xAxisData = JSON.parse(dataset.xAxisData);
      set(
        cfg,
        'options.scales.x.ticks.callback',
        (_value, idx) => this.xAxisData[idx],
      );
    }
  }

  connect() {
    this.ctx = this.element.querySelector('.chart-container__default-chart');
    this.chart = new Chart(this.ctx, this.config());
  }

  formatters = {
    // posible args (value, _idx, _values)
    percent: value => `%${value}`,
    dollar: value => `$${value}`,
    // snake_cased because key comes from ruby
    currency_short_hand: value => {
      const nFormatter = function(num) {
        if (num >= 1000000000) {
          return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
        }
        if (num >= 1000000) {
          return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
        }
        if (num >= 1000) {
          return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
        }
        return num;
      };
      return `$${nFormatter(value)}`;
    },
    // snake cased because value originates in html.erb(ruby)
    fixed_x_axis: (_value, idx) => {
      return this.xAxisData[idx];
    },
  };
  disconnect() {}
}
