import { formatNumbersAfterTyped } from '@ftf-old/main/helpers';

import DealApplicationFormStep from '../deal_application_form_step/deal_application_form_step';

export default class FormStepFinancials extends DealApplicationFormStep {
  static targets = ['ltarvValue', 'ltarvMessage'];

  previousStep = 'address';
  nextStep = 'experience';

  connect() {
    super.connect();

    formatNumbersAfterTyped();

    this.fieldTargets.forEach(elem => {
      elem.addEventListener('input', this.calculateLTARV, false);
    });
  }

  disconnect() {
    super.disconnect();

    this.fieldTargets.forEach(elem => {
      elem.removeEventListener('input', this.calculateLTARV, false);
    });
  }

  calculateLTARV = () => {
    const purchasePrice = parseInt(
      this.element
        .querySelector(
          "input[name='deal_application[flexible_purchase_price]']",
        )
        .value.replace(/\D/g, ''),
      10,
    );

    const rehabBudget = parseInt(
      this.element
        .querySelector(
          "input[name='deal_application[flexible_expected_rehab_budget]']",
        )
        .value.replace(/\D/g, ''),
      10,
    );

    const borrowerARV = parseInt(
      this.element
        .querySelector("input[name='deal_application[flexible_borrower_arv]']")
        .value.replace(/\D/g, ''),
      10,
    );

    const availableCapital = parseInt(
      this.element
        .querySelector("input[name='deal_application[flexible_down_payment]']")
        .value.replace(/\D/g, ''),
      10,
    );

    const LTARV =
      (purchasePrice - availableCapital + rehabBudget) / borrowerARV;

    this.ltarvValueTarget.textContent = Number.isNaN(LTARV)
      ? '--'
      : (LTARV * 100).toFixed(1);

    let isValid = false;
    let message = '';

    if (Number.isNaN(LTARV)) {
      isValid = false;
      message = '';
    }

    if (LTARV < 0) {
      isValid = false;
      message =
        'Hmmm, something looks wrong here. Take a second look at these numbers.';
    }

    if (LTARV < 0.6) {
      isValid = true;
      message = 'Your numbers look great!';
    }

    if (LTARV <= 0.7 && LTARV >= 0.6) {
      isValid = true;
      message = 'These numbers look within a range that we can work with!';
    }

    if (LTARV > 0.7) {
      isValid = false;
      message =
        'These numbers look kind of tight. Reduce purchase price and rehab budget or increase ARV or Available capital if possible.';
    }

    this.ltarvMessageTarget.textContent = message;
    this.ltarvMessageTarget.classList.toggle('is-valid', isValid);

    return { isValid, LTARV };
  };

  isValid = () => {
    let validFieldsCount = 0;

    this.fieldTargets.forEach(elem => {
      if (elem.checkValidity() === true) {
        validFieldsCount += 1;
      }
    });

    const { isValid } = this.calculateLTARV();

    return validFieldsCount === this.fieldTargets.length && isValid;
  };
}
