import { Controller } from 'stimulus';
import { isNil } from 'lodash';

export default class Popover extends Controller {
  timer = undefined;
  id = undefined;

  connect() {
    const {
      container,
      content,
      persisted,
      placement,
      title,
    } = this.element.dataset;

    $(this.element)
      .popover({
        animation: false,
        container: isNil(container) ? 'body' : container,
        content,
        html: true,
        placement: isNil(placement) ? 'auto top' : placement,
        title,
        trigger: 'manual',
      })
      .on('mouseenter', this.handleMouseEnter)
      .on('shown.bs.popover', this.handleShown)
      .on('hidden.bs.popover', this.handleHidden);

    if (isNil(persisted)) {
      $(this.element).on('mouseleave', this.handleMouseLeave);
    }
  }

  disconnect() {
    const { persisted } = this.element.dataset;

    $(this.element)
      .popover('destroy')
      .off('mouseenter', this.handleMouseEnter)
      .off('shown.bs.popover', this.handleShown)
      .off('hidden.bs.popover', this.handleHidden);

    if (isNil(persisted)) {
      $(this.element).off('mouseleave', this.handleMouseLeave);
    }
  }

  handleMouseEnter = () => {
    $(this.element).popover('show');
  };

  handleMouseMove = e => {
    clearTimeout(this.timer);

    this.timer = setTimeout(() => this.handleMouseStop(e), 150);
  };

  handleMouseStop = e => {
    if (isNil(e.target)) {
      return;
    }

    if (this.element.contains(e.target)) {
      return;
    }

    if (e.target.closest(`.popover[id=${this.id}]`)) {
      return;
    }

    $(this.element).popover('hide');
  };

  handleMouseLeave = () => {
    $(this.element).popover('hide');
  };

  handleShown = () => {
    const { persisted } = this.element.dataset;

    if (isNil(persisted)) {
      return;
    }

    window.addEventListener('mousemove', this.handleMouseMove, false);

    this.id = $(this.element).data('bs.popover').$tip[0].id;
  };

  handleHidden = () => {
    const { persisted } = this.element.dataset;

    if (isNil(persisted)) {
      return;
    }

    window.removeEventListener('mousemove', this.handleMouseMove, false);

    this.id = undefined;
  };
}
