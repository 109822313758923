import { Controller } from 'stimulus';

export default class ConstructionDrawEditRequestedForm extends Controller {
  static targets = ['titleInsuranseAdjustedControl', 'titleCompanyInfo'];

  connect() {
    this.titleInsuranseAdjustedControlTarget.addEventListener(
      'change',
      this.handleTitleInsuranseAdjustedChanged,
      false,
    );
  }

  disconnect() {
    this.titleInsuranseAdjustedControlTarget.removeEventListener(
      'change',
      this.handleTitleInsuranseAdjustedChanged,
      false,
    );
  }

  handleTitleInsuranseAdjustedChanged = e => {
    if (!this.hasTitleCompanyInfoTarget) return;

    this.titleCompanyInfoTarget.classList.toggle('d-none', e.target.checked);
  };
}
