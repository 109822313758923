import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class ExportCsv extends Controller {
  handleSubmit(e) {
    e.preventDefault();
    let href = window.location.href;
    let dealId = this.element.dataset.id;
    let page = this.element.dataset.page;
    Rails.ajax({
      type: 'get',
      url: `/admin/export_deals/csv_export?deal_id=${dealId}&page=${page}`,
    });

    window.location = `/admin/export_deals/csv_export?deal_id=${dealId}&page=${page}`;

    setTimeout(() => {
      window.location = `${href}`;
    }, 2000);
  }
}
