/* global Turbolinks */
import { Controller } from 'stimulus';
import { isNil } from 'lodash';
import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';

import { ImagesControl } from '@ftf/components/ImagesControl';
import { handleAJAXError, handleInfo, handleError } from '@ftf/lib/alerts';

export default class InspectionForm extends Controller {
  static targets = [
    'archiveReportButton',
    'file',
    'fileField',
    'fileName',
    'notesField',
    'notes',
    'picturesContainer',
    'reportId',
  ];

  pictures = [];

  connect() {
    $(this.element).validator();

    this.fileTarget.addEventListener('click', this.handleFileClick);

    this.fileFieldTarget.addEventListener('change', this.handleFileFieldChange);

    if (this.hasArchiveReportButtonTarget) {
      $(this.archiveReportButtonTarget).on({
        'ajax:success': this.handleArchiveReportSuccess,
        'ajax:error': handleAJAXError,
      });
    }

    $(this.element).on({
      'ajax:success': this.handleFormRequest,
      submit: this.handleFormRequest,
    });

    this.pictures = JSON.parse(this.picturesContainerTarget.dataset.pictures);

    this.renderImagesControl();
  }

  disconnect() {
    $(this.element).validator('destroy');

    this.fileTarget.removeEventListener('click', this.handleFileClick);

    this.fileFieldTarget.removeEventListener(
      'change',
      this.handleFileFieldChange,
    );

    if (this.hasArchiveReportButton) {
      $(this.archiveReportButton).off({
        'ajax:success': this.handleArchiveReportSuccess,
        'ajax:error': handleAJAXError,
      });
    }

    $(this.element).off({
      'ajax:success': this.handleFormRequest,
      submit: this.handleFormRequest,
    });

    unmountComponentAtNode(this.picturesContainerTarget);
  }

  handleFileClick = () => {
    if (this.fileTarget.className.indexOf('is-disabled') < 0) {
      return;
    }

    handleError('Previously uploaded report must be archived first', false);
  };

  handleFileFieldChange = e => {
    const [file] = e.target.files;

    if (isNil(file)) {
      return;
    }

    this.fileNameTarget.textContent = file.name;

    this.toggleNotesField(true);
  };

  handleArchiveReportSuccess = () => {
    handleInfo('Report has been archived successfully!', {
      autoDismissible: true,
    });

    this.archiveReportButtonTarget.classList.add('hidden');

    this.fileTarget.classList.remove('is-disabled');
    this.fileFieldTarget.disabled = false;
    this.fileNameTarget.textContent = '';
    this.reportIdTarget.value = '';

    this.toggleNotesField();
  };

  toggleNotesField = (show = false) => {
    this.notesTarget.classList.toggle('hidden', !show);
    this.notesFieldTarget.value = '';
  };

  handleFormRequest = () => {
    Turbolinks.clearCache();
  };

  handleDropAccepted = acceptedFiles => {
    const files = acceptedFiles.map(file => ({
      id: file.id,
      preview: file.preview,
      signed_id: file.signed_id,
    }));

    this.pictures = [...this.pictures, ...files];

    this.renderImagesControl();
  };

  handleSortEnd = sortedFiles => {
    this.pictures = sortedFiles;

    this.renderImagesControl();
  };

  handleDelete = id => {
    this.pictures = this.pictures.filter(image => image.id !== id);

    this.renderImagesControl();
  };

  handleSubmit = e => {
    e.target.setAttribute('disabled', 'disabled');
  };

  renderImagesControl = () => {
    render(
      <ImagesControl
        images={this.pictures}
        name="inspection[pictures_attributes]"
        onDropAccepted={this.handleDropAccepted}
        onSortEnd={this.handleSortEnd}
        onDelete={this.handleDelete}
      />,
      this.picturesContainerTarget,
    );
  };
}
