import { Controller } from 'stimulus';

export default class Note extends Controller {
  static targets = ['noteHeader'];

  connect() {
    this.noteHeaderTargets.forEach(el => {
      el.addEventListener('click', this.toggleDropdownIcon, false);
    });
  }

  disconnect() {
    this.noteHeaderTargets.forEach(el => {
      el.removeEventListener('click', this.toggleDropdownIcon, false);
    });
  }

  toggleDropdownIcon = el => {
    if (el.target.localName === 'a') {
      if (el.target.ariaExpanded === 'false') {
        el.target.innerHTML = '-';
        el.target.nextElementSibling.style.display = 'none';
      } else {
        el.target.innerHTML = '+';
        el.target.nextElementSibling.style.display = 'inline-block';
      }
    }
  };
}
