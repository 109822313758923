import { Controller } from 'stimulus';

import { LINK_TO_DEAL_DOCS, EXP_DATE_LABELS } from '@ftf/lib/documentLabels';

export default class DocumentsUploadsFields extends Controller {
  static targets = [
    'isPublicBlock',
    'isPublicCheckbox',
    'labelField',
    'removeFieldsButton',
    'assetStatementFields',
    'achFormFields',
    'tiedToPgCheckbox',
    'expirationDateBlock',
  ];

  connect() {
    $.fn.selectpicker.Constructor.BootstrapVersion = '4';

    $(this.labelFieldTarget).selectpicker({
      liveSearch: true,
      liveSearchPlaceholder: 'Search',
      style: 'btn-sm outline-none border',
    });

    this.labelFieldTarget.addEventListener(
      'change',
      this.handleLabelChange,
      false,
    );

    this.removeFieldsButtonTarget.addEventListener(
      'click',
      this.handleRemoveFieldsButtonClick,
    );
  }

  disconnect() {
    $(this.labelFieldTarget).selectpicker('destroy');

    this.labelFieldTarget.removeEventListener(
      'change',
      this.handleLabelChange,
      false,
    );

    this.removeFieldsButtonTarget.removeEventListener(
      'click',
      this.handleRemoveFieldsButtonClick,
    );
  }

  handleLabelChange = e => {
    this.isPublicCheckboxTarget.checked = false;
    this.isPublicBlockTarget.classList.toggle(
      'd-none',
      !LINK_TO_DEAL_DOCS.includes(e.currentTarget.value),
    );

    this.expirationDateBlockTarget.classList.toggle(
      'd-none',
      !EXP_DATE_LABELS.includes(e.currentTarget.value),
    );

    this.expirationDateBlockTarget.classList.toggle(
      'd-none',
      !EXP_DATE_LABELS.includes(e.currentTarget.value),
    );

    if (e.currentTarget.value === 'asset_documentation') {
      this.assetStatementFieldsTarget.classList.remove('d-none');
    } else if (
      this.assetStatementFieldsTarget.classList.contains('d-none') === false
    ) {
      this.assetStatementFieldsTarget.classList.add('d-none');
    }

    if (e.currentTarget.value === 'ach_form') {
      this.achFormFieldsTarget.classList.remove('d-none');
    } else if (
      this.achFormFieldsTarget.classList.contains('d-none') === false
    ) {
      this.achFormFieldsTarget.classList.add('d-none');
    }
  };

  handleAchCheckbox(e) {
    if (e.target.checked === true) {
      e.target.value = true;
    } else {
      e.target.value = false;
    }
  }

  handleRemoveFieldsButtonClick = () => this.element.remove();

  checkAccountHolderField = e => {
    if (e.target.value.length > 0) {
      this.tiedToPgCheckboxTarget.disabled = false;
    } else {
      this.tiedToPgCheckboxTarget.disabled = true;
    }
  };
}
