import { Controller } from 'stimulus';
import Cookies from 'js-cookie';

import { isValidEmail } from '@ftf-old/util/helpers';
import { trackGAEvent } from '@ftf-old/main/tracking';

export default class SubscribeForm extends Controller {
  static targets = [
    'controls',
    'emailField',
    'hutkField',
    'message',
    'pageNameField',
    'pageUrlField',
    'submitButton',
  ];

  connect() {
    this.populateFields();

    $(this.element).on({
      'ajax:before': this.handleSubmit,
      'ajax:success': this.handleSubmitSuccess,
      'ajax:error': this.handleSubmitError,
    });
  }

  disconnect() {
    $(this.element).off({
      'ajax:before': this.handleSubmit,
      'ajax:success': this.handleSubmitSuccess,
      'ajax:error': this.handleSubmitError,
    });
  }

  populateFields = () => {
    this.hutkFieldTarget.value = Cookies.get('hubspotutk');
    this.pageNameFieldTarget.value = document.title;
    this.pageUrlFieldTarget.value = window.location.href;
  };

  handleSubmit = () => {
    const { category, action, label } = this.submitButtonTarget.dataset;

    trackGAEvent({ category, action, label });

    const isValid = isValidEmail(this.emailFieldTarget.value);

    if (isValid) {
      this.hideMessage();

      return true;
    }

    this.showMessage('validation');

    return false;
  };

  handleSubmitSuccess = () => {
    this.showMessage('success');

    this.controlsTarget.style.display = 'none';
  };

  handleSubmitError = () => {
    this.showMessage('error');
  };

  showMessage = type => {
    const messages = {
      error: 'Sorry, something went wrong.',
      success: 'Thanks for signing up!',
      validation: 'Please enter a valid email address.',
    };

    this.messageTarget.innerHTML = messages[type];
    this.messageTarget.style.display = 'block';
  };

  hideMessage = () => {
    this.messageTarget.innerHTML = '';
    this.messageTarget.style.display = 'none';
  };
}
