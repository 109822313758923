import { Controller } from 'stimulus';

import checkIsMobile from '@ftf-old/util/checkIsMobile';
import { validators } from '@ftf-old/main/helpers';
import { trackGAEvent } from '@ftf-old/main/tracking';

export default class CreditApplicationForm extends Controller {
  static targets = ['select', 'phone', 'submitButton'];

  connect() {
    $(this.element).validator({
      custom: {
        phone: validators.validations.phone,
      },
      errors: {
        phone: validators.errors.phone,
      },
    });

    $(this.selectTargets).selectpicker({
      hideDisabled: true,
      mobile: checkIsMobile(),
    });

    $(this.phoneTarget).bfhphone(this.phoneTarget.dataset);

    this.submitButtonTarget.addEventListener('click', this.handleSubmit, false);
  }

  disconnect() {
    $(this.element).validator('destroy');

    $(this.selectTargets).selectpicker('destroy');

    $(this.phoneTarget).removeData('bfhphone');

    this.submitButtonTarget.removeEventListener(
      'click',
      this.handleSubmit,
      false,
    );
  }

  handleSubmit = () => {
    const { category, action } = this.submitButtonTarget.dataset;

    trackGAEvent({ category, action });
  };
}
