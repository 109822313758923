import { stringifyUrl } from 'query-string';

/** helpers for term sheet generation data */
export const urlMaker = (tsData, hasBroker) => {
  const url = document.querySelector('[name~=signature_service][content]')
    .content;

  const query = hasBroker
    ? tsData
    : Object.keys(tsData).reduce((acc, key) => {
        if (key.toLowerCase().indexOf('broker') < 0) {
          acc[key] = tsData[key];
        }

        return acc;
      }, {});

  return stringifyUrl(
    { url, query },
    {
      skipNull: true,
      skipEmptyString: true,
    },
  );
};

export const titlizeTsAttrs = {
  formatted_funding_request_amount: 'Funding Request Amount',
  formatted_month_term: 'Month Term',
  formatted_extension_term: 'Extension Term',
  formatted_annual_percentage_rate: 'APR',
  number_of_construction_draws: 'Number of Draws',
  formatted_prepaid_interest_period: 'Prepaid Interest Period',
  formatted_legal_closing_fee: 'Legal Closing Fee',
  formatted_borrower_deposit: 'Borrower Deposit',
  formatted_broker_fee_percentage: 'Broker Fee Percentage',
  formatted_purchase_distribution: 'Purchase Distribution',
  formatted_prepaid_interest_amount: 'Prepaid Interest Amount',
  debt_servicing_type: 'Debt Servicing Type',
  formatted_origination_fee_percentage: 'Origination Fee',
  formatted_remaining_construction_holdback: 'Remaining Construction Holdback',
  formatted_draw_at_close: 'Draw at Close',
  formatted_payoff_fee_percentage: 'Payoff Points',
  formatted_initial_distribution:
    'Initial Distribution (Purch. Dist. Amount + Draw at Close)',
};
