import { Controller } from 'stimulus';

export default class TaxEmailBanner extends Controller {
  static targets = ['close'];

  connect() {
    this.closeTarget.addEventListener('click', this.hideAlertBanner);
  }

  disconnect() {
    this.closeTarget.removeEventListener('click', this.hideAlertBanner);
  }

  hideAlertBanner = () => {
    const alertBanner = this.element.closest('.alerts__alert_tax_email');
    alertBanner.style.display = 'none';
  };
}
