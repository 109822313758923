import DealApplicationFormStep from '../deal_application_form_step/deal_application_form_step';

export default class FormStepExperience extends DealApplicationFormStep {
  previousStep = 'financials';

  connect() {
    super.connect();
  }

  disconnect() {
    super.disconnect();
  }

  validateCaptcha = () => {
    const captchaResponse = this.element.querySelector('#g-recaptcha-response');

    if (!captchaResponse) {
      return true;
    }

    return captchaResponse.value.length > 0;
  };

  isValid = () => {
    let validFieldsCount = 0;

    this.fieldTargets.forEach(elem => {
      if (elem.checkValidity() === true) {
        validFieldsCount += 1;
      }
    });

    const isValid = this.validateCaptcha();

    return validFieldsCount === this.fieldTargets.length && isValid;
  };

  handleSubmit = () => {
    $(this.element).validator('validate');

    return this.isValid();
  };
}
