// import 'expose-loader?$!jquery';
import 'expose-loader?jQuery!jquery';
window.$ = window.jQuery;
import 'bootstrap-sass';
import 'jquery-ujs';
import './bootstrap-formhelpers-custom.min';
import './jquery.easing';
import './lazyload.min';
import './validator';
import './jquery.tablesorter';
import './cocoon';
import './bootstrap-select.min';


/**
 * Temporary Bootstrap hacks.
 *
 * The main purpose of this code is to provide compatibility of Bootstrap v3's JS with Bootstrap v4 markup.
 *
 * TODO: use fully separate layout with separate JS pack
 */
$(document).on('turbolinks:load', () => {
  $(document).on({
    'shown.bs.dropdown': (e) => {
      e.target.classList.add('show')
      e.target.querySelector('.dropdown-menu').classList.add('show')
    },
    'hide.bs.dropdown': (e) => {
      e.target.classList.remove('show')
      e.target.querySelector('.dropdown-menu').classList.remove('show')
    }
  })

  $(document).on({
    'shown.bs.modal': (e) => {
      e.target.classList.add('show')
      document.querySelector('.modal-backdrop').classList.add('show')
    },
    'hide.bs.modal': (e) => {
      e.target.classList.remove('show')
      document.querySelector('.modal-backdrop').classList.remove('show')
    }
  })

  $(document).on({
    'shown.bs.tooltip': (e) => {
      const tooltipId = e.target.getAttribute('aria-describedby')

      if (!tooltipId) return

      document.getElementById(tooltipId).classList.add('show')
    },
    'hide.bs.tooltip': (e) => {
      const tooltipId = e.target.getAttribute('aria-describedby')

      if (!tooltipId) return

      document.getElementById(tooltipId).classList.remove('show')
    }
  })

  $(document).on({
    'shown.bs.select': (_, e) => {
      e.target.querySelector('.dropdown-menu.inner').classList.add('show');
      e.target.querySelectorAll('a').forEach(el => el.classList.add('dropdown-item'));
    },
    'hidden.bs.select': (_, e) => {
      e.target.querySelector('.dropdown-menu.inner').classList.remove('show');
      e.target.querySelectorAll('a').forEach(el => el.classList.remove('dropdown-item'));
    }
  })
})

$(document).on('turbolinks:before-cache', () => {
  $(document).off('shown.bs.dropdown hide.bs.dropdown');

  $(document).off('shown.bs.modal hide.bs.modal');

  $(document).off('shown.bs.tooltip hide.bs.tooltip');

  $(document).off('shown.bs.select hidden.bs.select');
})
