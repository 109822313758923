import { Controller } from 'stimulus';
import { debounce } from 'lodash';

import checkIsMobile from '@ftf-old/util/checkIsMobile';

export default class Header extends Controller {
  static targets = ['toggleButton', 'toggleIcon', 'signUpButton'];

  isOpened = false;

  initialize() {
    this.debouncedHandleResize = debounce(this.handleResize, 300);
  }

  connect() {
    document.addEventListener('click', this.handleOutsideClick);

    window.addEventListener('resize', this.debouncedHandleResize);

    this.toggleButtonTarget.addEventListener(
      'click',
      this.handleToggleButtonClick,
    );

    this.signUpButtonTargets.forEach(el =>
      el.addEventListener('click', this.handleSignUpButtonClick, false),
    );

    this.removeCodeAndStateQueryParams();
  }

  disconnect() {
    document.removeEventListener('click', this.handleOutsideClick);

    window.removeEventListener('resize', this.debouncedHandleResize);

    this.toggleButtonTarget.removeEventListener(
      'click',
      this.handleToggleButtonClick,
    );

    this.signUpButtonTargets.forEach(el =>
      el.removeEventListener('click', this.handleSignUpButtonClick, false),
    );
  }

  handleOutsideClick = e => {
    if (!this.isOpened) {
      return;
    }

    if (!this.element.contains(e.target)) {
      this.toggleMenu();
    }
  };

  handleResize = () => {
    if (!this.isOpened) {
      return;
    }

    if (checkIsMobile()) {
      return;
    }

    this.toggleMenu();
  };

  handleToggleButtonClick = e => {
    e.preventDefault();

    this.toggleMenu();
  };

  toggleMenu = () => {
    this.element.classList.toggle('is-opened', !this.isOpened);
    this.toggleIconTarget.classList.toggle('fa-bars', this.isOpened);
    this.toggleIconTarget.classList.toggle('fa-close', !this.isOpened);

    this.isOpened = !this.isOpened;
  };

  handleSignUpButtonClick = e => {
    e.preventDefault();
    window.location.href = '/sign_up';
  };

  removeCodeAndStateQueryParams = () => {
    const url = new URL(location.href);
    url.searchParams.delete('code');
    url.searchParams.delete('state');
    window.history.replaceState({}, document.title, url.href);
  };
}
