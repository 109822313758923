import { Controller } from 'stimulus';

import { handleError } from '@ftf/lib/alerts';

export default class CopyDocumentsModal extends Controller {
  static targets = ['copy'];

  connect() {
    $('.copy-to-deal-btn').attr('disabled', true);
    this.copyTarget.addEventListener('click', this.showModal, false);
    $('#target-deal').on('change', this.enableSubmitBtn);
  }

  disconnect() {
    this.copyTarget.removeEventListener('click', this.showModal, false);
    $('#target-deal').off('change', this.enableSubmitBtn);
  }

  enableSubmitBtn = () => {
    if ($('#target-deal').val().length !== 0) {
      $('.copy-to-deal-btn').attr('disabled', false);
    } else {
      $('.copy-to-deal-btn').attr('disabled', true);
    }
  };

  showModal() {
    if ($('#selected_documents input:checkbox:checked').length > 0) {
      $('#copy-to-deal-modal').modal('show');
    } else {
      handleError('You must select a document to copy.', false);
    }
  }
}
