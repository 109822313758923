import { Controller } from 'stimulus';

import { trackGAEvent } from '@ftf-old/main/tracking';

export default class DealApplicationFormStep extends Controller {
  static targets = ['backButton', 'submitButton', 'field'];

  validationTimer = undefined;
  isDirty = false;

  connect() {
    $(this.element).validator({ disable: false });

    if (this.hasBackButtonTarget) {
      this.backButtonTarget.addEventListener('click', this.goBack, false);
    }

    this.submitButtonTarget.addEventListener('click', this.handleSubmit, false);

    this.validationTimer = setInterval(this.toggleSubmitButton, 300);
  }

  disconnect() {
    $(this.element).validator('destroy');

    if (this.hasBackButtonTarget) {
      this.backButtonTarget.removeEventListener('click', this.goBack, false);
    }

    this.submitButtonTarget.removeEventListener(
      'click',
      this.handleSubmit,
      false,
    );

    clearInterval(this.validationTimer);
  }

  goBack = () => {
    const { category, action } = this.backButtonTarget.dataset;

    trackGAEvent({ category, action });

    window.dispatchEvent(
      new CustomEvent('DealApplicationForm:goToStep', {
        detail: this.previousStep,
      }),
    );
  };

  goNext = () => {
    window.dispatchEvent(
      new CustomEvent('DealApplicationForm:goToStep', {
        detail: this.nextStep,
      }),
    );
  };

  isValid = () => {
    let validFieldsCount = 0;

    this.fieldTargets.forEach(elem => {
      if (elem.checkValidity() === true) {
        validFieldsCount += 1;
      }
    });

    return validFieldsCount === this.fieldTargets.length;
  };

  toggleSubmitButton = () => {
    this.submitButtonTarget.classList.toggle('disabled', !this.isValid());
  };

  handleSubmit = e => {
    e.preventDefault();

    const { category, action } = this.submitButtonTarget.dataset;

    trackGAEvent({ category, action });

    $(this.element).validator('validate');

    if (!this.isValid()) {
      return;
    }

    this.fieldTargets.forEach(elem => {
      elem.value = elem.value.trim();
    });

    this.goNext();
  };
}
