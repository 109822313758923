import { loadAutoComplete } from '@ftf-old/main/helpers';

import DealApplicationFormStep from '../deal_application_form_step/deal_application_form_step';

export default class FormStepAddress extends DealApplicationFormStep {
  nextStep = 'financials';

  isInitialized = false;

  connect() {
    super.connect();

    this.checkInitialState();

    if (typeof google === 'object') {
      this.addAutocompleteToForm();
      return;
    }

    document.addEventListener(
      'places.autofill.load',
      this.addAutocompleteToForm,
      false,
    );
  }

  disconnect() {
    super.disconnect();

    document.removeEventListener(
      'places.autofill.load',
      this.addAutocompleteToForm,
      false,
    );
  }

  addAutocompleteToForm = () => {
    if (this.isInitialized) {
      return;
    }

    const $form = $(this.element);

    loadAutoComplete($form);

    this.isInitialized = true;
  };

  checkInitialState = () => {
    if (!this.isValid()) {
      return;
    }

    setTimeout(this.goNext, 500);
  };
}
