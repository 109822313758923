import { Controller } from 'stimulus';

export default class BulkSelectButton extends Controller {
  ids = {};

  connect() {
    window.addEventListener('BulkSelectButton:update', this.updateIds);
    window.addEventListener('BulkSelectButton:clear', this.clearIds);
  }

  disconnect() {
    window.removeEventListener('BulkSelectButton:update', this.updateIds);
    window.removeEventListener('BulkSelectButton:clear', this.clearIds);
  }

  updateIds = e => {
    const { ids = {} } = e.detail;

    this.ids = { ...this.ids, ...ids };

    this.updateUI();
  };

  clearIds = () => {
    this.ids = {};

    this.updateUI();
  };

  updateUI = () => {
    const { label } = this.element.dataset;
    const activeIds = Object.keys(this.ids).filter(id => Boolean(this.ids[id]));

    if (activeIds.length > 0) {
      this.element.textContent = `${label} (${activeIds.length})`;
      this.element.disabled = false;
      this.element.setAttribute('data-ids', activeIds);

      return;
    }

    this.element.textContent = label;
    this.element.disabled = true;
    this.element.removeAttribute('data-ids');
  };
}
