export const onDomIsRendered = domString =>
  new Promise(resolve => {
    const waitUntil = () => {
      setTimeout(() => {
        if ($(domString).length > 0) {
          resolve($(domString));
        } else {
          waitUntil();
        }
      }, 100);
    };
    // start the loop
    waitUntil();
  });

export const getTextFromTree = ({ props: { children } }) => {
  if (typeof children === 'string') return children;
  return getTextFromTree(children);
};

export const isScriptLoaded = url => {
  const scripts = document.getElementsByTagName('script');
  let i;
  for (i = scripts.length - 1; i >= 0; i -= 1) {
    if (scripts[i].src === url) return true;
  }
  return false;
};

export function fragmentFromString(strHTML) {
  const temp = document.createElement('template');
  temp.innerHTML = strHTML;
  return temp.content;
}
