import { Controller } from 'stimulus';

export default class BuyBoxNewBuyerModal extends Controller {
  static targets = [
    'institutionNameField',
    'invalidInstitutionError',
    'saveButton',
  ];

  validateNewInstitutionUniqueness() {
    const cleanNameVal = this.squishName(this.institutionNameFieldTarget.value);
    const institutionsArray = JSON.parse(
      this.institutionNameFieldTarget.getAttribute('data-attr'),
    );
    const cleanInstitutions = institutionsArray.map(inst =>
      this.squishName(inst),
    );

    if (cleanInstitutions.includes(cleanNameVal)) {
      this.invalidInstitutionErrorTarget.classList.remove('d-none');
      this.saveButtonTarget.disabled = true;
    } else {
      this.invalidInstitutionErrorTarget.classList.add('d-none');
      this.saveButtonTarget.disabled = false;
    }
  }

  showNewBuyerModal = () => {
    $('#buyBoxNewBuyerModal').modal('show');
  };

  squishName = str => {
    return str
      .replace(/\s+/g, ' ')
      .trim()
      .toLowerCase();
  };
}
