/**
 * And by "isMobile", we mean is less than 768px wide.
 */

let isMobile = false;

function checkIsMobile() {
  const nextIsMobile = window.innerWidth < 768;
  if (isMobile && !nextIsMobile) {
    isMobile = false;
  } else if (!isMobile && nextIsMobile) {
    isMobile = true;
  }
  return isMobile;
}

export default checkIsMobile;
