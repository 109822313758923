/*  eslint no-mixed-operators: ["error", {"allowSamePrecedence": true}] */
/* eslint-disable react/no-array-index-key */

import { Controller } from 'stimulus';
import React from 'react';
import ReactDOM from 'react-dom';
import {
  HeadingTitle,
  ExtensionTrackerPage,
} from '@fundthatflip/loan-extensions-ui-library';
import Cookies from 'js-cookie';

export default class Tracker extends Controller {
  connect() {
    // add event listener to the element
    this.render();
  }

  disconnect() {
    //was previously a console.log, was removed for linting reasons
  }

  render() {
    const ExtensionTrackerIndex = () => {
      return (
        <div style={{ padding: '20px' }} className="ftf-extensions-ui-lib">
          <HeadingTitle title="Extensions Page" />
          <ExtensionTrackerPage token={Cookies.get('local.accessToken')} />
        </div>
      );
    };
    ReactDOM.render(
      <ExtensionTrackerIndex />,
      document.getElementById('extension-tracker-page'),
    );
  }
}
