import { Controller } from 'stimulus';
import { isNil } from 'lodash';

export default class Tooltip extends Controller {
  connect() {
    const {
      container,
      placement,
      title,
      trigger,
      delay,
    } = this.element.dataset;

    const options = {
      container: isNil(container) ? 'body' : container,
      html: true,
      placement: isNil(placement) ? 'auto top' : placement,
      title,
      trigger: isNil(trigger) ? 'hover' : trigger,
      delay: delay ? JSON.parse(delay) : null,
    };

    $(this.element).tooltip(options);
  }

  disconnect() {
    $(this.element).tooltip('destroy');
  }
}
