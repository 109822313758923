import { Controller } from 'stimulus';
import { debounce } from 'lodash';

import checkIsMobile from '@ftf-old/util/checkIsMobile';

export default class ImagesSlider extends Controller {
  static targets = [
    'nextButton',
    'prevButton',
    'imagesContainer',
    'imageContainer',
    'image',
    'expandCloseButton',
    'expandNextButton',
    'expandPrevButton',
    'sliderContainer',
  ];

  imagesTotal = 0;

  imageWidth = 0;

  imagesPerViewPort = 0;

  imageOffest = 0;

  activeImagesIndex = 0;

  gap = 10;

  activeImage = null;

  initialize() {
    this.debouncedHandleResize = debounce(this.handleResize, 300);
  }

  connect() {
    this.nextButtonTarget.addEventListener('click', this.showNextImage);
    this.prevButtonTarget.addEventListener('click', this.showPrevImage);
    this.imageTargets.forEach(el => {
      el.addEventListener('click', this.expandImage, false);
    });
    window.addEventListener('resize', this.debouncedHandleResize);

    this.getSliderpPositioningData();

    this.lazyLoadImages(0);

    this.imagesContainerTarget.style.transform = `translateX(-${this.imageOffest}px)`;
  }

  disconnect() {
    this.nextButtonTarget.removeEventListener('click', this.showNextImage);
    this.prevButtonTarget.removeEventListener('click', this.showPrevImage);
    this.imageTargets.forEach(el => {
      el.removeEventListener('click', this.expandImage);
    });

    window.removeEventListener('resize', this.debouncedHandleResize);
  }

  getSliderpPositioningData = () => {
    this.imagesTotal = this.imagesContainerTarget.childElementCount;
    this.imageWidth = this.imageContainerTarget.offsetWidth;

    this.imagesPerViewPort = Math.round(
      this.imagesContainerTarget.offsetWidth / this.imageWidth,
    );

    this.imageOffest = ((this.imagesPerViewPort - 1) * this.gap) / 2;
  };

  lazyLoadImages = (startIndex, distance = this.imagesPerViewPort + 1) => {
    for (let i = startIndex; i < startIndex + distance; i++) {
      const img = this.imageTargets[i];

      if (i < this.imageTargets.length && !img.src) img.src = img.dataset.src;
    }
  };

  showImage = index => {
    this.lazyLoadImages(index);

    const targetPosition =
      (this.imageWidth + this.gap) * index +
      ((this.imagesPerViewPort - 1) * this.gap) / 2;

    this.imagesContainerTarget.style.transform = `translateX(-${targetPosition}px)`;
    this.activeImagesIndex = index;
    if (index === 0) {
      this.toggleNextPrevBtnVisibility('off', false, true);
      return;
    }
    if (index === this.imagesTotal - this.imagesPerViewPort) {
      this.toggleNextPrevBtnVisibility('off', true, false);
      return;
    }
    this.toggleNextPrevBtnVisibility('on');
  };

  showNextImage = e => {
    e.preventDefault();
    const isInRange =
      this.activeImagesIndex < this.imagesTotal - this.imagesPerViewPort;
    if (isInRange) this.showImage(this.activeImagesIndex + 1);
  };

  showPrevImage = e => {
    e.preventDefault();
    const isInRange = this.activeImagesIndex > 0;
    if (isInRange) this.showImage(this.activeImagesIndex - 1);
  };

  toggleNextPrevBtnVisibility = (
    toggle = 'toggle',
    next = true,
    prev = true,
  ) => {
    if (toggle === 'toggle') {
      if (next)
        this.nextButtonTarget.classList.toggle('image-slider__button-hidden');
      if (prev)
        this.prevButtonTarget.classList.toggle('image-slider__button-hidden');
    }

    if (toggle === 'off') {
      if (next)
        this.nextButtonTarget.classList.add('image-slider__button-hidden');
      if (prev)
        this.prevButtonTarget.classList.add('image-slider__button-hidden');
    }

    if (toggle === 'on') {
      if (next)
        this.nextButtonTarget.classList.remove('image-slider__button-hidden');
      if (prev)
        this.prevButtonTarget.classList.remove('image-slider__button-hidden');
    }
  };

  handleResize = () => {
    if (this.activeImage) {
      this.activeImage.remove();
      this.activeImage = null;
      this.toggleNextPrevBtnVisibility(
        'on',
        this.activeImagesIndex === this.imagesTotal - this.imagesPerViewPort
          ? false
          : true,
        this.activeImagesIndex === 0 ? false : true,
      );
    }
    this.getSliderpPositioningData();
    this.showImage(this.activeImagesIndex);
  };

  cleanupExpandedImage = expandedImage => {
    const imgSrc = expandedImage.querySelector('img').src;
    expandedImage.remove();
    this.activeImage = false;
    this.toggleNextPrevBtnVisibility(
      'on',
      this.activeImagesIndex === this.imagesTotal - this.imagesPerViewPort
        ? false
        : true,
      this.activeImagesIndex === 0 ? false : true,
    );

    return imgSrc;
  };

  addCloseButton = clonedImage => {
    const closeBtn = this.expandCloseButtonTarget.cloneNode(true);
    closeBtn.style.display = 'flex';

    closeBtn.addEventListener('click', () => {
      clonedImage.addEventListener(
        'transitionend',
        () => this.cleanupExpandedImage(clonedImage),
        false,
      );

      clonedImage.style.transform = `scale(100%)`;
    });

    clonedImage.append(closeBtn);
  };

  slideAndExpand = (e, direction, imgSrc) => {
    if (direction === 'next') this.showNextImage(e);
    else this.showPrevImage(e);

    let nextImage = null;
    this.imageTargets.forEach((img, i) => {
      if (img.src === imgSrc)
        nextImage =
          direction === 'next'
            ? this.imageTargets[i + 1]
            : this.imageTargets[i - 1];
    });
    if (nextImage)
      setTimeout(
        () => this.expandImage(e, nextImage.parentNode.parentNode),
        100,
      );
  };

  addNextButton = clonedImage => {
    const nextBtn = this.expandNextButtonTarget.cloneNode(true);
    nextBtn.style.display = 'block';

    nextBtn.addEventListener('click', e => {
      clonedImage.addEventListener(
        'transitionend',
        () => {
          const currImageSrc = this.cleanupExpandedImage(clonedImage);
          this.slideAndExpand(e, 'next', currImageSrc);
        },
        false,
      );

      clonedImage.style.transform = `scale(100%)`;
    });

    clonedImage.append(nextBtn);
  };

  addPrevButton = clonedImage => {
    const prevBtn = this.expandPrevButtonTarget.cloneNode(true);
    prevBtn.style.display = 'block';

    prevBtn.addEventListener('click', e => {
      clonedImage.addEventListener(
        'transitionend',
        () => {
          const currImageSrc = this.cleanupExpandedImage(clonedImage);
          this.slideAndExpand(e, 'prev', currImageSrc);
        },
        false,
      );

      clonedImage.style.transform = `scale(100%)`;
    });

    clonedImage.append(prevBtn);
  };

  getViewPortIndexAndLeftOffset = clickedImage => {
    for (let i = 0; i < this.imageContainerTargets.length; i++) {
      const img = this.imageContainerTargets[i];

      if (img === clickedImage) {
        const viewPortIndex = i - this.activeImagesIndex;

        return {
          left: (img.offsetWidth + this.gap) * viewPortIndex - this.imageOffest,
          viewPortIndex: viewPortIndex,
        };
      }
    }
    return undefined;
  };

  expandImage = (e, imageToExpand = null) => {
    if (this.activeImage || checkIsMobile()) return;

    const image = imageToExpand || e.target.closest('.image-slider__image');
    const { left, viewPortIndex } = this.getViewPortIndexAndLeftOffset(image);

    const clonedImage = image.cloneNode(true);

    this.activeImage = clonedImage;

    this.sliderContainerTarget.appendChild(clonedImage);

    clonedImage.classList.add('image-slider__image-expanded');
    clonedImage.classList.remove('image-slider__image-hover');

    clonedImage.style.left = `${left}px`;
    clonedImage.style.width = `${this.imageWidth}px`;

    clonedImage.querySelector(
      'img',
    ).style.height = `${this.imageContainerTarget.offsetHeight}px`;

    const xMult = Math.floor(this.imagesPerViewPort / 2) - viewPortIndex;
    clonedImage.style.transform = `translate(${xMult *
      (this.imageWidth + this.gap)}px, -40px) scale(250%)`;

    clonedImage.scrollIntoView({ behavior: 'smooth', block: 'center' });

    this.toggleNextPrevBtnVisibility('off');
    this.addCloseButton(clonedImage);
    this.addNextButton(clonedImage);
    this.addPrevButton(clonedImage);
  };
}
