import { Controller } from 'stimulus';

import { EXP_DATE_LABELS } from '@ftf/lib/documentLabels';

export default class DocumentEditModal extends Controller {
  static targets = [
    'labelField',
    'assetStatementFields',
    'achFormFields',
    'expirationDateField',
  ];

  connect() {
    this.labelFieldTarget.addEventListener(
      'change',
      this.handleLabelChange,
      false,
    );

    if (this.labelFieldTarget.value === 'asset_documentation') {
      this.assetStatementFieldsTarget.classList.remove('d-none');
    }

    if (this.labelFieldTarget.value === 'ach_form') {
      this.achFormFieldsTarget.classList.remove('d-none');
    }

    if (EXP_DATE_LABELS.includes(this.labelFieldTarget.value)) {
      this.expirationDateFieldTarget.classList.remove('d-none');
    }
  }

  handleLabelChange = e => {
    if (e.currentTarget.value === 'asset_documentation') {
      this.assetStatementFieldsTarget.classList.remove('d-none');
    } else if (
      this.assetStatementFieldsTarget.classList.contains('d-none') === false
    ) {
      this.assetStatementFieldsTarget.classList.add('d-none');
    }

    if (e.currentTarget.value === 'ach_form') {
      this.achFormFieldsTarget.classList.remove('d-none');
    } else if (
      this.achFormFieldsTarget.classList.contains('d-none') === false
    ) {
      this.achFormFieldsTarget.classList.add('d-none');
    }

    if (EXP_DATE_LABELS.includes(e.currentTarget.value)) {
      this.expirationDateFieldTarget.classList.remove('d-none');
    } else {
      this.expirationDateFieldTarget.classList.add('d-none');
    }
  };

  handleAchCheckbox(e) {
    if (e.target.checked === true) {
      e.target.value = true;
    } else {
      e.target.value = false;
    }
  }
}
