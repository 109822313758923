import { Controller } from 'stimulus';

import { isValidPhone } from '@ftf-old/util/helpers';
import { handleError } from '@ftf/lib/alerts';

export default class OauthAccountOptions extends Controller {
  static targets = [
    'form',
    'lenderStatus',
    'borrowerStatus',
    'brokerStatus',
    'accountOptionsErrorControl',
    'accreditationStatus',
    'incomeStatus',
    'netWorthStatus',
    'licenseStatus',
    'entityStatus',
    'nonAccreditedStatus',
    'accreditationStatusWarningControl',
    'accreditationStatusErrorControl',
    'phone',
    'phoneNumberErrorControl',
    'submitButton',
  ];

  connect() {
    $(this.phoneTarget).bfhphone(this.phoneTarget.dataset);
    this.lenderStatusTarget.addEventListener(
      'change',
      this.handleLenderStatusChange,
      false,
    );
    this.borrowerStatusTarget.addEventListener(
      'change',
      this.handleBorrowerStatusChange,
      false,
    );
    this.brokerStatusTarget.addEventListener(
      'change',
      this.handleBrokerStatusChange,
      false,
    );
    const handleNetWorthStatusChange = this.handleIncomeStatusChange;
    this.incomeStatusTarget.addEventListener(
      'change',
      this.handleIncomeStatusChange,
      false,
    );
    this.netWorthStatusTarget.addEventListener(
      'change',
      handleNetWorthStatusChange,
      false,
    );
    this.licenseStatusTarget.addEventListener(
      'change',
      this.handleLicenseStatusChange,
      false,
    );
    this.entityStatusTarget.addEventListener(
      'change',
      this.handleEntityStatusChange,
      false,
    );
    this.nonAccreditedStatusTarget.addEventListener(
      'change',
      this.handleNonAccreditedStatusChange,
      false,
    );
    this.submitButtonTarget.addEventListener('click', this.submitForm, false);
  }

  disconnect() {
    $(this.phoneTarget).removeData('bfhphone');
    this.lenderStatusTarget.removeEventListener(
      'change',
      this.handleLenderStatusChange,
      false,
    );
    this.borrowerStatusTarget.removeEventListener(
      'change',
      this.handleBorrowerStatusChange,
      false,
    );
    this.brokerStatusTarget.removeEventListener(
      'change',
      this.handleBrokerStatusChange,
      false,
    );
    this.incomeStatusTarget.removeEventListener(
      'change',
      this.handleIncomeStatusChange,
      false,
    );
    this.netWorthStatusTarget.removeEventListener(
      'change',
      this.handleNetWorthStatusChange,
      false,
    );
    this.licenseStatusTarget.removeEventListener(
      'change',
      this.handleLicenseStatusChange,
      false,
    );
    this.entityStatusTarget.removeEventListener(
      'change',
      this.handleEntityStatusChange,
      false,
    );
    this.nonAccreditedStatusTarget.removeEventListener(
      'change',
      this.handleNonAccreditedStatusChange,
      false,
    );
    this.submitButtonTarget.removeEventListener(
      'click',
      this.submitForm,
      false,
    );
  }

  accountOptionsErrorDisplay = () => {
    this.accountOptionsErrorControlTarget.classList.remove('hidden');
  };

  accountOptionsErrorHide = () => {
    this.accountOptionsErrorControlTarget.classList.add('hidden');
  };

  accreditationStatusErrorDisplay = () => {
    this.accreditationStatusErrorControlTarget.classList.remove('hidden');
  };

  accreditationStatusErrorHide = () => {
    this.accreditationStatusErrorControlTarget.classList.add('hidden');
  };

  handleAccreditationStatusWarningHide = () => {
    this.accreditationStatusWarningControlTarget.classList.add('hidden');
  };

  handleLenderStatusChange = () => {
    this.incomeStatusTarget.checked = false;
    this.netWorthStatusTarget.checked = false;
    this.licenseStatusTarget.checked = false;
    this.entityStatusTarget.checked = false;
    this.nonAccreditedStatusTarget.checked = false;
    this.accreditationStatusTarget.classList.toggle('hidden');
    this.accountOptionsErrorHide();
    this.handleAccreditationStatusWarningHide();
  };

  handleBorrowerStatusChange = () => {
    this.accountOptionsErrorHide();
  };

  handleBrokerStatusChange = () => {
    this.accountOptionsErrorHide();
  };

  handleIncomeStatusChange = () => {
    this.nonAccreditedStatusTarget.checked = false;
    this.accreditationStatusErrorHide();
    this.handleAccreditationStatusWarningHide();
  };

  handleLicenseStatusChange = () => {
    this.nonAccreditedStatusTarget.checked = false;
    this.accreditationStatusErrorHide();
    this.handleAccreditationStatusWarningHide();
  };

  handleEntityStatusChange = () => {
    this.nonAccreditedStatusTarget.checked = false;
    this.accreditationStatusErrorHide();
    this.handleAccreditationStatusWarningHide();
  };

  handleNonAccreditedStatusChange = () => {
    this.incomeStatusTarget.checked = false;
    this.netWorthStatusTarget.checked = false;
    this.licenseStatusTarget.checked = false;
    this.entityStatusTarget.checked = false;
    this.accreditationStatusErrorHide();
    this.accreditationStatusWarningControlTarget.classList.toggle('hidden');
  };

  handleSelfVerifiedStatus = () => {
    if (
      this.incomeStatusTarget.checked ||
      this.netWorthStatusTarget.checked ||
      this.licenseStatusTarget.checked ||
      this.entityStatusTarget.checked
    ) {
      return true;
    }
    return false;
  };

  handleErrors = () => {
    if (
      !this.lenderStatusTarget.checked &&
      !this.borrowerStatusTarget.checked &&
      !this.brokerStatusTarget.checked
    ) {
      this.accountOptionsErrorDisplay();
      if (!isValidPhone(this.phoneTarget.value)) {
        this.phoneNumberErrorControlTarget.classList.remove('hidden');
      }
    } else if (
      this.lenderStatusTarget.checked &&
      !this.incomeStatusTarget.checked &&
      !this.netWorthStatusTarget.checked &&
      !this.licenseStatusTarget.checked &&
      !this.entityStatusTarget.checked &&
      !this.nonAccreditedStatusTarget.checked
    ) {
      this.accreditationStatusErrorDisplay();
      if (!isValidPhone(this.phoneTarget.value)) {
        this.phoneNumberErrorControlTarget.classList.remove('hidden');
      }
    } else if (!isValidPhone(this.phoneTarget.value)) {
      this.phoneNumberErrorControlTarget.classList.remove('hidden');
    }
  };

  submitForm = e => {
    e.preventDefault();
    const userId = this.formTarget.dataset.userid;
    const user = {
      lender_boolean: this.lenderStatusTarget.checked,
      borrower_boolean: this.borrowerStatusTarget.checked,
      broker: this.brokerStatusTarget.checked,
      self_verification: this.handleSelfVerifiedStatus(),
      phone_number: this.phoneTarget.value,
    };
    const data = { user };
    let errors = false;

    if (
      !this.lenderStatusTarget.checked &&
      !this.borrowerStatusTarget.checked &&
      !this.brokerStatusTarget.checked
    ) {
      this.accountOptionsErrorDisplay();
      errors = true;
    }

    if (
      this.lenderStatusTarget.checked &&
      !this.incomeStatusTarget.checked &&
      !this.netWorthStatusTarget.checked &&
      !this.licenseStatusTarget.checked &&
      !this.entityStatusTarget.checked &&
      !this.nonAccreditedStatusTarget.checked
    ) {
      this.accreditationStatusErrorDisplay();
      errors = true;
    }

    if (!isValidPhone(this.phoneTarget.value)) {
      this.phoneNumberErrorControlTarget.classList.remove('hidden');
      errors = true;
    }

    if (!errors) {
      fetch(`/users/${userId}`, {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': (
            document.getElementsByTagName('meta')['csrf-token'] || {}
          ).content,
          'Content-Type': 'application/json',
        },
      }).then(response => {
        if (response.ok) {
          window.location.href = '/';
        } else {
          handleError('Something went wrong. Please try again.', false);
        }
      });
    }
  };
}
