import { Controller } from 'stimulus';
import { debounce } from 'lodash';

export default class AutoSubmitForm extends Controller {
  initialize() {
    this.debouncedHandleFormInput = debounce(this.submitForm, 500);
  }

  connect() {
    this.element.addEventListener('input', this.checkFormSubmission, false);
    this.element.addEventListener(
      'quicksearch:selected',
      this.submitForm,
      false,
    );
  }

  disconnect() {
    this.element.removeEventListener(
      'input',
      this.debouncedHandleFormInput,
      false,
    );
    this.element.addEventListener(
      'quicksearch:selected',
      this.submitForm,
      false,
    );
  }

  checkFormSubmission = e => {
    if (e.target.hasAttribute('data-form-submit')) {
      return;
    } else {
      this.debouncedHandleFormInput();
    }
  };

  submitForm = () => $(this.element).submit();
}
