import { Controller } from 'stimulus';

export default class NoteSearch extends Controller {
  static targets = ['results', 'form', 'submit', 'search', 'clear'];

  connect() {
    if (window.location.href.includes('search=')) {
      this.clearTarget.disabled = false;
      this.searchTarget.value = window.location.href.substring(
        window.location.href.indexOf('=') + 1,
        window.location.href.lastIndexOf('&'),
      );
    }

    this.clearTarget.addEventListener('click', this.resetSearch, false);
  }

  disconnect() {
    this.clearTarget.removeEventListener('click', this.resetSearch, false);
  }

  search() {
    this.formTarget.dispatchEvent(new Event('submit', { bubbles: true }));
  }

  resetSearch = e => {
    e.preventDefault();
    window.location.href = `${window.location.origin}${window.location.pathname}`.split(
      '?',
    )[0];
  };

  handleSubmit = e => {
    e.preventDefault();

    setTimeout(() => (this.submitTarget.disabled = false), 200);

    return false;
  };
}
