import { Controller } from 'stimulus';
import flatpickr from 'flatpickr';

export default class InvoiceForm extends Controller {
  static targets = ['datePicker'];

  connect() {
    this.datePickerTargets.forEach(dateInput => {
      flatpickr(dateInput, { minDate: '2000-01', allowInput: true });
    });
  }

  disconnect() {
    this.flatpickrInstances.forEach(instance => {
      instance.destroy();
    });
  }
}
