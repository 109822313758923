import { Controller } from 'stimulus';
import { find } from 'lodash';

export default class AppraisalOrderForm extends Controller {
  static targets = [
    'target',
    'template',
    'appraisalTypeControl',
    'appraisalTypeDisplayControl',
    'timingInput',
    'inspectionControl',
    'photoTypeControl',
    'appraiserNameDisplay',
    'appraiserNameInput',
    'vendorSelect',
    'appraisalFieldInput',
  ];

  allPhotoOptions = [];
  // HACK: collects attrs of elements that have been removed
  attrsToIgnore = [];

  add(e) {
    e.preventDefault();

    const content = this.templateTarget.innerHTML.replace(
      /NEW_RECORD/g,
      new Date().getTime().toString(),
    );
    this.targetTarget.insertAdjacentHTML('afterbegin', content);
  }

  remove(e) {
    e.preventDefault();
    const wrapper = e.target.closest('.nested-form-wrapper');

    if (wrapper.dataset.newRecord === 'true') {
      wrapper.remove();
    } else {
      wrapper.style.display = 'none';
      const input = wrapper.querySelector("input[name*='_destroy']");
      input.value = '1';
      this.attrsToIgnore.push(e.target.dataset.attr);
    }
    this.checkPhotoTypeOptions();
  }

  initialize() {
    this.allPhotoOptions = [...this.photoTypeControlTarget.options];
    this.checkAppraisalDetailDisplay();
    this.determineAppraiserDisplay();
    this.checkPhotoTypeOptions();
  }

  checkAppraisalDetailDisplay() {
    this.appraisalTypeControlTargets.forEach(el => {
      this.toggleFieldsControl(el.dataset.attr, el.value === '');
    });
  }

  handleAppraisalTypeControlChange = e => {
    if (e.target.value === '') {
      this.clearFieldValues(e.target.dataset.attr);
    }
    this.toggleFieldsControl(e.target.dataset.attr, e.target.value === '');
    this.determineAppraiserDisplay();
    this.checkPhotoTypeOptions();
  };

  determineAppraiserDisplay() {
    this.vendorSelectTargets.forEach(el => {
      this.toggleAppraiserName(
        el.dataset.attr,
        el.value !== 'direct_appraiser',
        el.value,
      );
    });
  }

  checkPhotoTypeOptions() {
    this.resetPhotoTypeOptions();

    const timingInputsToConsider =
      this.attrsToIgnore.length === 0
        ? this.timingInputTargets
        : this.timingInputTargets.filter(
            el => !this.attrsToIgnore.includes(el.dataset.attr),
          );

    const allPreClose = timingInputsToConsider.every(
      type => type.value === 'pre_close' || type.value === '',
    );
    const allPostClose = timingInputsToConsider.every(
      type => type.value === 'post_close' || type.value === '',
    );
    const onlyPreAndPostClose = timingInputsToConsider.every(
      type =>
        type.value === 'pre_close' ||
        type.value === 'post_close' ||
        type.value === '',
    );
    const noneSelected = timingInputsToConsider.every(
      type => type.value === '',
    );

    if ((onlyPreAndPostClose && !allPostClose && !allPreClose) || noneSelected)
      return;

    if (allPreClose) {
      this.allPhotoOptions.forEach(option => {
        if (!(option.value === '' || option.value === 'appraisal_photos')) {
          const idxToRemove = Array.from(
            this.photoTypeControlTarget.options,
          ).findIndex(el => el.value === option.value);
          this.photoTypeControlTarget.options.remove(idxToRemove);
        }
      });
    } else if (allPostClose) {
      this.allPhotoOptions.forEach(option => {
        if (option.value === 'appraisal_photos') {
          const idxToRemove = Array.from(
            this.photoTypeControlTarget.options,
          ).findIndex(el => el.value === option.value);
          this.photoTypeControlTarget.options.remove(idxToRemove);
        }
      });
    }
  }

  resetPhotoTypeOptions() {
    this.allPhotoOptions.forEach((option, idx) => {
      const idxOfOption = Array.from(
        this.photoTypeControlTarget.options,
      ).findIndex(el => el.value === option.value);
      if (idxOfOption === -1)
        this.photoTypeControlTarget.options.add(option, idx);
    });
  }

  handleVendorSelectChange = e => {
    this.toggleAppraiserName(
      e.target.dataset.attr,
      e.target.value !== 'direct_appraiser',
      e.target.value,
    );
  };

  toggleAppraiserName = (attr, isHidden, vendorValue) => {
    this.appraiserNameDisplayTargets.forEach(el => {
      if (el.dataset.attr !== attr) return;
      el.classList.toggle('d-none', isHidden);
      this.toggleAppraiserRequired(attr, vendorValue);
    });
  };

  toggleAppraiserRequired = (attr, vendorValue) => {
    const appraiserTarget = find(
      this.appraiserNameInputTargets,
      el => el.dataset.attr === attr,
    );
    if (vendorValue === 'direct_appraiser') {
      appraiserTarget.setAttribute('required', true);
    } else if (vendorValue !== 'direct_appraiser') {
      appraiserTarget.removeAttribute('required');
    }
  };

  toggleFieldsControl = (attr, isHidden) => {
    this.appraisalTypeDisplayControlTargets.forEach(el => {
      if (el.dataset.attr !== attr) return;
      el.classList.toggle('d-none', isHidden);
    });
  };

  clearFieldValues = attr => {
    this.appraisalFieldInputTargets.forEach(el => {
      if (el.dataset.attr !== attr) return;
      el.value = '';
    });
  };

  isRequiredFieldsFilled = () => {
    return !!(
      this.photoTypeControlTarget.value && this.inspectionControlTarget.value
    );
  };

  validateForm = e => {
    let shouldBeRequired = false;
    this.timingInputTargets.forEach(el => {
      if (el.value === 'pre_close' || el.value === 'post_close') {
        shouldBeRequired = true;
      }
    });
    this.photoTypeControlTarget.required = shouldBeRequired;
    this.inspectionControlTarget.required = shouldBeRequired;
    if (shouldBeRequired && !this.isRequiredFieldsFilled) {
      e.preventDefault();
      const appraisalForm = document.getElementsByClassName(
        'appraisal-order-form',
      );
      appraisalForm[0].reportValidity();
    }
  };
}
