import { Controller } from 'stimulus';

import { handleAJAXError, handleInfo } from '@ftf/lib/alerts';

export default class InspectionUpdateButton extends Controller {
  connect() {
    $(this.element).on({
      'ajax:success': this.handleAJAXSuccess,
      'ajax:error': handleAJAXError,
    });
  }

  disconnect() {
    $(this.element).on({
      'ajax:success': this.handleAJAXSuccess,
      'ajax:error': handleAJAXError,
    });
  }

  handleAJAXSuccess = () => {
    handleInfo('Draw update has been posted successfully!', {
      autoDismissible: true,
    });

    setTimeout(() => {
      $(this.element).replaceWith(this.element.dataset.replaceWith);
    }, 0);
  };
}
