import { Controller } from 'stimulus';

export default class InvestmentStatusMessage extends Controller {
  connect() {
    window.addEventListener('InvestNow:onStatusUpdate', this.update);
  }

  disconnect() {
    window.removeEventListener('InvestNow:onStatusUpdate', this.update);
  }

  update = ({ detail: status }) => {
    this.element.innerText = `Status: ${status}`;
  };
}
