import { Controller } from 'stimulus';

import axios from '@ftf/lib/axios';
import { stateTagsInit, validators } from '@ftf-old/main/helpers';

export default class DashboardProfileEditModal extends Controller {
  $modal = undefined;
  $form = undefined;
  $phone = undefined;
  $countries = undefined;

  connect() {
    $(this.element).on('ajax:success', this.handleRequestModalSuccess);
  }

  disconnect() {
    $(this.element).off('ajax:success', this.handleRequestModalSuccess);
  }

  handleRequestModalSuccess = (e, data) => {
    this.$modal = $(data.content).appendTo('body');

    this.$modal
      .on('shown.bs.modal', this.handleModalShown)
      .on('hidden.bs.modal', this.handleModalHidden);

    this.$modal.modal('show');
  };

  handleModalShown = e => {
    this.$form = $(e.target).find('form');

    this.$form.validator({
      custom: {
        phone: validators.validations.phone,
        states: validators.validations.states,
      },
      errors: {
        phone: validators.errors.phone,
        states: validators.errors.states,
      },
    });

    this.$countries = this.$form.find('.bfh-countries');
    this.$countries.bfhcountries({ country: 'US' });

    this.$phone = this.$form.find('.bfh-phone');
    this.$phone.bfhphone(this.$phone.data());

    this.$states = this.$form.find('.user_state_list');

    if (this.$states.length > 0) {
      stateTagsInit(this.$states[0]);
    }

    this.$form
      .on('awesomplete-selectcomplete', this.validateForm)
      .on(
        'change',
        '[data-behavior=other-platforms-boolean-change]',
        this.toggleOtherPlatformsField,
      )
      .on('ajax:success', this.handleFormSubmitSuccess);
  };

  handleModalHidden = () => {
    this.$form.validator('destroy');

    this.$phone.find('.bfh-phone').removeData('bfhphone');

    this.$countries.find('.bfh-countries').removeData('bfhcountries');

    this.$form
      .off('awesomplete-selectcomplete', this.validateForm)
      .off(
        'change',
        '[data-behavior=other-platforms-boolean-change]',
        this.toggleOtherPlatformsField,
      )
      .off('ajax:success', this.handleFormSubmitSuccess);

    this.$modal.remove();
  };

  handleFormSubmitSuccess = async (e, data) => {
    const { profileUrl } = this.element.dataset;

    if (profileUrl) {
      await axios.get(profileUrl);
    }

    window.dispatchEvent(
      new CustomEvent('DashboardProfile:updated', { detail: data }),
    );

    this.$modal.modal('hide');
  };

  toggleOtherPlatformsField = e => {
    $('#user_profile_attributes_other_platforms').toggle('slide', () => {
      if ($(e.target).val() !== 'no') {
        return;
      }

      $('#user_profile_attributes_other_platforms').val('');
    });
  };

  validateForm = () => {
    this.$form.validator('validate');
  };
}
