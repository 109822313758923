import { Controller } from 'stimulus';

export default class ForbearanceModificationForm extends Controller {
  static targets = ['firstOfMonthError', 'saveButton'];

  handleDateChange = e => {
    const submittedDate = e.target.value + 'T00:00:00';
    const parsedDate = new Date(submittedDate);
    const dayOfMonth = parsedDate.getDate();

    const error = this.firstOfMonthErrorTarget;
    const save = this.saveButtonTarget;

    if (dayOfMonth !== 1) {
      error.classList.remove('d-none');
      save.classList.add('disabled');
    } else {
      error.classList.add('d-none');
      save.classList.remove('disabled');
    }
  };
}
