import { Controller } from 'stimulus';

export default class PodDealsFilters extends Controller {
  static targets = ['userField'];

  connect() {
    $.fn.selectpicker.Constructor.BootstrapVersion = '4';

    $(this.userFieldTarget).selectpicker({
      liveSearch: true,
      liveSearchPlaceholder: 'Search',
      style: 'btn-sm outline-none border',
    });

    this.userFieldTarget.addEventListener(
      'change',
      this.handleSearchChange,
      false,
    );

    const searchBox = document.querySelector('.bs-searchbox').children[0];
    searchBox.setAttribute('data-form-submit', false);
  }

  handleSearchChange = e => {
    const path = window.location.pathname;
    const userId = e.target.value;
    const query = window.location.search;
    const search = `?per_page=50&group_id=&role=&status=&user_id=${userId}`;

    if (query) {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('user_id', userId);
      window.location = `${path}?${urlParams.toString()}`;
    } else {
      window.location = `${path}${search}`;
    }
  };
}
