import { Controller } from 'stimulus';
import { isNil } from 'lodash';

import { handleAJAXError } from '@ftf/lib/alerts';
import { formatSecondsToTime } from '@ftf/lib/formatters';

const SECONDS_UNTIL_WARNING = 5 * 60; // 5 minutes

export default class DealLockCountdownTimer extends Controller {
  static targets = ['remainingTime', 'prolongateLockBtn', 'card', 'overlay'];

  lockExpiresAt = undefined;
  timer = undefined;

  connect() {
    this.lockExpiresAt = Date.parse(this.element.dataset.lockExpiresAt);

    $(this.prolongateLockBtnTarget).on({
      'ajax:success': this.handleProlongateLockSuccess,
      'ajax:error': handleAJAXError,
    });

    this.startTimer();
  }

  disconnect() {
    $(this.prolongateLockBtnTarget).off({
      'ajax:success': this.handleProlongateLockSuccess,
      'ajax:error': handleAJAXError,
    });

    this.clearTimer();
  }

  show = kind => {
    this.element.classList.remove('d-none');
    this.overlayTarget.classList.toggle('d-none', kind !== 'expired');

    this.cardTargets.forEach(el => {
      el.classList.toggle('d-none', el.dataset.kind !== kind);
    });
  };

  hide = () => {
    this.element.classList.add('d-none');
    this.overlayTarget.classList.add('d-none');
  };

  startTimer = () => {
    this.timer = setInterval(() => {
      const diff = this.lockExpiresAt - new Date().getTime();
      const seconds = Math.floor(diff / 1000);

      if (seconds <= 0) {
        this.clearTimer();
        this.show('expired');

        return;
      }

      if (seconds < SECONDS_UNTIL_WARNING) {
        this.remainingTimeTarget.innerText = formatSecondsToTime(seconds);
        this.show('expire-soon');

        return;
      }

      this.hide();
    }, 1000);
  };

  clearTimer = () => {
    if (isNil(this.timer)) return;

    clearInterval(this.timer);
  };

  handleProlongateLockSuccess = (e, data) => {
    this.lockExpiresAt = Date.parse(data.lock.expires_at);

    this.clearTimer();
    this.startTimer();
  };
}
