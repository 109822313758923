import { Controller } from 'stimulus';

export default class Dropdown extends Controller {
  static targets = ['button', 'menu'];

  isOpened = false;

  connect() {
    document.addEventListener('click', this.handleOutsideClick);
    this.buttonTarget.addEventListener('click', this.handleButtonClick);
  }

  disconnect() {
    document.removeEventListener('click', this.handleOutsideClick);
    this.buttonTarget.removeEventListener('click', this.handleButtonClick);
  }

  handleButtonClick = e => {
    e.preventDefault();

    this.toggle();
  };

  handleOutsideClick = e => {
    if (!this.isOpened || this.element.contains(e.target)) return;

    this.toggle();
  };

  toggle = () => {
    this.menuTarget.style.display = this.isOpened ? 'none' : 'block';

    this.buttonTarget.classList.toggle('is-opened', !this.isOpened);

    this.isOpened = !this.isOpened;
  };
}
