import { Controller } from 'stimulus';
import { isEmpty } from 'lodash';

export default class RemoteModalTrigger extends Controller {
  connect() {
    $(this.element).on('ajax:success', this.handleRequestModalSuccess);
  }

  disconnect() {
    $(this.element).off('ajax:success', this.handleRequestModalSuccess);
  }

  handleRequestModalSuccess = (e, data) => {
    if (isEmpty(data) || isEmpty(data.content)) {
      return;
    }

    document.body.insertAdjacentHTML('beforeend', data.content);
  };
}
