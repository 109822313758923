import { Controller } from 'stimulus';

import checkIsMobile from '@ftf-old/util/checkIsMobile';

export default class AuthModalTrigger extends Controller {
  connect() {
    this.element.addEventListener('click', this.handleClick);
  }

  disconnect() {
    this.element.removeEventListener('click', this.handleClick);
  }

  handleClick = e => {
    if (checkIsMobile()) {
      return;
    }

    e.preventDefault();

    $('#authModal').modal('show', this.element);
  };
}
