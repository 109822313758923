import { Controller } from 'stimulus';

export default class SelfVerificationModal extends Controller {
  static targets = ['form'];

  connect() {
    $(this.formTarget).on('ajax:success', this.handleFormSuccess);
  }

  disconnect() {
    $(this.formTarget).off('ajax:success', this.handleFormSuccess);
  }

  handleFormSuccess = () => {
    $(this.element).modal('hide');

    window.dispatchEvent(new Event('SelfVerificationModal:success'));
  };
}
