import { Controller } from 'stimulus';
import flatpickr from 'flatpickr';

import { handleAJAXError, handleInfo } from '@ftf/lib/alerts';
import checkIsMobile from '@ftf-old/util/checkIsMobile';

export default class RolloverForm extends Controller {
  static targets = [
    'startOnField',
    'endOnField',
    'offeringIdsField',
    'activateButton',
  ];

  flatpickrInstances = {
    startOnField: undefined,
    endOnField: undefined,
  };

  connect() {
    $(this.element).validator();

    (() => {
      const { maxDate } = this.startOnFieldTarget.dataset;

      this.flatpickrInstances['startOnField'] = flatpickr(
        this.startOnFieldTarget,
        {
          allowInput: true,
          maxDate,
          onChange: this.handleStartOnChange,
        },
      );
    })();

    (() => {
      const { maxDate, minDate } = this.endOnFieldTarget.dataset;

      this.flatpickrInstances['endOnField'] = flatpickr(this.endOnFieldTarget, {
        allowInput: true,
        maxDate,
        minDate,
      });
    })();

    $(this.offeringIdsFieldTarget).selectpicker({
      dropupAuto: false,
      mobile: checkIsMobile(),
      size: 5,
      container: 'body',
    });

    $(this.activateButtonTarget).on({
      'ajax:success': this.handleActivationSuccess,
      'ajax:error': handleAJAXError,
    });
  }

  disconnect() {
    $(this.element).validator('destroy');

    Object.keys(this.flatpickrInstances).forEach(key => {
      this.flatpickrInstances[key].destroy();
    });

    $(this.offeringIdsFieldTarget).selectpicker('destroy');

    $(this.activateButtonTarget).off({
      'ajax:success': this.handleActivationSuccess,
      'ajax:error': handleAJAXError,
    });
  }

  handleStartOnChange = (selectedDates, dateStr) => {
    this.flatpickrInstances['endOnField'].set('minDate', dateStr);
  };

  handleActivationSuccess = () => {
    handleInfo('Scheduled investments will be activated!', {
      autoDismissible: true,
    });
  };
}
