export const Colors = Object.freeze({
  red: '#e85656',
  darkRed: '#A93C3C',
  blue: '#349bd3',
  darkBlue: '#124562',

  // Shades of gray from the ligthest to the darkest
  white: '#ffffff',
  gray10: '#f9f9f9',
  gray20: '#e5e5e5',
  gray30: '#cbcbcb',
  gray40: '#b2b2b2',
  gray50: '#808080',
  gray60: '#9a9a9a',
  gray70: '#606060',
  gray80: '#4d4d4d',
  gray90: '#323232',
  black: '#000000',

  transparentBlack: 'rgba(0, 0, 0, 0.25)',
});
