import { Controller } from 'stimulus';

import { handleInfo, handleAJAXError } from '@ftf/lib/alerts';

export default class RecentUsers extends Controller {
  static targets = ['toggleBooleanAttribute'];

  connect() {
    this.toggleBooleanAttributeTargets.forEach(el => {
      el.addEventListener('click', this.handleToggle, false);
    });
  }

  disconnect() {
    this.toggleBooleanAttributeTargets.forEach(el => {
      el.removeEventListener('click', this.handleToggle, false);
    });
  }

  handleToggle = e => {
    e.preventDefault();
    const { url, method } = e.target.dataset;

    $.ajax({
      url,
      method,
    })
      .done(result => {
        let isLenderRetailInstitution = result.lender_retail_institution;
        e.target.checked = isLenderRetailInstitution;
        handleInfo('Successfully updated.', {
          type: 'success',
          autoDismissible: true,
        });
      })
      .fail(handleAJAXError);
  };
}
