import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class BorrowerPaymentsPayoffStatement extends Controller {
  connect() {}

  updateCalculation = e => {
    let dealId = e.target.dataset.dealId;
    let payoffDate = e.target.value;
    Rails.ajax({
      type: 'get',
      url: `/admin/deals/${dealId}/borrower_payments/update_calc?payoff_date=${payoffDate}`,
    });
  };
}
