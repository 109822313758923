import { Controller } from 'stimulus';
import Chart from 'chart.js/auto';

export default class ReturnsChart extends Controller {
  static targets = ['chart'];

  canvasContext() {
    return this.chartTarget.getContext('2d');
  }

  connect() {
    const months = this.chartTarget.dataset.months;
    const earningsPerMonth = this.chartTarget.dataset.earnings;
    const accumulatedPerMonth = this.chartTarget.dataset.accumulated;

    new Chart(this.canvasContext(), {
      type: 'bar',
      data: {
        labels: JSON.parse(months),
        datasets: [
          {
            label: `Your Monthly Distribution`,
            data: JSON.parse(earningsPerMonth),
            borderColor: '#333448',
            backgroundColor: '#333448',
          },
          {
            label: `Your Total Distribution`,
            data: JSON.parse(accumulatedPerMonth),
            borderColor: '#65c0c1',
            backgroundColor: '#65c0c1',
          },
        ],
      },
      options: {
        categoryPercentage: 0.9,
        barPercentage: 0.65,
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            top: 20,
          },
        },
        plugins: {
          legend: {
            position: 'bottom',
          },
          tooltip: {
            callbacks: {
              label: function(context) {
                let label = context.dataset.label || '';

                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  }).format(context.parsed.y);
                }
                return label;
              },
            },
          },
        },
      },
    });
  }
}
