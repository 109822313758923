import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class AnalysisVersionNewContainer extends Controller {
  handleTemplateSelection = e => {
    const dealId = e.target.dataset.attr;
    const targetVersionId = e.target.selectedOptions[0].value;
    const taskAjaxRequest = `/admin/deals/${dealId}/analysis/version/new?template_id=${targetVersionId}`;
    Rails.ajax({
      type: 'get',
      url: taskAjaxRequest,
    });
  };
}
