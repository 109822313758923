import { Controller } from 'stimulus';

export default class RewardProgramForm extends Controller {
  connect() {
    $(this.element).validator({ disable: false });
  }

  disconnect() {
    $(this.element).validator('destroy');
  }
}
