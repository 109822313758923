import Color from 'color';

import { styled, Variables } from '@ftf/styles';
import { SpinnerWithMessage } from '@ftf/components/SpinnerWithMessage';

export const StyledSpinner = styled(SpinnerWithMessage)`
  align-items: center;
  background: ${Color(Variables.colorWhite)
    .alpha(0.8)
    .toString()};
  border-radius: 5px;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  margin: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
`;

export const Placeholder = styled.p`
  color: ${Variables.colorGray};
  font: 14px / 1.6 ${Variables.fontLato};
  left: 50%;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const style = {
  background: Variables.colorWhite,
  border: `1px solid ${Variables.borderColorLight}`,
  borderRadius: '5px',
  display: 'block',
  minHeight: '200px',
  padding: '15px',
  width: '100%',
};

export const activeStyle = {
  borderColor: Variables.colorBlack,
};

export const disabledStyle = {
  background: Variables.colorGrayLight,
};

export const rejectStyle = {
  borderColor: Variables.colorRed,
};
