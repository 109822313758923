import { Controller } from 'stimulus';

export default class AuditTasksForm extends Controller {
  connect() {
    $(this.element).on('ajax:success', this.handleAJAXSuccess);
  }

  disconnect() {
    $(this.element).off('ajax:success', this.handleAJAXSuccess);
  }

  handleAJAXSuccess = () => {
    $.ajax({
      url: this.element.dataset.auditTrailUrl,
      dataType: 'script',
    });
  };
}
