import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class PodDealsTable extends Controller {
  static targets = ['podDeals'];

  connect() {
    const tableRow = document.querySelector('.pod-deal-row');
    const dealId = tableRow.dataset.id;
    this.handleSelectedRow(dealId);
  }

  toggleSelectedRow(newSelectedId) {
    if (this.selectedPodDealId !== newSelectedId) {
      if (this.selectedPodDealId !== '') {
        const previousRowId = `pod-deal-row-${this.selectedPodDealId}`;
        const previousRowSelected = document.getElementById(previousRowId);
        if (previousRowSelected) {
          document
            .getElementById(previousRowId)
            .classList.remove('selected-row');
        }
      }
      const newSelectedRowId = `pod-deal-row-${newSelectedId}`;
      document.getElementById(newSelectedRowId).classList.add('selected-row');
      this.selectedPodDealId = newSelectedId;
    }
  }

  handleSelectedRow = e => {
    if (e.target) {
      this.toggleSelectedRow(e.target.dataset.attr);
      e.preventDefault();
      Rails.ajax({
        type: 'get',
        url: `/admin/pod_deals?deal_id=${e.target.dataset.attr}`,
      });
    } else {
      this.toggleSelectedRow(e);
      Rails.ajax({
        type: 'get',
        url: `/admin/pod_deals?deal_id=${e}`,
      });
    }
  };
}
