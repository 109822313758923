import { Controller } from 'stimulus';
import useValidation from '@lysyi3m/stimulus-use-validation';
import { debounce } from 'lodash';

import { formatNumbersAfterTyped, formatNumbers } from '@ftf-old/main/helpers';
import {
  textFieldGreaterThanZero,
  enforcePrecisionTwo,
} from '@ftf/lib/validators';
import { formatCurrency, formatNumberToPercentage } from '@ftf/lib/formatters';
import { ACQUISITION_TYPE_OWN_WITH_LOAN } from '@ftf-old/constants';

export default class DealBuilder extends Controller {
  static targets = [
    'asIsField',
    'asIsBorrowerFtfVal',
    'buyBoxMatchDisplay',
    'borrowerArvVal',
    'calcField',
    'calcResult',
    'constCompletedAtCloseVal',
    'constCompletedAfterCloseVal',
    'copyAsIsToggle',
    'creditScoreVal',
    'distPercentage',
    'distPercentageError',
    'form',
    'interestRateVal',
    'fundingRequestAmountVal',
    'loanPropertyTypeVal',
    'loanProjectTypeVal',
    'loanToAsIsValueVal',
    'loanToCostVal',
    'payoffField',
    'pdfForm',
    'pdfFormField',
    'pdfAddtlField',
    'purchDistAmountAtCloseVal',
    'purchasePriceVal',
    'quickSearchField',
    'readOnlyUnderlyingAsset',
    'subjectToField',
    'downloadPdfDisplay',
    'downloadPdf',
    'pdfDownloadTitle',
    'bbMatchChevron',
  ];

  initialize() {
    this.handleCalcFieldInput = debounce(this.calculate, 300);
  }

  connect() {
    if (this.hasDownloadPdfTarget) {
      this.downloadPdfTarget.addEventListener(
        'click',
        this.toggleDownloadPdfPanel,
      );
    } else {
      this.constructor.targets.splice(-4);
    }

    formatNumbers();
    formatNumbersAfterTyped();

    useValidation(this, {
      disable: false,
      errorClassName: 'is-invalid',
      errorSelector: '.invalid-feedback',
      validators: {
        'deal_submission[flexible_borrower_arv]': textFieldGreaterThanZero,
        'deal_submission[flexible_funding_request_amount]': textFieldGreaterThanZero,
        'deal_submission[flexible_purchase_price]': enforcePrecisionTwo,
        'deal_submission[flexible_construction_completed_at_close]': enforcePrecisionTwo,
        'deal_submission[flexible_construction_to_be_completed_after_close]': enforcePrecisionTwo,
        'deal_submission[flexible_earnest_money_deposit]': enforcePrecisionTwo,
        'deal_submission[flexible_as_is_borrower_ftf_value]': enforcePrecisionTwo,
      },
      form: this.formTarget,
    });

    useValidation(this, {
      disable: true,
      errorClassName: 'is-invalid',
      errorSelector: '.invalid-feedback',
      form: this.pdfFormTarget,
    });

    this.calcFieldTargets.forEach(el => {
      el.addEventListener('input', this.handleCalcFieldInput);
    });

    this.calcFieldTargets
      .filter(
        el => el.name === 'deal_submission[flexible_as_is_borrower_ftf_value]',
      )[0]
      .addEventListener('input', this.handleBorrowerChange, false);

    this.distPercentageTarget.addEventListener(
      'input',
      this.toggleDistPercentageError,
    );

    this.quickSearchFieldTargets.forEach(el => {
      el.addEventListener(
        'quicksearch:selected',
        this.handleQuickSearchSelected,
      );
    });

    if (this.formTarget.dataset.dealId) {
      this.calculate();
    }
  }

  disconnect() {
    this.calcFieldTargets.forEach(el => {
      el.removeEventListener('input', this.handleCalcFieldInput);
    });

    this.quickSearchFieldTargets.forEach(el => {
      el.removeEventListener(
        'quicksearch:selected',
        this.handleQuickSearchSelected,
      );
    });

    this.distPercentageTarget.removeEventListener(
      'input',
      this.toggleDistPercentageError,
    );
  }

  sendBuyBoxData() {
    // CREDIT SCORE
    const creditScore =
      this.creditScoreValTarget.value === ''
        ? null
        : parseInt(this.creditScoreValTarget.value, 10);
    //INTEREST RATE
    const interestRate =
      this.interestRateValTarget.value === ''
        ? null
        : parseFloat(this.interestRateValTarget.value);
    // LTARV
    const fundingRequestAmount =
      this.fundingRequestAmountValTarget.value === ''
        ? null
        : parseFloat(
            this.fundingRequestAmountValTarget.value.replace(/,/g, ''),
          );
    const borrowerArv =
      this.borrowerArvValTarget.value === ''
        ? null
        : parseFloat(this.borrowerArvValTarget.value.replace(/,/g, ''));
    const loanToAfterRepairValue =
      fundingRequestAmount && borrowerArv
        ? (fundingRequestAmount * 100) / borrowerArv
        : null;
    // LTCAC
    const purchDistAmountAtCloseFieldValue = this
      .purchDistAmountAtCloseValTarget.innerHTML;
    const purchaseDistributionAmountAtClose =
      purchDistAmountAtCloseFieldValue === 'N/A'
        ? null
        : parseFloat(
            purchDistAmountAtCloseFieldValue.substring(1).replace(/,/g, ''),
          );
    const purchasePrice =
      this.purchasePriceValTarget.value === ''
        ? null
        : parseFloat(this.purchasePriceValTarget.value.replace(/,/g, ''));
    const constructionCompletedAtClose =
      this.constCompletedAtCloseValTarget.value === ''
        ? null
        : parseFloat(
            this.constCompletedAtCloseValTarget.value.replace(/,/g, ''),
          );
    const loanToCostAtCloseCalc =
      ((purchaseDistributionAmountAtClose + constructionCompletedAtClose) *
        100) /
      (purchasePrice + constructionCompletedAtClose);
    const loanToCostAtClose = loanToCostAtCloseCalc
      ? loanToCostAtCloseCalc
      : null;
    //LTAIV
    const asIsBorrowerFtfValue =
      this.asIsBorrowerFtfValTarget.value === ''
        ? null
        : parseFloat(this.asIsBorrowerFtfValTarget.value.replace(/,/g, ''));
    const loanToAsIsValueCalc =
      ((purchaseDistributionAmountAtClose + constructionCompletedAtClose) *
        100) /
      asIsBorrowerFtfValue;
    const loanToAsIsValue = loanToAsIsValueCalc ? loanToAsIsValueCalc : null;
    //LTC
    const constructionCompletedAfterClose =
      this.constCompletedAfterCloseValTarget.value === ''
        ? null
        : parseFloat(
            this.constCompletedAfterCloseValTarget.value.replace(/,/g, ''),
          );
    const loanToCostCalc =
      (fundingRequestAmount * 100) /
      (purchasePrice +
        constructionCompletedAtClose +
        constructionCompletedAfterClose);
    const loanToCost = loanToCostCalc ? loanToCostCalc : null;
    //Property & Project Types
    const propertyType = this.loanPropertyTypeValTarget.value;
    const projectType = this.loanProjectTypeValTarget.value;

    const loanComparisonObj = {
      project_type: projectType,
      property_type: propertyType,
      loan_amount: fundingRequestAmount,
      interest_rate: interestRate,
      loan_to_cost_at_close: loanToCostAtClose,
      loan_to_as_is_value: loanToAsIsValue,
      loan_to_cost: loanToCost,
      loan_to_after_repair_value: loanToAfterRepairValue,
      credit_score: creditScore,
    };

    // Dispatch event that will be listened for in the BuyBoxComparison stimulus controller
    window.dispatchEvent(
      new CustomEvent('SendBuyBoxData', {
        detail: loanComparisonObj,
      }),
    );
  }

  toggleDistPercentageError = () => {
    const distPercentageVal = this.distPercentageTarget.value;
    const distPercentageError = this.distPercentageErrorTarget;

    if (Number(distPercentageVal) >= 0) {
      distPercentageError.classList.remove('d-block');
    } else {
      distPercentageError.classList.add('d-block');
    }
  };

  toggleDownloadPdfPanel = () => {
    this.toggleDownloadPdf();
  };

  toggleDownloadPdf() {
    this.downloadPdfDisplayTarget.classList.toggle('d-none');
    this.pdfDownloadTitleTarget.classList.toggle('d-none');
    this.bbMatchChevronTarget.classList.toggle('chev-rotated');
  }

  calculate = () => {
    const data = $(this.calcFieldTargets)
      .serializeArray()
      .reduce((acc, item) => {
        const [, name] = item.name.match(/\[(.*?)\]/);

        acc[name] = item.value;

        return acc;
      }, {});

    $.ajax({
      url: '/admin/underwritable/calculations.json',
      method: 'POST',
      data: { underwritable_calculator_input: data },
      success: ({ result }) => {
        this.fillCalcResult(result);
        this.fillPdfForm(data);
      },
    });
  };

  handleBorrowerChange = () => {
    const value = this.calcFieldTargets.filter(
      el => el.name === 'deal_submission[flexible_as_is_borrower_ftf_value]',
    )[0].value;
    this.calcFieldTargets.filter(
      el => el.name === 'deal_submission[flexible_as_is_borrower_ftf_value]',
    )[0].value = value[0] === '0' ? '1' : value;
    const newValue = this.calcFieldTargets.filter(
      el => el.name === 'deal_submission[flexible_as_is_borrower_ftf_value]',
    )[0].value;
    this.calcFieldTargets.filter(
      el => el.name === 'deal_submission[flexible_as_is_borrower_ftf_value]',
    )[0].value =
      newValue.indexOf('.') >= 0
        ? newValue.substr(0, newValue.indexOf('.')) +
          newValue.substr(newValue.indexOf('.'), 3)
        : newValue;
  };

  handleQuickSearchSelected = e => {
    const { id } = e.detail.suggestion;
    const { url } = e.target.dataset;

    $.ajax({
      url,
      method: 'GET',
      data: { id },
      success: result => {
        this.fillResult(result);
        this.calculate();
      },
    });
  };

  handleFormSubmit = () => {
    this.formTarget.validateForm();

    const hasErrors = this.formTarget.hasErrors();

    if (hasErrors) return;

    $('#underwritingReviewModal').modal('show');
  };

  handleAcquisitionChange(e) {
    let payoffField = document.querySelector('.payoff');
    let rebookedField = document.querySelector('.rebooked');
    if (e.target.value === ACQUISITION_TYPE_OWN_WITH_LOAN) {
      payoffField.classList.remove('d-none');
      rebookedField.classList.remove('d-none');
      this.payoffFieldTarget.required = true;
      useValidation(this, {
        errorClassName: 'is-invalid',
        errorSelector: '.invalid-feedback',
        validators: {
          'deal_submission[flexible_payoff_amount]': textFieldGreaterThanZero,
        },
        form: this.formTarget,
      });
    } else {
      payoffField.classList.add('d-none');
      rebookedField.classList.add('d-none');
      this.payoffFieldTarget.required = false;
      useValidation(this, {
        errorClassName: 'is-invalid',
        errorSelector: '.invalid-feedback',
        validators: {
          'deal_submission[flexible_payoff_amount]': '',
        },
        form: this.formTarget,
      });
    }
  }

  handleCopyToggle = e => {
    if (e.target.checked) {
      this.asIsFieldTargets.forEach(field => {
        if (field.value) {
          let currentField = field.dataset.attr;
          let subjectToField = this.subjectToFieldTargets.find(
            el => el.dataset.attr === currentField,
          );
          subjectToField.value = field.value;
          if (subjectToField.dataset.attr === 'underlying_asset') {
            this.readOnlyUnderlyingAssetTarget.value = field.value;
          }
        }
      });
      this.toggleToReadOnlyFields(true);
    } else {
      this.toggleToReadOnlyFields(false);
    }
  };

  toggleToReadOnlyFields(isReadOnly) {
    this.subjectToFieldTargets.forEach(field => {
      field.readOnly = isReadOnly;
      if (!isReadOnly) {
        field.value = '';
      }
    });
    this.toggleUnderlyingAsset(isReadOnly);
  }

  toggleUnderlyingAsset(hide) {
    const readOnlyField = this.readOnlyUnderlyingAssetTarget;
    const selectableField = this.subjectToFieldTargets.find(
      el => el.dataset.attr === 'underlying_asset',
    );
    if (hide) {
      selectableField.classList.add('hidden-property-type-field');
      readOnlyField.classList.remove('hidden-property-type-field');
    } else {
      selectableField.classList.remove('hidden-property-type-field');
      readOnlyField.classList.add('hidden-property-type-field');
    }
  }

  fillResult = result => {
    Object.keys(result).forEach(key => {
      const resultFields = this.formTarget.querySelectorAll(`[name*="${key}"]`);

      resultFields.forEach(el => {
        switch (el.type) {
          case 'radio':
            el.checked = el.value === result[key];
            break;

          case 'hidden':
            break;

          default:
            el.value = result[key];
            break;
        }
      });
    });
  };

  fillCalcResult = result => {
    Object.keys(result).forEach(key => {
      const resultElements = this.calcResultTargets.filter(
        el => el.dataset.attrName === key,
      );

      resultElements.forEach(el => {
        let text;

        switch (el.dataset.format) {
          case 'currency':
            text = formatCurrency(result[key]);
            break;

          case 'percentage':
            text = formatNumberToPercentage(result[key]);
            break;

          default:
            text = result[key];
            break;
        }

        el.innerText = text;
      });
    });
  };

  fillPdfForm = data => {
    const formData =
      data ||
      $(this.calcFieldTargets)
        .serializeArray()
        .reduce((acc, item) => {
          const [, name] = item.name.match(/\[(.*?)\]/);

          acc[name] = item.value;

          return acc;
        }, {});

    Object.keys(formData).forEach(key => {
      const resultFields = this.pdfFormFieldTargets.filter(
        el => el.dataset.attrName === key,
      );

      resultFields.forEach(el => (el.value = data[key]));
    });
  };

  fillAddtlFieldsPdfForm = () => {
    this.pdfAddtlFieldTargets.forEach(input => {
      const inputId = input.id;
      const key = inputId.replace('deal_submission_', '');
      const resultFields = this.pdfFormFieldTargets.filter(
        el => el.dataset.attrName === key,
      );

      resultFields.forEach(
        el => (el.value = document.getElementById(`${inputId}`).value),
      );
    });
  };

  setCheckboxValue(e) {
    this.copyAsIsToggleTarget.value = e.target.checked;
    this.handleCopyToggle(e);
  }
}
