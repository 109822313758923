import { Controller } from 'stimulus';
import { debounce } from 'lodash';

import { formatCurrency } from '@ftf/lib/formatters';

export default class BorrowerInvoices extends Controller {
  static targets = ['amountField', 'totalPayment', 'input'];

  initialize() {
    this.debouncedHandleInput = debounce(this.handleInput, 300);
  }

  connect() {
    $(this.element).validator();

    $(this.element).on('input', this.debouncedHandleInput);
  }

  disconnect() {
    $(this.element).validator('destroy');

    $(this.element).off('input', this.debouncedHandleInput);
  }

  handleInput = () => {
    const sum = this.inputTargets.reduce(
      (acc, input) => acc + parseFloat(input.value || 0),
      0,
    );

    this.amountFieldTarget.value = sum;
    this.totalPaymentTarget.innerText = formatCurrency(sum);
  };
}
