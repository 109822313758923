import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';

import { SortableImage } from './components/SortableImage';

export const SortableList = SortableContainer(
  ({ images, name, onDelete, isDisabled }) => (
    <div className="container-fluid">
      <div className="row">
        {images.map((image, index) => (
          <SortableImage
            image={image}
            imageIndex={index}
            index={index}
            isDisabled={isDisabled}
            key={image.id}
            name={name}
            onDelete={onDelete}
          />
        ))}
      </div>
    </div>
  ),
);
