import { Controller } from 'stimulus';

export default class TeamManagementForm extends Controller {
  static targets = ['podSelect'];

  connect() {
    this.handlePodSelectChange();

    $(this.podSelectTarget).on({
      change: this.handlePodSelectChange,
    });
  }

  disconnect() {
    $(this.podSelectTarget).off({
      change: this.handlePodSelectChange,
    });
  }

  handlePodSelectChange = () => {
    const param = window.location.search.substring(
      window.location.search.indexOf('=') + 1,
    );
    if (this.podSelectTarget.value === '' && param === undefined) {
      $('.team-members-selectors :input').attr('disabled', true);
    } else {
      $('.team-members-selectors :input').attr('disabled', false);
    }

    if (this.podSelectTarget.options.selectedIndex > 0) {
      window.location.href = `${window.location.origin}${
        window.location.pathname
      }?group=${
        this.podSelectTarget.options[this.podSelectTarget.options.selectedIndex]
          .value
      }`;
    }
  };
}
