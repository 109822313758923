import { Controller } from 'stimulus';
import { find, isNil, isEmpty } from 'lodash';

import { handleAJAXError } from '@ftf/lib/alerts';

const STEPS = ['address', 'financials', 'experience', 'account'];

export default class DealApplicationForm extends Controller {
  static targets = ['progressItem', 'step', 'field'];

  currentStep = 'address';

  connect() {
    window.addEventListener(
      'DealApplicationForm:goToStep',
      this.handleGoToStep,
      false,
    );

    this.element.addEventListener('keydown', this.handleFormKeydown, false);

    $(this.element)
      .on('ajax:error', handleAJAXError)
      .on('ajax:success', this.handleFormSuccess);
  }

  disconnect() {
    window.removeEventListener(
      'DealApplicationForm:goToStep',
      this.handleGoToStep,
      false,
    );

    this.element.removeEventListener('keydown', this.handleFormKeydown, false);

    $(this.element)
      .off('ajax:error', handleAJAXError)
      .off('ajax:success', this.handleFormSuccess);
  }

  updateProgress = step => {
    this.progressItemTargets.forEach(element => {
      element.classList.toggle('is-active', element.dataset.step === step);
    });
  };

  updateStep = async step => {
    const currentStep = find(
      this.stepTargets,
      elem => elem.dataset.step === this.currentStep,
    );

    const targetStep = find(
      this.stepTargets,
      elem => elem.dataset.step === step,
    );

    await new Promise(resolve => {
      $(currentStep).fadeOut(300, () => {
        $(currentStep).removeClass('is-active');
        resolve();
      });
    });

    await new Promise(resolve => {
      $(targetStep).fadeIn(300, () => {
        $(targetStep).addClass('is-active');
        resolve();
      });
    });

    await new Promise(resolve => {
      $('html, body').animate({ scrollTop: 0 }, 300, () => {
        $(targetStep)
          .find(':input:enabled:visible:first')
          .focus();
        resolve();
      });
    });
  };

  goToStep = step => {
    if (!STEPS.includes(step)) {
      return;
    }

    if (step === this.currentStep) {
      return;
    }

    this.updateProgress(step);
    this.updateStep(step);

    this.currentStep = step;
  };

  handleFormKeydown = e => {
    if (e.which !== 13) {
      return;
    }

    e.preventDefault();
  };

  handleFormSuccess = (e, data) => {
    localStorage.setItem('dealApplicationId', data.uuid);

    this.goToStep('account');
  };

  handleGoToStep = e => {
    if (isNil(e.detail) || isEmpty(e.detail)) {
      return;
    }

    this.goToStep(e.detail);
  };
}
