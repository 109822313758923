import { Controller } from 'stimulus';

export default class ClosingDataForm extends Controller {
  static targets = [
    'dateField',
    'entitiesSelect',
    'needsTitleCompanyContactedField',
    'titleCompanyInfoRow',
    'editEntityLink',
  ];

  connect() {
    this.needsTitleCompanyContactedFieldTarget.addEventListener(
      'change',
      this.handleNeedsTitleCompanyContactedChange,
      false,
    );

    if (
      this.entitiesSelectTarget.options[this.entitiesSelectTarget.selectedIndex]
        .value !== ''
    ) {
      let entityId = Number(
        this.entitiesSelectTarget.options[
          this.entitiesSelectTarget.selectedIndex
        ].value,
      );
      let dealId = location.pathname.split('/')[3];
      let userId = Number(this.entitiesSelectTarget.dataset.user);
      let editLink = `/admin/users/${userId}/entities/${entityId}/edit?deal_id=${dealId}`;
      let span = this.editEntityLinkTarget;
      span.innerHTML = `<a id="edit-entity" class='font-weight-bold small' data-disable-with='Loading...' data-remote='true' data-controller='RemoteModalTrigger' href='${editLink}'>Edit</a>`;
    }
  }

  disconnect() {
    this.needsTitleCompanyContactedFieldTarget.removeEventListener(
      'change',
      this.handleNeedsTitleCompanyContactedChange,
      false,
    );
  }

  handleNeedsTitleCompanyContactedChange = e => {
    this.titleCompanyInfoRowTarget.classList.toggle(
      'd-none',
      !e.target.checked,
    );
  };

  handleEntitySelection = e => {
    let dealId = location.pathname.split('/')[3];
    let entityId = Number(e.target.value);
    let userId = Number(e.target.dataset.user);
    let editLink = `/admin/users/${userId}/entities/${entityId}/edit?deal_id=${dealId}`;
    let span = this.editEntityLinkTarget;

    if (entityId) {
      span.innerHTML = `<a id="edit-entity" class='font-weight-bold small' data-disable-with='Loading...' data-remote='true' data-controller='RemoteModalTrigger' href='${editLink}'>Edit</a>`;
    } else {
      span.innerHTML = '';
    }
  };
}
