import { escapeHighlightedString as esc } from 'autocomplete.js';

import { reportError } from '@ftf/lib/reporting';
import { toTitleCase } from '@ftf-old/util/Formatter';

const USER_TYPES = ['borrower_boolean', 'lender_boolean', 'broker'];

const stripBool = (str = '') => str.replace('_boolean', '');

const userTemplate = (hit, answer, isPersistent) => {
  const { _highlightResult: user } = hit;

  try {
    const userTypes = USER_TYPES.map(type => {
      if (hit[type]) {
        return toTitleCase(stripBool(type));
      }

      return null;
    }).filter(Boolean);

    const first = USER_TYPES.find(type => hit[type]);

    const firstName = user.first_name
      ? esc(user.first_name.value)
      : 'NOT PROVIDED';

    const lastName = user.last_name
      ? esc(user.last_name.value)
      : 'NOT PROVIDED';

    const email = user.email ? esc(user.email.value) : 'NOT PROVIDED';

    const content = `
      <div class="row">
        <div class="col-4 col-xs-4 quick-search__id">
          #${esc(user.id.value)}
        </div>
        <div class="col-8 col-xs-8 quick-search__name">
          ${firstName} ${lastName}
          <br/>
          <small>
            ${email}
            <br/>
            ${userTypes.join(', ')}
          </small>
        </div>
      </div>
    `;

    if (isPersistent) {
      return `<div class="quick-search__link">${content}</div>`;
    }

    const hostname = window.location.hostname;
    let domain = 'invest.app.upright.us';

    if (hostname.includes('dev.notprod.live')) {
      domain = 'invest.app.upright.dev.notprod.live';
    }

    if (hostname.includes('stg.notprod.live')) {
      domain = 'invest.app.upright.stg.notprod.live';
    }

    if (hostname.includes('local.dev.notprod.live')) {
      domain = 'local.dev.notprod.live';
    }

    if (hostname.includes('monolith.bc.upright.us')) {
      domain = 'monolith.bc.upright.us';
    }

    const href = hit.borrower_boolean
      ? `https://${domain}/admin/borrowers/${hit.id}`
      : `https://${domain}/dashboard/${hit.id}/${stripBool(first)}`;

    return `
      <a data-turbolinks="false" class="quick-search__link" href="${href}">${content}</a>
    `;
  } catch (error) {
    reportError(error, {
      data: { query: answer.query, user },
    });

    return undefined;
  }
};

export default userTemplate;
