import { Controller } from 'stimulus';

export default class TwoFactorAuthModal extends Controller {
  static targets = ['verifyForm'];

  isVerified = false;

  connect() {
    window.addEventListener('TwoFactorAuthModal:show', this.handleShow);

    $(this.element).on('hidden.bs.modal', this.handleModalClose);

    $(this.verifyFormTarget).on({
      'ajax:success': this.handleVerifyFormSuccess,
      'ajax:error': this.handleVerifyFormError,
    });
  }

  disconnect() {
    window.removeEventListener('TwoFactorAuthModal:show', this.handleShow);

    $(this.element).off('hidden.bs.modal', this.handleModalClose);

    $(this.verifyFormTarget).off({
      'ajax:success': this.handleVerifyFormSuccess,
      'ajax:error': this.handleVerifyFormError,
    });
  }

  handleShow = e => {
    window.dispatchEvent(
      new CustomEvent('TwoFactorAuthForm:fill', { detail: e.detail }),
    );

    $(this.element).modal('show');
  };

  handleModalClose = () => {
    if (this.isVerified) {
      this.isVerified = false;

      return;
    }

    window.dispatchEvent(new CustomEvent('TwoFactorAuthModal:unverified'));
  };

  handleVerifyFormSuccess = () => {
    this.isVerified = true;

    window.dispatchEvent(new CustomEvent('TwoFactorAuthModal:verified'));

    $(this.element).modal('hide');
  };

  handleVerifyFormError = () => {
    this.isVerified = false;

    window.dispatchEvent(new CustomEvent('TwoFactorAuthModal:unverified'));
  };
}
