const warning = '#invest-now-warning-modal';
const warningConfirmation = '#invest-now-warning-modal-confirmation';
const open = 'InvestNowModal:open';
const close = 'InvestNowModal:close';
const onClose = 'InvestNowModal:onClose';
const onError = 'InvestNowModal:onError';
const onStatusUpdate = 'InvestNow:onStatusUpdate';
const onUpdatePendingInfo = 'InvestNow:onUpdatePendingInfo';
const notNow = '#invest-now-warning-modal-not-now';
const onFullyFunded = 'InvestNow:onFullyFunded';

export const show = 'show';
export const hide = 'hide';
export const selfVerificationModalSuccess = 'SelfVerificationModal:success';

export const operationStatuses = {
  NOT_AVAILABLE: 'not_available',
  SUCCESS: 'success',
  MAX_AMOUNT_EXCEEDED: 'max_amount_exceeded',
  EXCEEDED: 'exceeded',
};

export const reserveStates = {
  FULLY_FUNDED: 'fully_funded',
  PENDING_RESERVATIONS: 'pending_reservations',
  OPEN: 'open',
};

export const beforeUnload = 'beforeunload';
export const click = 'click';
export const CountdownTimer = {
  start: 'CountdownTimer:start',
  stop: 'CountdownTimer:stop',
  expired: 'CountdownTimer:expired',
};

export const InvestNowModal = {
  close,
  notNow,
  onClose,
  onError,
  onFullyFunded,
  open,
  onStatusUpdate,
  onUpdatePendingInfo,
  warning,
  warningConfirmation,
};
