import { Controller } from 'stimulus';
import { find } from 'lodash';

export default class TasksCopyToMultiple extends Controller {
  static targets = [
    'copyForm',
    'copyButton',
    'taskInfoRow',
    'notesInputToApply',
    'statusInputToApply',
    'assigneeInputToApply',
    'dueDateInputToApply',
  ];

  toggleDisplay = e => {
    this.copyFormTargets.forEach(el =>
      el.classList.toggle('d-none', !e.target.checked),
    );
    this.copyButtonTargets.forEach(el =>
      el.classList.toggle('d-none', !e.target.checked),
    );
  };

  applyToAllTasks = e => {
    const status = e.target.dataset.attr;

    const allStatusRows = this.taskInfoRowTargets.filter(
      el => el.dataset.attr === status,
    );

    allStatusRows.forEach(row => {
      const fields = row.querySelectorAll('input, select, textarea');
      fields.forEach(field => {
        if (!field.dataset.target) return;
        const target = field.dataset.target.split('.')[1];
        const valToCopy = this.getValToCopy(target, status);
        if (valToCopy !== '') field.value = valToCopy;
        field.setAttribute('data-dirty', '');
      });
    });
  };

  copyFromDefault = e => {
    const taskId = e.target.dataset.attr;
    const status = e.target.dataset.key;

    const row = find(this.taskInfoRowTargets, el => el.dataset.key === taskId);
    const fields = row.querySelectorAll('input, select, textarea');
    fields.forEach(field => {
      if (!field.dataset.target) return;
      const target = field.dataset.target.split('.')[1];
      const valToCopy = this.getValToCopy(target, status);
      if (valToCopy !== '') field.value = valToCopy;
      field.setAttribute('data-dirty', '');
    });
  };

  getValToCopy(target, status) {
    let value = '';
    switch (target) {
      case 'taskId':
        break;
      case 'assigneeInput':
        value = find(
          this.assigneeInputToApplyTargets,
          el => el.dataset.key === status,
        ).value;
        break;
      case 'statusInput':
        value = find(
          this.statusInputToApplyTargets,
          el => el.dataset.key === status,
        ).value;
        break;
      case 'dueDateInput':
        value = find(
          this.dueDateInputToApplyTargets,
          el => el.dataset.key === status,
        ).value;
        break;
      case 'notesInput':
        value = find(
          this.notesInputToApplyTargets,
          el => el.dataset.key === status,
        ).value;
        break;
    }
    return value;
  }
}
