import React, { Component, Fragment } from 'react';
import {
  arrayOf,
  string,
  func,
  shape,
  number,
  oneOfType,
  bool,
} from 'prop-types';
import { find, noop, isNil } from 'lodash';
import { arrayMove } from 'react-sortable-hoc';

import { DropArea } from './components/DropArea';
import { SortableList } from './components/SortableList';

export class ImagesControl extends Component {
  static propTypes = {
    accept: arrayOf(string),
    disabled: bool,
    images: arrayOf(
      shape({
        attachment_asset_url: string,
        id: oneOfType([string, number]),
        preview: string,
        signed_id: string,
      }),
    ),
    maxSize: number,
    name: string,
    onDelete: func,
    onDropAccepted: func,
    onSortEnd: func,
  };

  static defaultProps = {
    images: [],
    onDropAccepted: noop,
    onSortEnd: noop,
    onDelete: noop,
  };

  state = {
    deletedImages: [],
  };

  handleSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) {
      return;
    }

    const { images, onSortEnd } = this.props;

    const sortedImages = arrayMove(images, oldIndex, newIndex);

    onSortEnd(sortedImages);
  };

  handleDelete = id => {
    const { images, onDelete } = this.props;
    const deletedImage = find(images, { id: id });

    if (isNil(deletedImage.signed_id)) {
      const deletedImages = [...this.state.deletedImages, deletedImage];

      return this.setState({ deletedImages }, () => onDelete(id));
    }

    return onDelete(id);
  };

  render() {
    const {
      accept,
      disabled,
      images,
      maxSize,
      name,
      onDropAccepted,
    } = this.props;

    const { deletedImages } = this.state;

    return (
      <>
        <DropArea
          accept={accept}
          disabled={disabled}
          images={images}
          maxSize={maxSize}
          onDropAccepted={onDropAccepted}
        >
          <SortableList
            axis="xy"
            disabled={disabled}
            distance={20}
            images={images}
            isDisabled={disabled}
            name={name}
            onDelete={this.handleDelete}
            onSortEnd={this.handleSortEnd}
          />
        </DropArea>

        {name &&
          deletedImages.map((image, i) => (
            <Fragment key={image.id}>
              <input
                type="hidden"
                name={`${name}[${images.length + i + 1}][id]`}
                value={image.id}
              />
              <input
                type="hidden"
                name={`${name}[${images.length + i + 1}][_destroy]`}
                value={true}
              />
            </Fragment>
          ))}
      </>
    );
  }
}
