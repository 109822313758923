import { Controller } from 'stimulus';

export default class Summary extends Controller {
  static targets = ['link'];

  copy(event) {
    event.preventDefault();

    const url = this.linkTarget.href;

    navigator.clipboard.writeText(url).then(
      function() {
        this.element.setAttribute('title', 'Copied!');

        $(this.element).tooltip('show');

        setTimeout(() => {
          $(this.element).tooltip('hide');
          this.element.removeAttribute('title');
        }, 2000);
      }.bind(this),
    );
  }
}
