import { Controller } from 'stimulus';

import { handleAJAXError } from '@ftf/lib/alerts';

export default class LoanAdjustmentsTable extends Controller {
  static targets = [
    'generateModalButton',
    'generateSubmitButton',
    'positionAdjustmentsGeneratedAt',
    'voidModalButton',
    'voidSubmitButton',
  ];

  connect() {
    this.generateSubmitButtonTargets.forEach(button => {
      button.addEventListener('click', this.handleGenerateButton);
    });

    this.voidSubmitButtonTargets.forEach(button => {
      button.addEventListener('click', this.handleVoidButton);
    });

    $(this.element).on('ajax:error', handleAJAXError);
  }

  disconnect() {
    this.generateSubmitButtonTargets.forEach(button => {
      button.removeEventListener('click', this.handleGenerateButton);
    });

    this.voidSubmitButtonTargets.forEach(button => {
      button.removeEventListener('click', this.handleVoidButton);
    });

    $(this.element).off('ajax:error', handleAJAXError);
  }

  handleGenerateButton = e => {
    const { url, id: loanAdjustmentId } = e.target.dataset;

    const modalComponent = $(
      `#generate-position-adjustments-modal-${loanAdjustmentId}`,
    );
    $.ajax({
      url,
      method: 'POST',
      success: result => {
        modalComponent.modal('hide');
        this.getButton('generate', loanAdjustmentId).setAttribute(
          'disabled',
          true,
        );
        this.fillGeneratedAt(result, loanAdjustmentId);
      },
      error: error => {
        modalComponent
          .find('.modal-error')
          .html(error.responseText.substring(0, 300))
          .show();
      },
    });
  };

  handleVoidButton = e => {
    const { url, id: loanAdjustmentId } = e.target.dataset;

    const modalComponent = $(
      `#void-position-adjustments-modal-${loanAdjustmentId}`,
    );
    $.ajax({
      url,
      method: 'PUT',
      success: result => {
        modalComponent.modal('hide');
        this.getButton('void', loanAdjustmentId).setAttribute('disabled', true);
        this.fillGeneratedAt(result, loanAdjustmentId);
      },
      error: error => {
        modalComponent
          .find('.modal-error')
          .html(error.responseText.substring(0, 300))
          .show();
      },
    });
  };

  getButton = (action, loanAdjustmentId) =>
    this.element.querySelector(
      `[data-target="#${action}-position-adjustments-modal-${loanAdjustmentId}"]`,
    );

  fillGeneratedAt = (result, loanAdjustmentId) => {
    const parentTd = this.getButton('void', loanAdjustmentId).parentElement
      .previousElementSibling;
    let generatedAt = 'N/A';
    if (result.data?.created_at) {
      let createdAt = new Date(result.data.created_at.split('T')[0]);
      let options = { year: 'numeric', month: 'short', day: 'numeric' };
      generatedAt = createdAt.toLocaleDateString('en-US', options);
    }
    parentTd.innerHTML = generatedAt;
  };
}
