import { Controller } from 'stimulus';

import { handleInfo, handleError } from '@ftf/lib/alerts';
export default class LoansTable extends Controller {
  static targets = ['editable'];

  connect() {
    this.editableTargets.forEach(element => {
      element.addEventListener('click', this.handleSpreadChange, false);
    });
  }

  disconnect() {
    this.editableTargets.forEach(element => {
      element.removeEventListener('click', this.handleSpreadChange, false);
    });
  }

  handleSpreadChange = e => {
    e.preventDefault();
    const { loanId, poolId } = e.target.dataset;

    // create the input
    const editor = document.createElement('input');
    editor.value = e.target.innerText;
    editor.name = 'spread';
    editor.type = 'number';
    editor.classList.add('purchase-pool-loans-table__input');

    e.target.insertAdjacentElement('afterend', editor);
    e.target.classList.add('purchase-pool-loans-table__editable-hidden');

    // submit when entered is pressed
    editor.addEventListener('keydown', event => {
      const key = event.which;

      if (key === 13) {
        this.handleSubmit(e, loanId, poolId, editor);
      }

      if (key === 27) {
        editor.classList.add('purchase-pool-loans-table__editable-hidden');
        e.target.classList.remove('purchase-pool-loans-table__editable-hidden');
      }
    });
  };

  handleSubmit = (e, loanId, poolId, editor) => {
    e.target.innerText = parseFloat(editor.value).toFixed(1) + '%';
    e.target.classList.remove('purchase-pool-loans-table__editable-hidden');
    editor.remove();

    $.ajax({
      url: `/admin/treasury/purchase_pools/${poolId}/loans/${loanId}`,
      method: 'PATCH',
      data: { flexible_spread_fee_percentage: editor.value },
      success: result => {
        if (result.id) {
          handleInfo(`Spread fee updated to ${result.id}%.`, {
            type: 'success',
            autoDismissible: true,
          });
        } else {
          handleError(result.error, {
            type: 'danger',
            autoDismissible: true,
          });
        }
      },
    });
  };
}
