import { Controller } from 'stimulus';
import flatpickr from 'flatpickr';
import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';

import { ImagesControl } from '@ftf/components/ImagesControl';
import { handleAJAXError, handleInfo } from '@ftf/lib/alerts';

export default class SeriesNoteOfferingForm extends Controller {
  static targets = [
    'createOffering',
    'currentStatus',
    'field',
    'maturityDateField',
    'openDateField',
    'picturesContainer',
    'radixId',
    'statusField',
    'submit',
  ];

  flatpickrInstances = [];

  pictures = [];

  get dateInputs() {
    return [this.openDateFieldTarget, this.maturityDateFieldTarget];
  }

  connect() {
    this.statusFieldTarget.addEventListener(
      'change',
      this.handleStatusChange,
      false,
    );

    this.element.addEventListener('submit', this.handleSubmit);

    this.dateInputs.forEach((dateInput, index) => {
      this.flatpickrInstances[index] = flatpickr(dateInput, {
        minDate: 'today',
        allowInput: true,
      });
    });

    $(this.createOfferingTarget).on({
      'ajax:success': this.handleCreateOfferingSuccess,
      'ajax:error': handleAJAXError,
    });

    this.pictures = JSON.parse(this.picturesContainerTarget.dataset.pictures);

    this.renderImagesControl();
  }

  disconnect() {
    this.statusFieldTarget.removeEventListener(
      'change',
      this.handleStatusChange,
      false,
    );

    this.element.removeEventListener('submit', this.handleSubmit);

    this.flatpickrInstances.forEach(instance => {
      instance.destroy();
    });

    $(this.createOfferingTarget).off({
      'ajax:success': this.handleCreateOfferingSuccess,
      'ajax:error': handleAJAXError,
    });

    unmountComponentAtNode(this.picturesContainerTarget);
  }

  get shouldShowConfirmation() {
    const currentStatus = this.currentStatusTarget.value;
    const status = this.statusFieldTarget.value;

    return currentStatus === 'submitted' && status === 'open_for_investment';
  }

  handleStatusChange = e => {
    const { value } = e.currentTarget;

    this.fieldTargets.forEach(field => {
      field.disabled = value !== 'submitted';
    });
  };

  handleSubmit = e => {
    if (!this.shouldShowConfirmation) {
      return true;
    }

    const message =
      'Once this Series is open for investment, ' +
      'all values except status and maximum investment amount ' +
      'become locked and cannot be changed, do you wish to continue?';

    if (confirm(message)) {
      return true;
    }

    e.preventDefault();

    setTimeout(() => (this.submitTarget.disabled = false), 200);

    return false;
  };

  handleCreateOfferingSuccess = (e, data) => {
    this.radixIdTarget.value = data.radix_id;
    this.radixIdTarget.disabled = true;

    this.createOfferingTarget.classList.add('disabled');

    handleInfo('Offering has been sucessfully created in Radix', {
      type: 'success',
      autoDismissible: true,
    });
  };

  handleDropAccepted = acceptedFiles => {
    const files = acceptedFiles.map(file => ({
      id: file.id,
      preview: file.preview,
      signed_id: file.signed_id,
    }));

    this.pictures = [...this.pictures, ...files];

    this.renderImagesControl();
  };

  handleSortEnd = sortedFiles => {
    this.pictures = sortedFiles;

    this.renderImagesControl();
  };

  handleDelete = id => {
    this.pictures = this.pictures.filter(image => image.id !== id);

    this.renderImagesControl();
  };

  renderImagesControl = () => {
    render(
      <ImagesControl
        images={this.pictures}
        name="series_note_offering[pictures_attributes]"
        onDropAccepted={this.handleDropAccepted}
        onSortEnd={this.handleSortEnd}
        onDelete={this.handleDelete}
      />,
      this.picturesContainerTarget,
    );
  };
}
