import { Controller } from 'stimulus';

import { formatNumbersAfterTyped, formatNumbers } from '@ftf-old/main/helpers';

export default class NewAnalysisVersionModal extends Controller {
  static targets = ['form'];

  connect() {
    formatNumbers();
    formatNumbersAfterTyped();

    $(this.formTarget).validator();
  }

  disconnect() {
    $(this.formTarget).validator('destroy');
  }
}
