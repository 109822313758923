import { Controller } from 'stimulus';
import { debounce, isNil } from 'lodash';

import { handleInfo, handleAJAXError } from '@ftf/lib/alerts';
import { isValidPhone } from '@ftf-old/util/helpers';

export default class TwoFactorAuthSettings extends Controller {
  static targets = [
    'checkbox',
    'field',
    'phoneNumberControl',
    'smsEnabledControl',
  ];

  controlsState = {};

  initialize() {
    this.debouncedHandlePhoneNumberInput = debounce(
      this.handlePhoneNumberInput,
      300,
    );
  }

  connect() {
    $(this.phoneNumberControlTarget).bfhphone(
      this.phoneNumberControlTarget.dataset,
    );

    this.checkboxTargets.forEach(el => {
      el.addEventListener('change', this.handleCheckboxChange, false);
    });

    this.phoneNumberControlTarget.addEventListener(
      'input',
      this.debouncedHandlePhoneNumberInput,
      false,
    );

    window.addEventListener(
      'TwoFactorAuthModal:verified',
      this.handleVerificationSuccess,
    );

    window.addEventListener(
      'TwoFactorAuthModal:unverified',
      this.handleVerificationError,
    );
  }

  disconnect() {
    this.checkboxTargets.forEach(el => {
      el.removeEventListener('change', this.handleCheckboxChange, false);
    });

    this.phoneNumberControlTarget.removeEventListener(
      'input',
      this.debouncedHandlePhoneNumberInput,
      false,
    );

    window.removeEventListener(
      'TwoFactorAuthModal:verified',
      this.handleVerificationSuccess,
    );
  }

  submit = (data = {}) => {
    const { url, method } = this.element.dataset;

    $.ajax({
      url,
      method,
      data,
      beforeSend: () => {
        this.fieldTargets.forEach(el => {
          this.controlsState[el.name] = el.disabled;

          el.disabled = true;
        });
      },
    })
      .done(this.handleSubmitSuccess)
      .fail(handleAJAXError)
      .always(() => {
        this.fieldTargets.forEach(el => {
          el.disabled = this.controlsState[el.name];
        });
      });
  };

  handleSubmitSuccess = data => {
    if (isNil(data.two_factor_auth)) {
      handleInfo('Two Factor Authentication settings updated', {
        type: 'success',
        autoDismissible: true,
      });

      return;
    }

    window.dispatchEvent(
      new CustomEvent('TwoFactorAuthModal:show', {
        detail: { ...data.two_factor_auth, isPersistent: true, step: 'verify' },
      }),
    );
  };

  handleCheckboxChange = e => {
    const { name, checked } = e.target;
    const data = { [name]: checked ? '1' : '0' };

    this.submit(data);
  };

  handlePhoneNumberInput = e => {
    const { name, value } = e.target;

    const isValid = isValidPhone(value);

    $(e.target)
      .closest('.form-group')
      .toggleClass('has-error', !isValid);

    this.smsEnabledControlTarget.disabled = !isValid;

    if (!isValid) {
      return;
    }

    const data = { [name]: value };

    const { name: smsEnabledName, checked } = this.smsEnabledControlTarget;

    if (checked) {
      this.smsEnabledControlTarget.checked = false;

      data[smsEnabledName] = '0';
    }

    this.submit(data);
  };

  handleVerificationSuccess = () => {
    this.smsEnabledControlTarget.checked = true;

    handleInfo('Two Factor Authentication settings updated', {
      type: 'success',
      autoDismissible: true,
    });
  };

  handleVerificationError = () => {
    this.smsEnabledControlTarget.checked = false;
  };
}
