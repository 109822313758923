import { Controller } from 'stimulus';

export default class PodManagementMemberActions extends Controller {
  static targets = ['userId', 'podId'];

  addFields() {
    let form = document.getElementById('pod-move-form');
    let userList = document.getElementById('user-list');

    this.userIdTargets.forEach(function(ele) {
      if (ele.checked) {
        var input = document.createElement('input');
        var text = document.createTextNode(ele.dataset.fullName + ' - ');
        input.setAttribute('id', 'move-user-id');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', 'users[]');
        input.setAttribute('value', ele.value);
        form.appendChild(input);
        userList.appendChild(text);
      }
    });
  }

  removeHiddenFields() {
    let hiddenFields = document.querySelectorAll('#move-user-id');
    hiddenFields.forEach(function(ele) {
      ele.remove();
    });
  }

  selectRemovePodId() {
    this.removeHiddenFields();

    let form = document.getElementById('pod-move-form');
    let podInput = document.createElement('input');
    let value = this.podIdTarget.options[this.podIdTarget.selectedIndex].value;
    podInput.setAttribute('id', 'move-user-id');
    podInput.setAttribute('type', 'hidden');
    podInput.setAttribute('name', 'remove_pod_id');
    podInput.setAttribute('value', value.toString());
    form.appendChild(podInput);
  }
}
