import LoanApplicationFormStep from '../loan_application_form_step/loan_application_form_step';

export default class FormStepUserExperience extends LoanApplicationFormStep {
  static targets = ['statesField'];

  previousStep = 'project';
  nextStep = '';

  initialize() {
    if (this.element.dataset.newUser === 'true') {
      this.nextStep = 'account';
      this.newUser = true;
    } else {
      this.nextStep = '';
      this.newUser = false;
    }
  }

  connect() {
    super.connect();
    $.fn.selectpicker.Constructor.BootstrapVersion = '4';
    $(this.statesFieldTarget).selectpicker({
      liveSearch: true,
      liveSearchPlaceholder: 'Search a state',
      style: 'outline-none shadow-none',
    });
  }
}
