import { Controller } from 'stimulus';
import pluralize from 'pluralize';

export default class EmailForm extends Controller {
  static targets = ['input', 'submit', 'recipients'];

  connect() {
    $(this.element).validator();

    this.inputTarget.addEventListener(
      'quicksearch:selected',
      this.addRecipients,
      false,
    );

    this.recipientsTarget.addEventListener('blur', this.handleBlur, false);
  }

  disconnect() {
    $(this.element).validator('destroy');

    this.inputTarget.removeEventListener(
      'quicksearch:selected',
      this.addRecipients,
      false,
    );

    this.recipientsTarget.removeEventListener('blur', this.handleBlur, false);
  }

  addRecipients = e => {
    const email = e.detail.suggestion['email'];

    let recipientsArray = this.recipientsTarget.value
      .split(', ')
      .filter(String);

    if (!recipientsArray.includes(email)) {
      recipientsArray.push(email);
    }

    this.recipientsTarget.value = recipientsArray.join(', ');

    this.submitTarget.innerText = `Send Email to ${
      recipientsArray.length
    } Recipient${recipientsArray.length > 1 ? 's' : ''}`;

    this.submitTarget.innerText = `Send Email to ${pluralize(
      'Recipient',
      recipientsArray.length,
      true,
    )}`;

    $(this.element).validator('validate');
  };

  handleBlur = e => {
    const recipientsArray = e.target.value.split(', ').filter(String);

    this.submitTarget.innerText = `Send Email to ${pluralize(
      'Recipient',
      recipientsArray.length,
      true,
    )}`;
  };
}
