import { Controller } from 'stimulus';
import marked from 'marked';

export default class MarkdownEditor extends Controller {
  static targets = ['tab', 'tabContent', 'textarea', 'preview'];

  connect() {
    this.tabTargets.forEach(element => {
      element.addEventListener('click', this.handleTabClick, false);
    });

    if (this.textareaTarget.value.length === 0) {
      this.textareaTarget.innerHTML = '## Note Title';
    }

    this.textareaTarget.addEventListener(
      'keydown',
      this.handleTextareaKeydown,
      false,
    );
  }

  disconnect() {
    this.tabTargets.forEach(element => {
      element.removeEventListener('click', this.handleTabClick, false);
    });

    this.textareaTarget.removeEventListener(
      'keydown',
      this.handleTextareaKeydown,
      false,
    );
  }

  toggleMarkdownPreview = () => {
    try {
      const html = marked(this.textareaTarget.value);

      this.previewTarget.innerHTML = html;
    } catch (error) {
      window.dispatchEvent(
        new CustomEvent('Alerts:add', {
          detail: {
            message: 'Markdown parsing error',
            type: 'danger',
            autoDismissible: true,
          },
        }),
      );
    }
  };

  handleTabClick = e => {
    const { name } = e.target.dataset;

    this.tabTargets.forEach(element => {
      element.classList.toggle('is-active', element.dataset.name === name);
    });

    this.tabContentTargets.forEach(element => {
      element.classList.toggle('is-active', element.dataset.name === name);
    });

    if (name === 'preview') {
      this.toggleMarkdownPreview();
    }
  };

  handleTextareaKeydown = () => {
    this.textareaTarget.style.height = 'auto';
    this.textareaTarget.style.height = `${this.textareaTarget.scrollHeight}px`;
  };
}
