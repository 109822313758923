import { Controller } from 'stimulus';

const SELECTORS = [
  '#project-photo',
  '#project-updates',
  '#investment-summary',
  '#use-of-proceeds',
  '#loan-to-cost',
  '#valuation',
  '#about-the-property',
  '#project-strategy',
  '#market-overview',
  '#about-the-redeveloper',
  '#previous-projects',
  '#risk',
];

export default class DealShowNavbar extends Controller {
  static targets = [];

  connect() {
    // disable nav item if it doesn't exist on the deal
    SELECTORS.forEach(selector => {
      const section = document.querySelector(selector);
      if (section === null) {
        let sectionNavLi = document.getElementsByClassName(
          `nav-li-label ${selector.slice(1)}`,
        )[0];
        sectionNavLi.classList.add('unavailable-link');
      }
    });

    window.addEventListener('scroll', this.onScroll);
  }

  disconnect() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll() {
    const sections = document.querySelectorAll(SELECTORS.join(', '));
    const navLi = document.querySelectorAll('nav ul li');
    const scrollPosition = window.scrollY;
    let current = '';

    sections.forEach(section => {
      const sectionDimensions = section.getBoundingClientRect();
      const offsetTop = section.offsetTop - 25;
      const offsetBottom = offsetTop + sectionDimensions.height + 25;
      if (scrollPosition >= offsetTop && scrollPosition <= offsetBottom) {
        current = section.getAttribute('id');
      }
    });

    navLi.forEach(li => {
      li.classList.remove('active');
      if (li.classList.contains(current)) {
        li.classList.add('active');
      }
    });
  }
}
