import { Controller } from 'stimulus';

export default class NewTransactionModal extends Controller {
  static targets = ['form'];

  connect() {
    $(this.formTarget).validator();

    $(this.formTarget).on('ajax:success', this.handleSuccess);
  }

  disconnect() {
    $(this.formTarget).validator('destroy');

    $(this.formTarget).off('ajax:success', this.handleSuccess);
  }

  handleSuccess = () => {
    $(this.element).modal('hide');

    window.location.reload();
  };
}
