import { Controller } from 'stimulus';
import React from 'react';
import { render } from 'react-dom';

import { ImagesControl } from '@ftf/components/ImagesControl';

export default class DealPictureableForm extends Controller {
  static targets = ['thumbnailAssetField', 'carouselContainer'];

  pictures = [];

  connect() {
    this.pictures = JSON.parse(this.carouselContainerTarget.dataset.pictures);

    window.addEventListener(
      'PictureCropper:BlobUploaded',
      this.updateThumbnail,
      false,
    );

    this.renderCarousel();
  }

  disconnect() {
    window.removeEventListener(
      'PictureCropper:BlobUploaded',
      this.updateThumbnail,
      false,
    );
  }

  updateThumbnail = e => {
    const blob = e.detail;

    this.thumbnailAssetFieldTarget.disabled = false;
    this.thumbnailAssetFieldTarget.value = blob.signed_id;
  };

  handleDropAccepted = acceptedFiles => {
    const files = acceptedFiles.map(file => ({
      id: file.id,
      preview: file.preview,
      signed_id: file.signed_id,
    }));

    this.pictures = [...this.pictures, ...files];

    this.renderCarousel();
  };

  handleSortEnd = sortedFiles => {
    this.pictures = sortedFiles;

    this.renderCarousel();
  };

  handleDelete = id => {
    this.pictures = this.pictures.filter(image => image.id !== id);

    this.renderCarousel();
  };

  renderCarousel = () => {
    render(
      <ImagesControl
        images={this.pictures}
        name="pictureable[pictures_attributes]"
        onDropAccepted={this.handleDropAccepted}
        onSortEnd={this.handleSortEnd}
        onDelete={this.handleDelete}
      />,
      this.carouselContainerTarget,
    );
  };
}
