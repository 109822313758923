import { Controller } from 'stimulus';
import { debounce } from 'lodash';

import checkIsMobile from '@ftf-old/util/checkIsMobile';

// NOTE: Please remove all dynamic routing logic on buttons
// and use static links, since the redirect logic is being moved
// to the pages themselves.
export default class UprightHomeCards extends Controller {
  static cards = window?.gon?.homePageData?.cards ?? [];

  static targets = UprightHomeCards.cards
    .map(({ type }) => [
      `${type}CardText`,
      `${type}Btn`,
      `${type}BtnCard`,
      `${type}Icon`,
      `${type}IconAlt`,
      `${type}IconMobile`,
      `${type}IconMobileAlt`,
    ])
    .flat();

  initialize = () => {
    // only happens on local dev => might find a way to make this synchronous
    if (
      !window.gon?.homePageData ||
      typeof this.borrowerBtnTarget === 'undefined'
    )
      window.location.reload();

    this.cards = UprightHomeCards.cards;
    // this is a hack to get around the fact that I have no idea how to get the style guide
    // into the stimulus controller, other than importing the json file directly
    // which I think would maybe break the build?(allthough it did work locally...)
    this.styleGuide = {
      'color-upright-teal': '#63CDD4',
      'color-orange-alt': '#E87146',
    };

    this.getAccountType();
    this.getEnvDomain();
  };

  connect() {
    if (typeof this.borrowerBtnTarget === 'undefined') return;
    this.addEventListeners();
    this.updateCards();
  }

  disconnect() {
    this.removeEventListeners();
  }

  getAccountType = () => {
    const { borrower, lender } = window.gon.homePageData;
    this.borrower = borrower;
    this.lender = lender;
  };

  getEnvDomain = () => {
    const hostname = window.location.hostname;

    if (hostname.includes('dev.notprod.live')) {
      this.envDomain = 'app.upright.dev.notprod.live';
      this.investEnvDomain = 'invest.app.upright.dev.notprod.live';
    }

    if (hostname.includes('local')) {
      this.envDomain = 'local.dev.notprod.live';
      this.investEnvDomain = 'local.dev.notprod.live';
    }

    if (hostname.includes('stg.notprod.live')) {
      this.envDomain = 'app.upright.stg.notprod.live';
      this.investEnvDomain = 'invest.app.upright.stg.notprod.live';
    }

    if (hostname.includes('upright.us')) {
      this.envDomain = 'app.upright.us';
      this.investEnvDomain = 'invest.app.upright.us';
    }

    this.investSubdomain = this.getAppdomain('invest');
    this.loansSubdomain = this.getAppdomain('loans');
  };

  addEventListeners = () => {
    this.borrowerBtnTarget.addEventListener('click', this.borrowerBtnCallback);
    this.lenderBtnTarget.addEventListener('click', this.lenderBtnCallback);

    this.borrowerBtnCardTarget.addEventListener(
      'click',
      this.borrowerBtnCallback,
    );
    this.lenderBtnCardTarget.addEventListener('click', this.lenderBtnCallback);

    window.addEventListener('resize', this.debounceUpdateCards);
  };

  removeEventListeners = () => {
    this.borrowerBtnTarget.removeEventListener(
      'click',
      this.borrowerBtnCallback,
    );
    this.lenderBtnTarget.removeEventListener('click', this.lenderBtnCallback);

    this.borrowerBtnCardTarget.removeEventListener(
      'click',
      this.borrowerBtnCallback,
    );
    this.lenderBtnCardTarget.removeEventListener(
      'click',
      this.lenderBtnCallback,
    );

    window.removeEventListener('resize', this.debounceUpdateCards);
  };

  updateCards = () => {
    if (checkIsMobile()) return this.updateCardsMobile();

    const updateCardHelper = (
      card,
      btn,
      image,
      altImage,
      cardTextContainer,
      mobileAltImage,
    ) => {
      card.style.backgroundColor = this.styleGuide['color-upright-teal'];
      btn.style.backgroundColor = this.styleGuide['color-orange-alt'];

      image.style.display = 'none';
      altImage.style.display = 'block';

      const cardTextElements = Array.from(cardTextContainer.children);
      cardTextElements.forEach(el => (el.style.color = 'black'));

      mobileAltImage.style.display = 'none';
    };

    this.cards.forEach(({ type }) => {
      if (this[type]) {
        updateCardHelper(
          this[`${type}BtnCardTarget`],
          this[`${type}BtnTarget`],
          this[`${type}IconTarget`],
          this[`${type}IconAltTarget`],
          this[`${type}CardTextTarget`],
          this[`${type}IconMobileAltTarget`],
        );
      }
    });

    // linter wants a return statement
    // because the mobile version returns early
    // and wants to be consistent or whatever
    return null;
  };

  updateCardsMobile = () => {
    const updateCardHelper = (
      card,
      image,
      altImage,
      cardTextContainer,
      desktopAltImage,
    ) => {
      const cardStyle = card.style;
      cardStyle.backgroundColor = 'white';

      const cardTextElements = Array.from(cardTextContainer.children);
      cardTextElements.forEach(
        el => (el.style.color = this.styleGuide['color-upright-teal']),
      );

      image.style.display = 'none';
      altImage.style.display = 'block';
      desktopAltImage.style.display = 'none';
    };

    this.cards.forEach(({ type }) => {
      if (this[type]) {
        updateCardHelper(
          this[`${type}BtnCardTarget`],
          this[`${type}IconMobileTarget`],
          this[`${type}IconMobileAltTarget`],
          this[`${type}CardTextTarget`],
          this[`${type}IconAltTarget`],
        );
      }
    });
  };

  debounceUpdateCards = debounce(this.updateCards, 100);

  getAppSubpath = type => (type === 'loans' ? '/loans/apply' : '');

  getAppSubdomain = type => (type ? `${type}.` : '');

  getAppdomain = type => {
    if (this.envDomain.includes('local')) {
      return `https://${this.envDomain}${this.getAppSubpath(type)}`;
    }
    if (type === 'loans' && window.gon?.homePageData?.showOldBorrowerPortal) {
      const userId = window.gon?.homePageData?.userId;
      return `https://${this.investEnvDomain}/dashboard/${userId}/borrower`;
    }
    const appSubdomain = this.getAppSubdomain(type);
    return `https://${appSubdomain}${this.envDomain}`;
  };

  borrowerBtnCallback = e => {
    if (!this.isClickable(e)) return;

    const existingBorrowerPath = `${this.loansSubdomain}`;
    const newBorrowerPath = `${this.loansSubdomain}`;

    if (this.borrower) window.location.href = existingBorrowerPath;
    else window.location.href = newBorrowerPath;
  };

  lenderBtnCallback = e => {
    if (!this.isClickable(e)) return;
    window.location.href = `${this.investSubdomain}/dashboard/lender`;
  };

  // when mobile the card shrinks and the buttons are hidden
  // and the card becomes clickable in place of the buttons.
  isClickable = e => {
    const isClickable = getComputedStyle(e?.target)?.cursor === 'pointer';
    return !!isClickable;
  };
}
