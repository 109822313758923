import { Controller } from 'stimulus';
export default class DetailsSummary extends Controller {
  static targets = ['details', 'arrow'];

  initialize() {
    let details = document.querySelectorAll('.accordian');

    details.forEach(function(ele) {
      ele.open = true;
    });
  }

  toggleSummary() {
    let target = this.detailsTarget;
    let arrow = this.arrowTarget;

    if (target.open === true) {
      arrow.textContent = '▸';
    } else {
      arrow.textContent = '▾';
    }
  }
}
