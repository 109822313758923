import { Controller } from 'stimulus';

import { validators } from '@ftf-old/main/helpers';

export default class AccreditationModal extends Controller {
  static targets = ['form'];

  $phone = undefined;
  $countries = undefined;

  connect() {
    $(this.formTarget).validator({
      custom: {
        phone: validators.validations.phone,
      },
      errors: {
        phone: validators.errors.phone,
      },
    });

    this.$countries = $(this.formTarget).find('.bfh-countries');
    this.$countries.bfhcountries({ country: 'US' });

    this.$phone = $(this.formTarget).find('.bfh-phone');
    this.$phone.bfhphone(this.$phone.data());
  }

  disconnect() {
    $(this.formTarget).validator('destroy');

    this.$countries.find('.bfh-countries').removeData('bfhcountries');

    this.$phone.find('.bfh-phone').removeData('bfhphone');
  }
}
