import { Controller } from 'stimulus';
import { debounce } from 'lodash';

import { formatCurrency, formatNumberToPercentage } from '@ftf/lib/formatters';

export default class AnalysisVersionCalculations extends Controller {
  static targets = ['calcField', 'calcResult'];

  initialize() {
    this.handleCalcFieldInput = debounce(this.calculate, 300);
  }

  connect() {
    this.calcFieldTargets.forEach(el => {
      el.addEventListener('input', this.handleCalcFieldInput);
    });

    this.calculate();
  }

  disconnect() {
    this.calcFieldTargets.forEach(el => {
      el.removeEventListener('input', this.handleCalcFieldInput);
    });
  }

  calculate = () => {
    const data = {};
    this.calcFieldTargets.forEach(target => {
      data[target.dataset.name] = target.value;
    });

    if (data.flexible_as_is_appraised_value === '') {
      data.flexible_as_is_appraised_value = '0';
    }

    $.ajax({
      url: '/admin/underwritable/calculations.json',
      method: 'POST',
      data: { underwritable_calculator_input: data },
      success: ({ result }) => {
        this.fillCalcResult(result);
      },
    });
  };

  fillCalcResult = result => {
    Object.keys(result).forEach(key => {
      const resultElements = this.calcResultTargets.filter(
        el =>
          el.dataset.name === key ||
          (key === 'variance_analysis_matrix' && el.dataset.name.includes(key)),
      );

      resultElements.forEach(el => {
        let text;

        switch (el.dataset.format) {
          case 'currency':
            text = formatCurrency(result[key]);
            break;

          case 'percentage':
            text = formatNumberToPercentage(result[key], false);
            break;

          default:
            text = result[key];
            break;
        }

        // set values for variance analysis matrix
        if (el.dataset.name.includes('variance_analysis_matrix')) {
          const target = el.dataset.name.split('.')[1];
          const value = result[key][target];
          if (Number(value) < 0) el.classList.add('text-danger');
          text = formatCurrency(value);
        }

        el.innerText = text;
      });
    });
  };
}
