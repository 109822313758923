import { Controller } from 'stimulus';

import {
  formatNumbersAfterTyped,
  formatNumbers,
  validators,
} from '@ftf-old/main/helpers';
import { parseDollars } from '@ftf-old/util/Formatter';
import { formatCurrency } from '@ftf/lib/formatters';
import { handleAJAXError } from '@ftf/lib/alerts';
import axios from '@ftf/lib/axios';

export default class RolloverSetupModal extends Controller {
  static targets = [
    'form',
    'detail',
    'redemptionAmount',
    'rolloverAmountField',
    'radioButton',
  ];

  connect() {
    formatNumbers();
    formatNumbersAfterTyped();

    this.initValidator();

    $(this.formTarget).on({
      'ajax:success': this.handleFormSubmitSuccess,
      'ajax:error': this.handleFormSubmitError,
    });

    this.radioButtonTargets.forEach(elem => {
      elem.addEventListener('change', this.handleRadioButtonChange, false);
    });

    this.rolloverAmountFieldTarget.addEventListener(
      'input',
      this.handleRolloverAmountInput,
      false,
    );
  }

  disconnect() {
    $(this.formTarget).validator('destroy');

    $(this.formTarget).off({
      'ajax:success': this.handleFormSubmitSuccess,
      'ajax:error': handleAJAXError,
    });

    this.radioButtonTargets.forEach(elem => {
      elem.removeEventListener('change', this.handleRadioButtonChange, false);
    });

    this.rolloverAmountFieldTarget.removeEventListener(
      'input',
      this.handleRolloverAmountInput,
      false,
    );
  }

  initValidator = () => {
    if ($(this.formTarget).data('bs.validator')) {
      $(this.formTarget).validator('destroy');
    }

    $(this.formTarget).validator({
      custom: {
        maxnum: validators.validations.maxnum,
        minnum: validators.validations.minnum,
      },
      errors: {
        maxnum: `Must be less than ${formatCurrency(
          this.formTarget.dataset.amountInvested,
        )}.`,
        minnum: `Must be at least ${formatCurrency(
          this.rolloverAmountFieldTarget.dataset.minnum,
        )}.`,
      },
    });
  };

  handleFormSubmitSuccess = async (e, data) => {
    const { userId, investmentId } = this.element.dataset;

    $(this.element).modal('hide');

    const url = `/dashboard/${userId}/lender/investments/${investmentId}/rollover_subscriptions/${data.id}/rollover_confirmations/new`;

    const {
      data: { content },
    } = await axios.get(url);

    document.body.insertAdjacentHTML('beforeend', content);
  };

  handleFormSubmitError = (e, jqXHR) => {
    $(this.element).modal('hide');

    handleAJAXError(e, jqXHR);
  };

  handleRadioButtonChange = e => {
    const offering = JSON.parse(e.currentTarget.dataset.offering);

    this.detailTargets.forEach(elem => {
      const { name } = elem.dataset;

      elem.innerText = offering[name];
    });

    this.rolloverAmountFieldTarget.dataset.minnum =
      offering.min_investment_amount;

    this.initValidator();
  };

  handleRolloverAmountInput = e => {
    const { amountInvested } = this.formTarget.dataset;

    const redemptionAmount =
      parseDollars(amountInvested) - parseDollars(e.target.value);

    this.redemptionAmountTarget.innerText = formatCurrency(redemptionAmount);
  };
}
