import { Controller } from 'stimulus';
import useValidation from '@lysyi3m/stimulus-use-validation';

export default class ValidatedForm extends Controller {
  connect() {
    const {
      errorClassName,
      errorSelector,
      disable = false,
    } = this.element.dataset;

    useValidation(this, {
      errorClassName,
      errorSelector,
      disable,
    });
  }
}
