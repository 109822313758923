const linkToDealDocs = [
  'sow_for_loan_docs_and_deal_card',
  'appraisal_arv',
  'appraisal_aiv',
  'appraisal_land',
  'appraisal_arv_aiv',
  'appraisal_arv_land',
  'appraisal_desktop_review',
  'additional_marketing_materials',
];

const expirationDateLabels = [
  'appraisal_aiv',
  'appraisal_arv',
  'appraisal_land',
  'appraisal_arv_aiv',
  'appraisal_arv_land',
  'appraisal_desktop_review',
  'asset_documentation',
  'background_check',
  'cash_needs_calculator',
  'certificate_of_good_standing',
  'closing_protection_letter',
  'credit_report',
  'ftf_internal_valuation_aiv',
  'ftf_internal_valuation_arv',
  'ftf_internal_valuation_land',
  'ftf_internal_valuation_arv_aiv',
  'ftf_internal_valuation_arv_land',
  'loan_payment_status_report',
  'payoff_letter_external_lender',
  'payoff_letter_ftf',
  'title_commitment',
  'credit_report_equifax',
  'credit_report_experian',
  'ofac_search_borrowing_entity',
  'ofac_search_sub_entity',
  'ofac_search_individuals',
  'partial_payoff_fop',
  'partial_payoff_retail',
  'final_payoff_fop',
  'final_payoff_retail',
  'sos_entity_search',
  'credit_report_tri_merge',
];

export const LINK_TO_DEAL_DOCS = linkToDealDocs;
export const EXP_DATE_LABELS = expirationDateLabels;
