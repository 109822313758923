import { Controller } from 'stimulus';
import { debounce } from 'lodash';

import checkIsMobile from '@ftf-old/util/checkIsMobile';

export default class SvgSlider extends Controller {
  static targets = [
    'indicators',
    'nextButton',
    'prevButton',
    'images',
    'image',
  ];

  imagesTotal = 0;

  activeImageIndex = 0;

  gap = 100;

  nonMobileImgSrcs = this.imageTargets.map(img => img.src);
  mobileImgSrcs = this.imageTargets.map(img =>
    img.src.replace('.svg', '-mobile.svg'),
  );

  initialize() {
    this.debouncedHandleResize = debounce(this.handleResize, 300);
  }

  connect() {
    this.imagesTotal = this.imagesTarget.childElementCount;

    this.populateIndicators();

    this.nextButtonTarget.addEventListener('click', this.showNextImage);
    this.prevButtonTarget.addEventListener('click', this.showPrevImage);
    this.toggleMobileImages();

    window.addEventListener('resize', this.debouncedHandleResize);
  }

  disconnect() {
    this.nextButtonTarget.removeEventListener('click', this.showNextImage);
    this.prevButtonTarget.removeEventListener('click', this.showPrevImage);
    window.removeEventListener('resize', this.debouncedHandleResize);
  }

  populateIndicators = () => {
    for (let index = 0; index < this.imagesTotal; index++) {
      const indicatorEl = document.createElement('span');

      indicatorEl.classList.add('images-slider__indicator');

      if (index === this.activeImageIndex) {
        indicatorEl.classList.add('is-active');
      }

      this.indicatorsTarget.appendChild(indicatorEl);
    }
  };

  updateIndicators = () => {
    const indicators = this.indicatorsTarget.querySelectorAll(
      '.images-slider__indicator',
    );

    indicators.forEach((indicator, index) => {
      indicator.classList.toggle('is-active', index === this.activeImageIndex);
    });
  };

  showImage = index => {
    const targetPosition = (this.imagesTarget.offsetWidth + this.gap) * index;

    this.imagesTarget.style.transform = `translateX(-${targetPosition}px)`;

    this.activeImageIndex = index;

    this.updateIndicators();
  };

  showNextImage = e => {
    e.preventDefault();

    const isInRange = this.activeImageIndex < this.imagesTotal - 1;
    const targetIndex = isInRange ? this.activeImageIndex + 1 : 0;

    this.showImage(targetIndex);
  };

  showPrevImage = e => {
    e.preventDefault();

    const isInRange = this.activeImageIndex > 0;
    const targetIndex = isInRange
      ? this.activeImageIndex - 1
      : this.imagesTotal - 1;

    this.showImage(targetIndex);
  };

  handleResize = () => {
    this.showImage(this.activeImageIndex);
    this.toggleMobileImages();
  };

  toggleMobileImages = () => {
    this.imageTargets.forEach((img, i) => {
      img.src = checkIsMobile()
        ? this.mobileImgSrcs[i]
        : this.nonMobileImgSrcs[i];
    });
  };
}
