import { Controller } from 'stimulus';

export default class OfferingFundingInfo extends Controller {
  static targets = ['funded', 'fundedAmount', 'pending', 'pendingAmount'];

  connect() {
    window.addEventListener(
      'InvestNow:onUpdatePendingInfo',
      this.updatePending,
    );

    window.addEventListener(
      'InvestNow:onFullyFunded',
      this.handeFullyFundedEvent,
    );
  }

  disconnect() {
    window.removeEventListener(
      'InvestNow:onUpdatePendingInfo',
      this.updatePending,
    );

    window.removeEventListener(
      'InvestNow:onFullyFunded',
      this.handeFullyFundedEvent,
    );
  }

  updatePending = ({ detail: { percentage, amount } }) => {
    this.pendingTarget.style.width = `${percentage}%`;
    this.pendingAmountTarget.innerText = `$${amount} investments pending`;
  };

  handeFullyFundedEvent = () => {
    const { fullAmount } = this.element.dataset;

    this.fundedTarget.style.width = '100%';
    this.fundedTarget.innerText = '100% Funded!';
    this.fundedAmountTarget.innerText = fullAmount;
  };
}
