import { Controller } from 'stimulus';
import { debounce } from 'lodash';

export default class CommentsSlider extends Controller {
  static targets = ['indicators', 'nextButton', 'prevButton', 'slides'];

  slidesTotal = 0;

  activeSlideIndex = 0;

  gap = 100;

  initialize() {
    this.debouncedHandleResize = debounce(this.handleResize, 300);
  }

  connect() {
    this.slidesTotal = this.slidesTarget.childElementCount;

    this.populateIndicators();

    this.nextButtonTarget.addEventListener('click', this.showNextSlide);
    this.prevButtonTarget.addEventListener('click', this.showPrevSlide);
    window.addEventListener('resize', this.debouncedHandleResize);
  }

  disconnect() {
    this.nextButtonTarget.removeEventListener('click', this.showNextSlide);
    this.prevButtonTarget.removeEventListener('click', this.showPrevSlide);
    window.removeEventListener('resize', this.debouncedHandleResize);
  }

  populateIndicators = () => {
    for (let index = 0; index < this.slidesTotal; index++) {
      const indicatorEl = document.createElement('span');

      indicatorEl.classList.add('comments-slider__indicator');

      if (index === this.activeSlideIndex) {
        indicatorEl.classList.add('is-active');
      }

      this.indicatorsTarget.appendChild(indicatorEl);
    }
  };

  updateIndicators = () => {
    const indicators = this.indicatorsTarget.querySelectorAll(
      '.comments-slider__indicator',
    );

    indicators.forEach((indicator, index) => {
      indicator.classList.toggle('is-active', index === this.activeSlideIndex);
    });
  };

  showSlide = index => {
    const targetPosition = (this.slidesTarget.offsetWidth + this.gap) * index;

    this.slidesTarget.style.transform = `translateX(-${targetPosition}px)`;

    this.activeSlideIndex = index;

    this.updateIndicators();
  };

  showNextSlide = e => {
    e.preventDefault();

    const isInRange = this.activeSlideIndex < this.slidesTotal - 1;
    const targetIndex = isInRange ? this.activeSlideIndex + 1 : 0;

    this.showSlide(targetIndex);
  };

  showPrevSlide = e => {
    e.preventDefault();

    const isInRange = this.activeSlideIndex > 0;
    const targetIndex = isInRange
      ? this.activeSlideIndex - 1
      : this.slidesTotal - 1;

    this.showSlide(targetIndex);
  };

  handleResize = () => {
    this.showSlide(this.activeSlideIndex);
  };
}
