import { Controller } from 'stimulus';
import useValidation from '@lysyi3m/stimulus-use-validation';

import { recaptchaValidator, phoneNumberValidator } from '@ftf/lib/validators';

export default class SignUpForm extends Controller {
  static targets = [
    'userRoleControl',
    'selfVerification',
    'selfVerificationControl',
    'selfVerificationInfo',
    'phone',
    'phoneDisclaimer',
  ];

  connect() {
    useValidation(this, {
      form: this.element,
      validators: {
        'g-recaptcha-response': recaptchaValidator,
        'user[phone_number]': phoneNumberValidator,
      },
    });

    $(this.phoneTarget).bfhphone(this.phoneTarget.dataset);

    this.userRoleControlTargets.forEach(element => {
      element.addEventListener(
        'change',
        this.handleUserRoleControlChange,
        false,
      );
    });

    window.addEventListener('recaptcha:success', this.validateForm);
    window.addEventListener('recaptcha:expired', this.validateForm);
    window.addEventListener('recaptcha:error', this.validateForm);
  }

  disconnect() {
    $(this.phoneTarget).removeData('bfhphone');

    this.userRoleControlTargets.forEach(element => {
      element.removeEventListener(
        'change',
        this.handleUserRoleControlChange,
        false,
      );
    });

    $(this.selfVerificationInfoTarget).popover('destroy');

    window.removeEventListener('recaptcha:success', this.validateForm);
    window.removeEventListener('recaptcha:expired', this.validateForm);
    window.removeEventListener('recaptcha:error', this.validateForm);
  }

  toggleSelfVerification = isVisible => {
    this.selfVerificationControlTarget.checked = false;
    this.selfVerificationTarget.classList.toggle('hidden', !isVisible);

    const content = `
      <p>(1) My net worth is over $1 million, excluding my home.</p>
      <p>(2) Personal income was more than $200K (or $300K if I filed jointly) for the past two years, and I expect the same income this year.</p>
      <p>(3) I invest on behalf of an accredited business or Venture Capital firm.</p>
    `;

    if (isVisible) {
      $(this.selfVerificationInfoTarget).popover({
        content: content,
        html: true,
        title:
          'Accredited investor qualifications (Meet any of following criteria)',
        placement: 'top',
        trigger: 'hover',
      });
    } else {
      $(this.selfVerificationInfoTarget).popover('destroy');
    }
  };

  togglePhoneDisclaimer = isVisible => {
    this.phoneDisclaimerTarget.classList.toggle('hidden', !isVisible);
  };

  handleUserRoleControlChange = e => {
    switch (e.target.value) {
      case '1':
      case '3':
        this.toggleSelfVerification(true);
        this.togglePhoneDisclaimer(true);
        break;

      default:
        this.toggleSelfVerification(false);
        this.togglePhoneDisclaimer(false);
        break;
    }
  };
}
