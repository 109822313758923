import { Controller } from 'stimulus';

export default class DistributionOfProceeds extends Controller {
  static targets = [
    'borrower',
    'signature',
    'loanDocs',
    'borrowerAttorney',
    'titleCompany',
  ];

  connect() {
    this.borrowerTarget.addEventListener(
      'input',
      this.handleBorrowerChange,
      false,
    );

    this.loanDocsTarget.addEventListener(
      'change',
      this.submitLoanDocsInProgress,
      false,
    );

    this.toggleExhibitA();
  }

  disconnect() {
    this.borrowerTarget.removeEventListener(
      'input',
      this.handleBorrowerChange,
      false,
    );

    this.loanDocs.removeEventListener(
      'change',
      this.submitLoanDocsInProgress,
      false,
    );
  }

  handleBorrowerChange = e => {
    this.signatureTarget.innerText = e.currentTarget.value;
  };

  submitLoanDocsInProgress = () => {
    $('#dist_of_proceeds_form').trigger('submit.rails');

    this.toggleExhibitA();
  };

  toggleExhibitA() {
    if (this.loanDocsTarget.checked === true) {
      this.disableExhibitA();
    } else {
      this.enableExhibitA();
    }
  }

  disableExhibitA() {
    this.borrowerAttorneyTarget.disabled = true;
    this.titleCompanyTarget.disabled = true;
  }

  enableExhibitA() {
    this.borrowerAttorneyTarget.disabled = false;
    this.titleCompanyTarget.disabled = false;
  }
}
