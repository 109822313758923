import { isValidPhone } from '@ftf-old/util/helpers';

export function originationDateValidator(field) {
  const { today, twoYears } = field.dataset;

  if (field.value <= today) {
    return {
      isValid: false,
      message: 'Please enter a future date',
    };
  }

  if (field.value > twoYears) {
    return {
      isValid: false,
      message: 'Please enter a date within 2 years from now',
    };
  }

  return { isValid: true, message: '' };
}

export function recaptchaValidator(field) {
  if (field.value.length === 0) {
    return {
      isValid: false,
      message: 'reCAPTCHA is required',
    };
  }

  return { isValid: true, message: '' };
}

export function phoneNumberValidator(field) {
  if (!isValidPhone(field.value)) {
    return {
      isValid: false,
      message: 'Valid Phone Number is required',
    };
  }

  return { isValid: true, message: '' };
}

export function textFieldGreaterThanZero(field) {
  if (Number(field.value) <= 0) {
    return {
      isValid: false,
      message: 'Must be greater than 0',
    };
  }

  return { isValid: true, message: '' };
}

export function enforcePrecisionTwo(field) {
  if (!field.value.match(/^[0-9,]+(\.[0-9]{1,2})?$/)) {
    return {
      isValid: false,
      message: 'Please round value to two decimal points.',
    };
  }

  return { isValid: true, message: '' };
}

export function enforceValueToAtLeastOne(field) {
  if (Number(field.value) < 1) {
    return {
      isValid: false,
      message: 'Cannot be smaller than 1.',
    };
  }
  return { isValid: true, message: '' };
}

export function enforcePrecisionTwoForOptionalField(field) {
  if (field.value) {
    return enforcePrecisionTwo(field);
  }
  return { isValid: true, message: '' };
}

export function payoffAmountValidators(field) {
  if (!field.value.match(/^[0-9,]+(\.[0-9]{1,2})?$/)) {
    return {
      isValid: false,
      message: 'Please round value to two decimal points.',
    };
  }

  if (Number(field.value) <= 0) {
    return {
      isValid: false,
      message: 'Must be greater than 0',
    };
  }

  return { isValid: true, message: '' };
}
