import { Controller } from 'stimulus';
import moment from 'moment';
import { isNil } from 'lodash';

import { formatSecondsToTime, formatCurrency } from '@ftf/lib/formatters';
import { trackGAEvent } from '@ftf-old/main/tracking';

import {
  operationStatuses,
  reserveStates,
  selfVerificationModalSuccess,
  InvestNowModal,
  beforeUnload,
  click,
  CountdownTimer,
  show,
  hide,
} from './statuses';
export default class InvestNowForm extends Controller {
  static targets = ['button', 'amount', 'message'];

  expirationForPending; // time till offering clears oldest reservation
  pendingReservationTimer;

  isUserSelfVerified;
  isUserHasActiveEntities;

  connect() {
    this.isUserSelfVerified = JSON.parse(
      this.buttonTarget.dataset.selfVerified,
    );
    this.isUserHasActiveEntities = JSON.parse(
      this.buttonTarget.dataset.hasActiveEntities,
    );

    window.addEventListener(beforeUnload, this.handlePageUnload);

    window.addEventListener(
      CountdownTimer.expired,
      this.handleReservationExpired,
    );

    window.addEventListener(
      InvestNowModal.onClose,
      this.handleCloseModalMessage,
    );

    window.addEventListener(InvestNowModal.onError, this.handleModalError);
    $('#investment-time-out-modal').on('hidden.bs.modal', this.reload);

    this.buttonTarget.addEventListener(click, this.handleSubmit);

    this.minimumInvestmentAmount = this.defaultMinimumInvestmentAmount;
    this.initialReservationCheck();

    window.addEventListener(
      selfVerificationModalSuccess,
      this.handleSelfVerificationModalSuccess,
    );
  }

  disconnect() {
    window.removeEventListener(beforeUnload, this.handlePageUnload);

    window.removeEventListener(
      CountdownTimer.expired,
      this.handleReservationExpired,
    );

    window.removeEventListener(
      InvestNowModal.onClose,
      this.handleCloseModalMessage,
    );

    window.removeEventListener(InvestNowModal.onError, this.handleModalError);
    $('#investment-time-out-modal').off('hidden.bs.modal');

    this.buttonTarget.removeEventListener(click, this.handleSubmit);

    window.removeEventListener(
      selfVerificationModalSuccess,
      this.handleSelfVerificationModalSuccess,
    );
  }

  get defaultMinimumInvestmentAmount() {
    const { minAmount } = this.amountTarget.dataset;

    return isNil(minAmount) ? undefined : Number(minAmount);
  }

  get maximumInvestmentAmount() {
    const { maxAmount } = this.amountTarget.dataset;

    return isNil(maxAmount) ? undefined : Number(maxAmount);
  }

  get investmentReserveUrl() {
    return this.element.dataset.investmentReserveUrl;
  }

  handlePageUnload = () => {
    this.releaseReservation(true);
  };

  handleModalError = ({ detail: message }) => {
    this.displayMessage(message);
    window.dispatchEvent(new Event(CountdownTimer.stop));
  };

  setMinimumInvestmentAmount = openForInvestment => {
    this.minimumInvestmentAmount =
      openForInvestment >= this.defaultMinimumInvestmentAmount
        ? this.defaultMinimumInvestmentAmount
        : 1000;

    this.amountTarget.setAttribute(
      'placeholder',
      `Minimum ${formatCurrency(this.minimumInvestmentAmount)}`,
    );
  };

  initialReservationCheck = () => {
    this.releaseReservation(false, response => {
      if (response.reserve_state === reserveStates.FULLY_FUNDED) {
        this.handleFullyFunded(response);
      } else if (
        response.reserve_state === reserveStates.PENDING_RESERVATIONS &&
        response.amount_open_for_investment === 0
      ) {
        this.handlePendingReservations(response);
      } else {
        this.buttonTarget.disabled = false;
        this.setMinimumInvestmentAmount(response.amount_open_for_investment);
      }
    });
  };

  reload = () => {
    window.location.reload();
  };

  handleReservationExpired = () => {
    window.dispatchEvent(new Event(InvestNowModal.close));
    this.releaseReservation(true);

    $('#investment-time-out-modal').modal(show);
  };

  releaseReservation = (async, callback) => {
    $.ajax({
      url: this.investmentReserveUrl,
      method: 'DELETE',
      async,
    })
      .done(response => {
        this.updatePendingInfo(response);
        if (callback) callback(response);
      })
      .fail(this.handleInvestServerError);
  };

  handleInvestServerError = () => {
    this.buttonTarget.disabled = true;
    this.displayMessage('Oops, something went wrong!');
  };

  reserveInvestment = amount => {
    $.ajax({
      url: `${this.investmentReserveUrl}?amount=${amount}`,
      method: 'PATCH',
    })
      .done(response => {
        this.updatePendingInfo(response);

        switch (response.operation_status) {
          case operationStatuses.SUCCESS:
            this.handleReserveSuccess(response);
            break;

          case operationStatuses.NOT_AVAILABLE:
            this.handleReserveNotAvailable(response);
            break;

          case operationStatuses.EXCEEDED:
            this.handleExceededReservation(response);
            break;

          case operationStatuses.MAX_AMOUNT_EXCEEDED:
            this.handleMaxAmountExceeded(response);
            break;

          default:
            break;
        }
      })
      .fail(this.handleInvestServerError);
  };

  handleMaxAmountExceeded = response => {
    if (isNil(this.maximumInvestmentAmount)) {
      return;
    }

    const maxAmountMessage =
      `This investment will go over the maximum amount allowed ` +
      `to be invested in this deal by a single investor, ` +
      `which is ${formatCurrency(this.maximumInvestmentAmount)}. `;

    const suggestedAmountMessage =
      `Please reduce your investment ` +
      `to be less than ${formatCurrency(response.alt_amount)} ` +
      `or see our other offerings for more choices.`;

    const message =
      response.alt_amount < this.minimumInvestmentAmount
        ? 'Maximum amount invested, please see our other offerings'
        : `<p>${maxAmountMessage}</p>` + `<p>${suggestedAmountMessage}</p>`;

    this.amountTarget.value = Math.floor(response.alt_amount);
    this.displayMessage(message);
  };

  handleExceededReservation = response => {
    this.displayMessage(
      `Please enter an amount less than $${response.alt_amount}`,
    );
  };

  handleReserveNotAvailable = response => {
    switch (response.reserve_state) {
      case reserveStates.FULLY_FUNDED:
        this.handleFullyFunded();
        break;

      case reserveStates.OPEN:
        this.handleOpenReservation(response);
        break;

      case reserveStates.PENDING_RESERVATIONS:
        this.handlePendingReservations(response);
        break;

      default:
        break;
    }
  };

  handleReserveSuccess = () => {
    window.dispatchEvent(new Event(CountdownTimer.start));

    this.openInvestNowModal();
    this.displayMessage('');
  };

  handlePendingReservations = response => {
    this.updateInvestmentStatus();
    this.buttonTarget.disabled = true;

    this.expirationForPending = moment().add(
      response.latest_reservation_expiration,
      'seconds',
    );

    this.pendingReservationTimer = setInterval(
      this.pendingReservationTick,
      1000,
    );
  };

  handleOpenReservation = response => {
    this.displayMessage(
      `We can only take ${formatCurrency(
        response.alt_amount,
      )} maximally at the moment`,
    );
  };

  pendingReservationTick = () => {
    const now = moment();

    const timeLeftStr = formatSecondsToTime(
      this.expirationForPending.diff(now, 'seconds'),
    );

    if (now.isSameOrAfter(this.expirationForPending, 'seconds')) {
      clearInterval(this.pendingReservationTimer);
      window.location.reload();
    } else {
      const message =
        `Investments in this offering are on hold. ` +
        `If pending funds clear within ${timeLeftStr}, ` +
        `this offering will be fully funded.`;

      this.displayMessage(message);
    }
  };

  handleFullyFunded = () => {
    this.buttonTarget.disabled = true;

    this.displayMessage('This offering is fully funded');
    window.dispatchEvent(new Event(InvestNowModal.onFullyFunded));
  };

  handleCloseModalMessage = () => {
    window.dispatchEvent(new Event(CountdownTimer.stop));
    this.releaseReservation(true);
  };

  handleSubmit = () => {
    const {
      category,
      action,
      label,
      previouslyInvestedAmount,
    } = this.buttonTarget.dataset;

    trackGAEvent({ category, action, label });

    if (!this.isUserSelfVerified) {
      $('#self-verification-modal').modal(show);
      return;
    }

    if (!this.isUserHasActiveEntities) {
      $('#go-create-entity-modal').modal(show);
      return;
    }

    const amount = this.amountTarget.value;

    if (amount < this.minimumInvestmentAmount) {
      this.displayMessage(
        `Please enter at least ${formatCurrency(this.minimumInvestmentAmount)}`,
      );
      return;
    }

    if (amount % 1000 !== 0) {
      this.displayMessage('Investment must be an increment of $1,000.');
      return;
    }

    if (previouslyInvestedAmount > 0) {
      this.showWarning(amount);
      return;
    }

    this.reserveInvestment(amount);
  };

  handleSelfVerificationModalSuccess = () => {
    this.isUserSelfVerified = true;

    this.handleSubmit();
  };

  openInvestNowModal = () => {
    const event = new CustomEvent(InvestNowModal.open, {
      detail: { amount: this.amountTarget.value },
    });

    window.dispatchEvent(event);
  };

  displayMessage = message => {
    this.messageTarget.innerHTML = message;
  };

  updatePendingInfo = ({ percent_reserved, total_reserved }) => {
    const event = new CustomEvent(InvestNowModal.onUpdatePendingInfo, {
      detail: {
        percentage: percent_reserved,
        amount: total_reserved,
      },
    });

    window.dispatchEvent(event);
  };

  updateInvestmentStatus = () => {
    const event = new CustomEvent(InvestNowModal.onStatusUpdate, {
      detail: 'Investments on Hold',
    });

    window.dispatchEvent(event);
  };

  showWarning = amount => {
    $(InvestNowModal.warning).modal(show);
    $(InvestNowModal.warningConfirmation).on(click, () =>
      this.handleConfirm(amount),
    );

    $(InvestNowModal.notNow).on(click, () => this.handleNotNow());
  };

  handleConfirm = amount => {
    $(InvestNowModal.warning).modal(hide);

    const event = new CustomEvent(InvestNowModal.open, {
      detail: {
        amount: amount,
      },
    });

    window.dispatchEvent(event);
  };

  handleNotNow = () => {
    $(InvestNowModal.warning).modal(hide);
  };
}
