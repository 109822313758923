import { Controller } from 'stimulus';

import { APICaller } from '@ftf/lib/api';

export default class ClosePurchasePoolModal extends Controller {
  static targets = ['subEntity', 'custodian'];

  updateInstitution({ target: { value } }) {
    if (value) {
      this.setInstitutionDependenciesDisabled(false);
      this.getOptionsByInstitutionId(value, 'subEntity');
      this.getOptionsByInstitutionId(value, 'custodian');
    } else {
      this.setInstitutionDependenciesDisabled(true);
      this.emptyInstitutionDependencies();
    }
  }

  setInstitutionDependenciesDisabled(disabledValue) {
    $(this.subEntityTarget).prop('disabled', disabledValue);
    $(this.custodianTarget).prop('disabled', disabledValue);
  }

  emptyInstitutionDependencies() {
    $(this.subEntityTarget).prop('value', '');
    $(this.custodianTarget).prop('value', '');
  }

  async getOptionsByInstitutionId(institutionId, target) {
    const field = {
      subEntity: {
        title: 'Sub-Entity',
        path: 'sub-entities',
        target: this.subEntityTarget,
      },
      custodian: {
        title: 'Custodian',
        path: 'custodians',
        target: this.custodianTarget,
      },
    };

    // Empty current options
    $(field[target]?.target).empty();

    // Add default option
    const emptyOption = document.createElement('option');
    emptyOption.value = '';
    emptyOption.innerHTML = `Select ${field[target]?.title}`;
    $(field[target]?.target).append(emptyOption);

    const { data } = await APICaller.get(
      `v2/${field[target]?.path}?filter[institution]=${institutionId}&page[size]=50`,
    );

    if (data?.data.length) {
      data.data.forEach(value => {
        const option = document.createElement('option');
        option.value = value.id;
        option.innerHTML = value?.attributes?.name || '';

        $(field[target]?.target).append(option);
      });
    }
  }
}
