import { Controller } from 'stimulus';
import { isNil, find } from 'lodash';

import { handleAJAXError } from '@ftf/lib/alerts';
import { validators, isFormValid } from '@ftf-old/main/helpers';
import { trackGAEvent } from '@ftf-old/main/tracking';

export default class FormStepAccount extends Controller {
  static targets = [
    'form',
    'userForm',
    'backButton',
    'submitButton',
    'switchFormButton',
  ];

  connect() {
    this.backButtonTargets.forEach(elem => {
      elem.addEventListener('click', this.goBack, false);
    });

    this.formTargets.forEach(elem => {
      $(elem).validator({
        custom: {
          phone: validators.validations.phone,
          email: validators.validations.email,
        },
        errors: {
          phone: validators.errors.phone,
          email: validators.errors.email,
        },
      });

      elem.addEventListener('keydown', this.handleFormKeydown, false);

      $(elem)
        .on('ajax:error', this.handleFormError)
        .on('ajax:success', this.handleFormSuccess);

      this.initPhoneFormatter(elem);
    });

    this.submitButtonTargets.forEach(elem => {
      elem.addEventListener('click', this.submit, false);
    });

    this.switchFormButtonTargets.forEach(elem => {
      elem.addEventListener('click', this.siwtchForm, false);
    });

    window.addEventListener(
      'TwoFactorAuthModal:verified',
      this.handleVerification,
    );
  }

  disconnect() {
    this.backButtonTargets.forEach(elem => {
      elem.removeEventListener('click', this.goBack, false);
    });

    this.formTargets.forEach(elem => {
      $(elem).validator('destroy');

      elem.addEventListener('keydown', this.handleFormKeydown, false);

      $(elem)
        .off('ajax:error', this.handleFormError)
        .off('ajax:success', this.handleFormSuccess);
    });

    this.submitButtonTargets.forEach(elem => {
      elem.removeEventListener('click', this.submit, false);
    });

    this.switchFormButtonTargets.forEach(elem => {
      elem.removeEventListener('click', this.siwtchForm, false);
    });

    window.removeEventListener(
      'TwoFactorAuthModal:verified',
      this.handleVerification,
    );
  }

  goBack = e => {
    const { category, action } = e.target.dataset;

    trackGAEvent({ category, action });

    window.dispatchEvent(
      new CustomEvent('DealApplicationForm:goToStep', { detail: 'experience' }),
    );
  };

  submit = e => {
    const $form = $(e.target).closest('form');

    $form.validator('validate');

    const { category, action, label } = e.target.dataset;

    trackGAEvent({ category, action, label });

    if (isFormValid($form)) {
      return true;
    }

    e.preventDefault();

    return false;
  };

  siwtchForm = async e => {
    e.preventDefault();

    const currentForm = find(
      this.userFormTargets,
      elem => elem.dataset.mode !== e.target.dataset.mode,
    );

    const targetForm = find(
      this.userFormTargets,
      elem => elem.dataset.mode === e.target.dataset.mode,
    );

    await new Promise(resolve => {
      $(currentForm).fadeOut(300, () => {
        $(currentForm).removeClass('is-active');
        resolve();
      });
    });

    await new Promise(resolve => {
      $(targetForm).fadeIn(300, () => {
        $(targetForm).addClass('is-active');
        this.initPhoneFormatter(targetForm);
        resolve();
      });
    });

    await new Promise(resolve => {
      $('html, body').animate({ scrollTop: 0 }, 300, () => {
        $(targetForm)
          .find(':input:enabled:visible:first')
          .focus();
        resolve();
      });
    });

    const { category, action } = e.target.dataset;

    trackGAEvent({ category, action });
  };

  handleFormKeydown = e => {
    if (e.which !== 13) {
      return;
    }

    e.preventDefault();
  };

  handleFormError = (
    e,
    jqXHR,
    { csrf_token: csrfToken, two_factor_auth: twoFactorAuth } = {},
  ) => {
    // catch false AJAX error
    if (jqXHR.status === 200) {
      this.handleFormSuccess(e, {
        csrf_token: csrfToken,
        two_factor_auth: twoFactorAuth,
      });
    } else {
      handleAJAXError(e, jqXHR);
    }
  };

  handleFormSuccess = (
    e,
    { csrf_token: csrfToken, two_factor_auth: twoFactorAuth } = {},
  ) => {
    e.stopPropagation();

    if (csrfToken) {
      $('meta[name="csrf-token"]').attr('content', csrfToken);
    }

    const { mode } = e.currentTarget.dataset;

    if (mode === 'sign-in' && twoFactorAuth) {
      window.dispatchEvent(
        new CustomEvent('TwoFactorAuthModal:show', {
          detail: { ...twoFactorAuth, isPersistent: true },
        }),
      );

      return;
    }

    this.finalizeDealApplication(mode === 'sign-up');
  };

  handleVerification = () => {
    this.finalizeDealApplication();
  };

  finalizeDealApplication = async (newUser = false) => {
    const dealApplicationId = localStorage.getItem('dealApplicationId');

    if (isNil(dealApplicationId)) {
      return;
    }

    try {
      const data = await $.ajax({
        url: `/deal_applications/${dealApplicationId}/finalize`,
        method: 'POST',
        data: { new_user: newUser },
      });

      if (Object.prototype.hasOwnProperty.call(data, 'redirect_to')) {
        window.location.href = `${window.location.origin}${data.redirect_to}`;
      }
    } catch (error) {
      window.location.reload();
    }
  };

  initPhoneFormatter = form => {
    const $phone = $(form).find('.bfh-phone');

    if ($phone.length === 0) {
      return;
    }

    $phone.bfhphone($phone.data());
  };
}
