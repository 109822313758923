import { Controller } from 'stimulus';
import { find, isNil, isEmpty } from 'lodash';

import { handleAJAXError } from '@ftf/lib/alerts';

const STEPS = ['project', 'experience', 'account'];

export default class LoanApplicationContainer extends Controller {
  static targets = [
    'progressItem',
    'step',
    'field',
    'progressBarProject',
    'progressBarExperience',
    'progressBarAccount',
    'progressBarSuccess',
  ];

  currentStep = 'project';

  connect() {
    window.addEventListener(
      'LoanApplicationContainer:goToStep',
      this.handleGoToStep,
      false,
    );

    this.element.addEventListener('keydown', this.handleFormKeydown, false);

    $(this.element)
      .on('ajax:error', handleAJAXError)
      .on('ajax:success', this.handleFormSuccess);
  }

  disconnect() {
    window.removeEventListener(
      'LoanApplicationContainer:goToStep',
      this.handleGoToStep,
      false,
    );

    this.element.removeEventListener('keydown', this.handleFormKeydown, false);

    $(this.element)
      .off('ajax:error', handleAJAXError)
      .off('ajax:success', this.handleFormSuccess);
  }

  updateProgress = step => {
    this.progressItemTargets.forEach(element => {
      element.classList.toggle('is-active', element.dataset.step === step);
    });
  };

  updateStep = async step => {
    const currentStep = find(
      this.stepTargets,
      elem => elem.dataset.step === this.currentStep,
    );

    const targetStep = find(
      this.stepTargets,
      elem => elem.dataset.step === step,
    );

    await new Promise(resolve => {
      $(currentStep).fadeOut(500, () => {
        $(currentStep).removeClass('is-active');
        resolve();
      });
    });

    await new Promise(resolve => {
      $(targetStep).fadeIn(500, () => {
        $(targetStep).addClass('is-active');
        resolve();
      });
    });

    await new Promise(resolve => {
      $('html, body').animate({ scrollTop: 0 }, 300, () => {
        $(targetStep)
          .find(':input:enabled:visible:first')
          .focus();
        resolve();
      });
    });
  };

  goToStep = step => {
    if (!STEPS.includes(step)) {
      return;
    }

    if (step === this.currentStep) {
      return;
    }

    this.updateProgress(step);
    this.updateProgressBar(step);
    this.updateStep(step);

    this.currentStep = step;
  };

  updateProgressBar(step) {
    let currentStep;

    switch (step) {
      case 'project':
        currentStep = parseInt(
          this.progressBarProjectTarget.parentElement.id.split('-')[1],
          10,
        );

        this.handleProgressBars(currentStep);

        break;
      case 'experience':
        currentStep = parseInt(
          this.progressBarExperienceTarget.parentElement.id.split('-')[1],
          10,
        );

        this.handleProgressBars(currentStep);

        break;
      case 'account':
        currentStep = parseInt(
          this.progressBarAccountTarget.parentElement.id.split('-')[1],
          10,
        );

        this.handleProgressBars(currentStep);

        break;
    }
  }

  handleProgressBars(currentStep) {
    for (let i = currentStep; i >= 1; i--) {
      this.makeProgressBarActive(
        document.getElementById(`pb-${i}`).firstElementChild,
      );
    }

    for (
      let i = currentStep + 1;
      i <= document.getElementById('pb-container').children.length;
      i++
    ) {
      this.makeProgressBarInactive(
        document.getElementById(`pb-${i}`).firstElementChild,
      );
    }
  }

  makeProgressBarActive(progressBarTarget) {
    progressBarTarget.classList.remove('step-timeline-3');
    progressBarTarget.classList.add('active-step-line');
  }

  makeProgressBarInactive(progressBarTarget) {
    progressBarTarget.classList.remove('active-step-line');
    progressBarTarget.classList.add('step-timeline-3');
  }

  handleFormKeydown = e => {
    if (e.which !== 13) {
      return;
    }

    e.preventDefault();
  };

  handleFormSuccess = (e, data) => {
    localStorage.setItem('loanApplicationId', data.uuid);
  };

  handleGoToStep = e => {
    if (isNil(e.detail) || isEmpty(e.detail)) return;

    this.goToStep(e.detail);
  };
}
