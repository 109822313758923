import { camelCase } from 'lodash';

import styleguide from '../../styleguide.json';

const variables = Object.keys(styleguide).reduce((result, key) => {
  result[camelCase(key)] = styleguide[key];

  return result;
}, {});

export default Object.freeze({
  ...variables,

  mediaTablet: '(min-width: 768px)',
  mediaDesktop: '(min-width: 900px)',
  mediaLarge: '(min-width: 1200px)',
});
