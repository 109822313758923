import { Controller } from 'stimulus';

export default class PrintButton extends Controller {
  connect() {
    this.element.addEventListener('click', this.print, false);
  }

  disconnect() {
    this.element.removeEventListener('click', this.print, false);
  }

  print = () => window.print();
}
