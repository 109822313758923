import { Controller } from 'stimulus';
import { debounce, isNil, find } from 'lodash';

import { formatNumbersAfterTyped, formatNumbers } from '@ftf-old/main/helpers';
import { formatCurrency, formatNumberToPercentage } from '@ftf/lib/formatters';

export default class DealCalculator extends Controller {
  static targets = ['calcForm', 'result', 'linkedField'];

  initialize() {
    this.debouncedHandleCalcFormInput = debounce(this.handleCalcFormInput, 300);
  }

  connect() {
    formatNumbers();
    formatNumbersAfterTyped();

    for (const element of this.calcFormTarget.elements) {
      element.addEventListener(
        'input',
        this.debouncedHandleCalcFormInput,
        false,
      );
    }

    $(this.calcFormTarget).on('ajax:success', this.handleCalcFormSuccess);
  }

  disconnect() {
    for (const element of this.calcFormTarget.elements) {
      element.removeEventListener(
        'input',
        this.debouncedHandleCalcFormInput,
        false,
      );
    }

    $(this.calcFormTarget).off('ajax:success', this.handleCalcFormSuccess);
  }

  handleCalcFormInput = () => $(this.calcFormTarget).submit();

  handleCalcFormSuccess = (e, data) => {
    this.fillResult(data.result);
    this.fillLinkedFields();
  };

  fillResult = result => {
    if (isNil(result)) {
      return;
    }

    this.resultTargets.forEach(el => {
      const { field, format } = el.dataset;
      const value = result[field];

      let text;

      switch (format) {
        case 'currency':
          text = formatCurrency(value, {}, '');
          break;

        case 'percentage':
          text = formatNumberToPercentage(value, false, '');
          break;

        default:
          text = value;
          break;
      }

      el.innerText = text;
    });
  };

  fillLinkedFields = () => {
    const calcFormValues = $(this.calcFormTarget).serializeArray();

    this.linkedFieldTargets.forEach(el => {
      const match = find(
        calcFormValues,
        pair => pair.name.indexOf(el.dataset.attrName) > -1,
      );

      if (isNil(match)) {
        return;
      }

      el.value = match.value;
    });
  };
}
