let el;
let parentEl;
let offsetEl;
let win;
let doc;
let homePosition;
let fixPoint;
let isFixed = false;
let isMobile = false;

const handleScroll = () => {
  if (!isMobile && el.getBoundingClientRect().top <= fixPoint && !isFixed) {
    isFixed = true;
    el.classList.add('is-sticky');
  } else if (
    isMobile &&
    parentEl.getBoundingClientRect().bottom <= fixPoint &&
    !isFixed
  ) {
    isFixed = true;
    el.classList.add('is-sticky');
  } else if (
    isFixed &&
    (win.pageYOffset || doc.documentElement.scrollTop) < homePosition
  ) {
    isFixed = false;
    el.classList.remove('is-sticky');
  }
};

const onStartClick = () => {
  el.classList.add('is-cta-active');
};

const onCloseClick = () => {
  el.classList.remove('is-cta-active');
};

const handleResize = () => {
  if (!isMobile && win.innerWidth < 768) {
    isMobile = true;
    fixPoint = offsetEl.clientHeight;
    homePosition = parentEl.clientHeight;
  } else if (isMobile && win.innerWidth >= 768) {
    isMobile = false;
    fixPoint = offsetEl.clientHeight;
    homePosition = el.offsetTop;
  }
};

const stickyTopEl = (elId, offsetElId) => {
  win = window;
  doc = document;
  el = doc.getElementById(elId);
  parentEl = el.parentElement;
  offsetEl = doc.getElementById(offsetElId);
  fixPoint = offsetEl.clientHeight;
  homePosition = el.offsetTop;

  handleResize();

  win.addEventListener('scroll', handleScroll, false);
  win.addEventListener('resize', handleResize, false);
  doc
    .getElementById('sticky_address_mb-tab')
    .addEventListener('click', onStartClick, false);
  doc
    .getElementById('sticky_close-btn')
    .addEventListener('click', onCloseClick, false);
};

export default stickyTopEl;
