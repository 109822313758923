import { Controller } from 'stimulus';

export default class PaymentRequestForm extends Controller {
  static targets = [
    'form',
    'quickSearchField',
    'entityDropdown',
    'userName',
    'userEmail',
    'userNoEntityMessage',
    'submitButton',
  ];

  connect() {
    $(this.element).validator({ disable: false });

    this.quickSearchFieldTargets.forEach(el => {
      el.addEventListener(
        'quicksearch:selected',
        this.handleQuickSearchSelected,
        false,
      );
    });
  }

  disconnect() {
    $(this.element).validator('destroy');

    this.quickSearchFieldTargets.forEach(el => {
      el.removeEventListener(
        'quicksearch:selected',
        this.handleQuickSearchSelected,
        false,
      );
    });
  }

  handleQuickSearchSelected = e => {
    const { url } = e.target.dataset;
    const { id } = e.detail.suggestion;

    this.clearOldResult();

    $.ajax({
      url,
      method: 'GET',
      data: { id },
      success: result => {
        this.fillResult(result);
        this.userNameTarget.innerHTML = `${result.first_name} ${result.last_name} User ID: ${id}`;
        this.userEmailTarget.innerHTML = result.email;
      },
    });
  };

  fillResult = result => {
    const select = this.entityDropdownTarget;

    if (result.entities.length === 0) {
      this.submitButtonTarget.disabled = true;
      select.style.display = 'none';
      this.userNoEntityMessageTarget.innerHTML = 'No entities available';
    } else {
      for (var i = 0; i < result.entities.length; i++) {
        let opt = result.entities[i];
        let el = document.createElement('option');
        el.textContent = opt.name;
        el.value = opt.id;
        select.appendChild(el);

        if (opt.id === result.default_entity.id) {
          select.value = el.value;
        }

        if (opt.wallet === false) {
          el.disabled = true;
        }
      }
    }
  };

  clearOldResult = () => {
    const select = this.entityDropdownTarget;

    select.style.display = 'block';
    this.submitButtonTarget.disabled = false;
    this.userNameTarget.innerHTML = '';
    this.userEmailTarget.innerHTML = '';
    this.userNoEntityMessageTarget.innerHTML = '';

    if (select.options.length > 0) {
      var i,
        len = select.options.length - 1;

      for (i = len; i >= 1; i--) {
        select.remove(i);
      }
    }
  };
}
