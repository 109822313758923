import { Controller } from 'stimulus';

export default class EditEntityModal extends Controller {
  static targets = ['form'];

  connect() {
    $(this.formTarget).on('ajax:success', this.handleEntityUpdate);
  }

  disconnect() {
    $(this.formTarget).off('ajax:success', this.handleEntityUpdate);
  }

  handleEntityUpdate = (e, data) => {
    window.dispatchEvent(
      new CustomEvent('EditEntityModal:success', {
        detail: data,
      }),
    );

    $(this.element).modal('hide');
  };
}
