import { Application } from 'stimulus';

import { html } from '@ftf/lib/templateTags';

import CountdownTimer from '../countdown_timer';

function renderApp() {
  document.body.innerHTML = html`
    <div data-controller="CountdownTimer">
      Please complete your investment within 5 minutes
    </div>
  `;

  const app = Application.start();
  app.register('CountdownTimer', CountdownTimer);
}

describe('CountdownTimer block', () => {
  beforeEach(() => {
    renderApp();
    jest.useFakeTimers();
  });

  afterEach(() => {
    document.body.innerHTML = '';
    jest.clearAllTimers();
  });

  it('starts on CountdownTimer:start event', () => {
    window.dispatchEvent(new Event('CountdownTimer:start'));
    jest.advanceTimersByTime(1100);

    expect(setInterval).toHaveBeenCalledWith(expect.any(Function), 1000);
  });

  it('stops on CountdownTimer:stop event', () => {
    window.dispatchEvent(new Event('CountdownTimer:stop'));

    expect(clearInterval).toHaveBeenCalledWith(expect.any(Number));
  });
});
