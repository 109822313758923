import { Controller } from 'stimulus';
import GLightbox from 'glightbox';
import { isNil } from 'lodash';

export default class EventPicturesGallery extends Controller {
  static targets = ['picture', 'more'];

  lightbox = undefined;

  connect() {
    if (this.pictureTargets.length > 0) {
      const pictures = JSON.parse(this.element.dataset.pictures);

      this.lightbox = new GLightbox({
        elements: pictures.map(picture => ({
          href: picture.attachment_asset_url,
          type: 'image',
        })),
        preload: false,
      });

      this.lightbox.on('open', this.handleLightboxOpen);
      this.lightbox.on('slide_changed', this.handleLightboxSlideChanged);
    }

    this.pictureTargets.forEach(picture => {
      picture.addEventListener('click', this.handlePictureClick, false);
    });

    if (this.hasMoreTarget) {
      this.moreTarget.addEventListener('click', this.handlePictureClick, false);
    }
  }

  disconnect() {
    if (this.pictureTargets.length > 0) {
      this.lightbox.destroy();
    }

    this.pictureTargets.forEach(picture => {
      picture.removeEventListener('click', this.handlePictureClick, false);
    });

    if (this.hasMoreTarget) {
      this.moreTarget.removeEventListener(
        'click',
        this.handlePictureClick,
        false,
      );
    }
  }

  handleLightboxOpen = () => {
    const counter = document.createElement('div');

    counter.classList.add('gcounter');

    this.lightbox.modal.prepend(counter);
  };

  handleLightboxSlideChanged = ({ current }) => {
    const counter = this.lightbox.modal.querySelector('.gcounter');
    const total = this.lightbox.elements.length;

    counter.innerText = `${current.index + 1} / ${total}`;
  };

  handlePictureClick = e => {
    if (isNil(this.lightbox)) {
      return;
    }

    const { index } = e.currentTarget.dataset;

    this.lightbox.openAt(index);
  };
}
