import { Controller } from 'stimulus';
import { isNil } from 'lodash';

import { processAJAXError } from '@ftf/lib/errors';

export default class RolloverConfirmModal extends Controller {
  static targets = ['errors', 'form', 'ppmAgreement', 'submitBtn', 'loader'];

  connect() {
    this.ppmAgreementTarget.addEventListener(
      'change',
      this.handlePpmAgreementChange,
      false,
    );

    $(this.formTarget)
      .on('ajax:beforeSend', this.showLoader)
      .on('ajax:success', this.handleFormSuccess)
      .on('ajax:error', this.handleFormError);
  }

  disconnect() {
    this.ppmAgreementTarget.removeEventListener(
      'change',
      this.handlePpmAgreementChange,
      false,
    );

    $(this.formTarget)
      .off('ajax:beforeSend', this.showLoader)
      .off('ajax:success', this.handleFormSuccess)
      .off('ajax:error', this.handleFormError);
  }

  handlePpmAgreementChange = e => {
    this.submitBtnTarget.disabled = !e.target.checked;
  };

  showLoader = () => {
    this.submitBtnTarget.style.display = 'none';
    this.loaderTarget.style.display = 'block';
  };

  hideLoader = () => {
    this.submitBtnTarget.style.display = 'block';
    this.loaderTarget.style.display = 'none';
  };

  handleFormSuccess = (e, { redirect_to: redirectTo } = {}) => {
    if (isNil(redirectTo) || redirectTo.length === 0) {
      return;
    }

    window.location.href = redirectTo;
  };

  handleFormError = (e, xhr) => {
    this.hideLoader();
    this.errorsTarget.innerText = processAJAXError(xhr);
  };
}
