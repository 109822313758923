import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import classNames from 'classnames';

export const SortableImage = SortableElement(
  ({ image, imageIndex, name, onDelete, isDisabled }) => (
    <div className="col-xs-6 col-sm-3 mb15 mt15">
      {name && (
        <>
          {image.signed_id ? (
            <input
              type="hidden"
              name={`${name}[${imageIndex}][asset]`}
              value={image.signed_id}
            />
          ) : (
            <input
              type="hidden"
              name={`${name}[${imageIndex}][id]`}
              value={image.id}
            />
          )}
        </>
      )}

      <div className="sortable-card">
        <div
          className="square-image-bg mb5"
          style={{
            maxWidth: '100%',
            background: `#f4f4f4 url("${image.preview ||
              image.attachment_asset_url}") no-repeat center center`,
            backgroundSize: 'contain',
          }}
        />
        <div className="clearfix">
          <span className="pull-left">{imageIndex + 1}</span>

          <span
            className={classNames('pull-right', { disabled: isDisabled })}
            style={{ cursor: 'pointer' }}
            onClick={() => onDelete(image.id)}
            role="presentation"
          >
            <i className="fa fa-trash-o" aria-hidden="true" />
          </span>
        </div>
      </div>
    </div>
  ),
);
