import { Controller } from 'stimulus';

export default class UprightHomeResources extends Controller {
  static targets = ['podcastContainer'];

  connect() {
    this.podcastContainerTarget.addEventListener('click', () => {
      window.location.href =
        'https://learn.upright.us/real-estate-investing-unscripted-podcast';
    });
  }
}
