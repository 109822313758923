import { Controller } from 'stimulus';

export default class DocumentRequestsForm extends Controller {
  static targets = ['checkbox', 'submit', 'email'];

  labels = {};
  persistedLables = {};

  isSubmitted = false;

  connect() {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.addEventListener('change', this.handleCheckboxChange, false);

      // Create lists of persisted and checked labels that we can diff when enabling buttons
      if (checkbox.checked) {
        this.persistedLables = {
          ...this.persistedLables,
          [checkbox.value]: checkbox.checked,
        };
      }
    });

    this.labels = this.persistedLables;

    this.updateEmailButton();
  }

  disconnect() {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.removeEventListener('change', this.handleCheckboxChange, false);
    });
  }

  handleCheckboxChange = e => {
    // if e.target.checked is false, only update the value stored in this.lables if e.target.value
    // is in this.persistedLabels. otherwise, remove it from this.labels.
    if (
      e.target.checked === true ||
      (e.target.checked === false && this.persistedLables[e.target.value])
    ) {
      this.labels = {
        ...this.labels,
        [e.target.value]: e.target.checked,
      };
    } else if (
      e.target.checked === false &&
      !this.persistedLables[e.target.value]
    ) {
      delete this.labels[e.target.value];
    }

    this.updateUI();
  };

  updateUI = () => {
    this.updateSubmitButton();
    this.updateEmailButton();
  };

  updateSubmitButton = () => {
    // Disable the submit button if the selected checkboxes match the document requests that have
    // already been persisted. It should only be clickable if there is new data to persist.
    if (JSON.stringify(this.labels) !== JSON.stringify(this.persistedLables)) {
      this.submitTarget.disabled = false;

      return;
    }

    this.submitTarget.disabled = true;
  };

  updateEmailButton = () => {
    // Emails should only be able to be sent if there are document requests to send.
    if (Object.keys(this.persistedLables).length > 0) {
      this.emailTarget.classList.remove('disabled');

      return;
    }

    this.emailTarget.classList.add('disabled');
  };
}
