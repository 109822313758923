/* global YT */
import { isScriptLoaded } from '../util/dom';

const scriptSrc = 'https://www.youtube.com/iframe_api';
const videos = {};
let isYtAPIReady = false;
let activeVideo = null;
let videoEls;

const loadYtScript = () => {
  const tag = document.createElement('script');
  const firstScriptTag = document.getElementsByTagName('script')[0];
  tag.src = scriptSrc;
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
};

const onPlayerReady = e => {
  e.target.playVideo();
};

const createYtVideo = el => {
  const videoDiv = document.createElement('div');
  const videoId = el.dataset.embed;
  videoDiv.setAttribute('id', videoId);
  el.innerHTML = '';
  el.appendChild(videoDiv);
  videos[videoId] = new YT.Player(videoId, {
    playerVars: {
      modestbranding: 1,
      showinfo: 0,
      rel: 0,
    },
    videoId,
    events: {
      onReady: onPlayerReady,
    },
  });
};

window.onYouTubeIframeAPIReady = () => {
  isYtAPIReady = true;
  if (activeVideo) {
    createYtVideo(activeVideo);
    activeVideo = null;
  }
};

const addYouTubeLazyLoad = () => {
  videoEls = document.querySelectorAll('.yt-player');
  [].forEach.call(videoEls, video => {
    const posterFrame = video.dataset.poster;
    const embed = video.dataset.embed;
    const quality = video.dataset.quality || 'sddefault';

    const source =
      posterFrame || `https://img.youtube.com/vi/${embed}/${quality}.jpg`;

    const image = new Image();
    image.src = source;

    image.addEventListener('load', () => {
      video.appendChild(image);
    });

    video.addEventListener('click', () => {
      if (isYtAPIReady) {
        createYtVideo(video, video.dataset.id);
      } else {
        activeVideo = video;
      }
      if (!isScriptLoaded(scriptSrc)) {
        loadYtScript();
      }
    });
  });
};

export default addYouTubeLazyLoad;
