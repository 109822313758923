import { Controller } from 'stimulus';
import { isArray, isString, isNil, isEmpty } from 'lodash';

export default class Alerts extends Controller {
  connect() {
    this.updatePosition();

    window.addEventListener('scroll', this.updatePosition);
    window.addEventListener('Alerts:add', this.add);
  }

  disconnect() {
    window.removeEventListener('scroll', this.updatePosition);
    window.removeEventListener('Alerts:add', this.add);
  }

  updatePosition = () => {
    const header = document.querySelector('header#header');

    if (isNil(header)) {
      return;
    }

    const { height: headerHeight } = header.getBoundingClientRect();
    const { height: containerHeight } = this.element.getBoundingClientRect();
    const isFixed =
      window.pageYOffset >
      this.element.parentElement.offsetTop + containerHeight - headerHeight;

    if (isFixed && this.element.classList.contains('is-fixed')) {
      return;
    }

    this.element.parentElement.style.paddingTop = isFixed
      ? `${containerHeight}px`
      : 0;
    this.element.style.top = isFixed ? `${headerHeight}px` : 'auto';
    this.element.classList.toggle('is-fixed', isFixed);
  };

  add = e => {
    if (isNil(e.detail) || isEmpty(e.detail)) {
      return;
    }

    const {
      autoDismissible = false,
      message,
      dismissible = true,
      sticky = false,
      type = 'info',
    } = e.detail;

    let text = '';

    if (isString(message)) {
      text = message;
    }

    if (isArray(message)) {
      text = message.join('<br />');
    }

    const html = `
      <div
        class="alerts__alert alerts__alert_${type} alert ${
      dismissible ? 'fade in show' : ''
    } is-animated"
        ${sticky ? "data-sticky='true'" : ''}
        role="alert"
      >
        <div class="alerts__alert-content">
          ${
            dismissible
              ? '\
            <button type="button" class="alerts__alert-close" data-dismiss="alert" aria-label="Close">\
              <span aria-hidden="true">×</span>\
            </button>\
          '
              : ''
          }

          <p>${text}</p>
        </div>
      </div>
    `;

    const $alert = $(html).appendTo(this.element);

    if (autoDismissible) {
      setTimeout(() => $alert.alert('close'), 3000);
    }

    setTimeout(() => $alert.removeClass('is-animated'), 400);
  };
}
