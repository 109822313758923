import { Controller } from 'stimulus';

export default class SignUp extends Controller {
  static targets = [
    'password',
    'passwordError',
    'passwordConfirmation',
    'passwordConfirmationError',
    'submitButton',
  ];

  connect() {
    this.passwordTarget.addEventListener('input', this.validatePassword);
    this.passwordConfirmationTarget.addEventListener(
      'input',
      this.validatePasswordConfirm,
    );
    this.errorMessages = {
      lengthError: 'Password must be at least 8 characters long',
      dontMatchError: 'Password does not match',
    };
  }

  disconnect() {
    this.passwordTarget.removeEventListener('input', this.validatePassword);
    this.passwordConfirmationTarget.removeEventListener(
      'input',
      this.validatePasswordConfirm,
    );
  }

  isPasswordError() {
    return this.passwordErrorTarget.innerHTML.length > 0;
  }

  validatePassword = () => {
    let password = this.passwordTarget.value;
    if (password.length < 8) {
      this.disableSubmit();
      this.setPasswordErrorMessage(this.errorMessages['lengthError']);
    } else {
      this.setPasswordErrorMessage('');
      this.validatePasswordConfirm();
    }
  };

  validatePasswordConfirm = () => {
    let passwordConfirm = this.passwordConfirmationTarget.value;
    let password = this.passwordTarget.value;
    if (passwordConfirm !== password || this.isPasswordError()) {
      this.disableSubmit();
      this.setPasswordConfirmErrorMessage(this.errorMessages['dontMatchError']);
    } else {
      this.setPasswordConfirmErrorMessage('');
      this.enableSubmit();
    }
  };

  setPasswordErrorMessage = message => {
    this.passwordErrorTarget.innerHTML = message;
  };

  disableSubmit = () => {
    this.submitButtonTarget.disabled = true;
  };

  enableSubmit = () => {
    this.submitButtonTarget.disabled = false;
  };

  setPasswordConfirmErrorMessage = message => {
    this.passwordConfirmationErrorTarget.innerHTML = message;
  };
}
