import { Controller } from 'stimulus';
import pluralize from 'pluralize';

export default class DocumentRequestsTable extends Controller {
  static targets = ['file'];

  connect() {
    this.fileTargets.forEach(fileInput => {
      fileInput.addEventListener('change', this.addFile, false);
    });
  }

  disconnect() {
    this.fileTargets.forEach(fileInput => {
      fileInput.removeEventListener('change', this.addFile, false);
    });
  }

  addFile = e => {
    const originalInput = e.target;
    const originalParent = originalInput.parentNode;
    // TODO: This is fragile as it depends on them being co-located
    // this.filesTarget.append(selectedFile)
    const filesToUpload = originalParent.nextElementSibling;

    // Create label (the visible part of the new input element) with the name of
    // the selected files.
    for (var i = 0; i <= originalInput.files.length - 1; i++) {
      // Create a new element that will contain our input element
      const selectedFile = document.createElement('div');
      selectedFile.className = 'selected-file';
      selectedFile.append(originalInput);

      // Duplicate the file and label
      const file = originalInput.files[i];
      const labelNode = document.createElement('label');
      const textElement = document.createTextNode(file.name);
      labelNode.appendChild(textElement);
      selectedFile.appendChild(labelNode);

      // Add the duplicated file to the list of selected files on the UI
      filesToUpload.append(selectedFile);
    }

    // Create a new input field to use going forward
    const newInput = originalInput.cloneNode();

    // Clear the filelist - some browsers maintain the filelist when cloning,
    // others don't
    newInput.value = '';
    newInput.addEventListener('change', this.addFile, false);
    // Add it to the DOM where the original input was
    originalParent.append(newInput);

    // Reveal the upload button
    const submit = originalParent.parentElement.querySelector(
      '.document-requests-table__submit',
    );

    submit.firstElementChild.value = `Upload ${pluralize(
      'Documents',
      filesToUpload.childElementCount,
      true,
    )}`;

    submit.style.display = 'block';
  };
}
