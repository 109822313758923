import { Controller } from 'stimulus';
import flatpickr from 'flatpickr';
import useValidation from '@lysyi3m/stimulus-use-validation';

export default class LoanDistributionsModal extends Controller {
  static targets = [
    'clearButton',
    'counter',
    'datePicker',
    'form',
    'hiddenFields',
    'poolSelect',
  ];

  flatpickrInstances = [];

  connect() {
    useValidation(this, {
      form: this.formTarget,
    });

    $(this.element).on('show.bs.modal', this.handleShowModal);

    this.datePickerTargets.forEach((el, index) => {
      this.flatpickrInstances[index] = flatpickr(el, {
        minDate: '2000-01',
        allowInput: true,
      });
    });

    this.clearButtonTarget.addEventListener('click', this.handleClear, false);
    this.poolSelectTarget.addEventListener(
      'change',
      this.handlePoolChange,
      false,
    );
  }

  disconnect() {
    $(this.element).off('show.bs.modal', this.handleShowModal);

    this.flatpickrInstances.forEach(instance => {
      instance.destroy();
    });

    this.clearButtonTarget.removeEventListener(
      'click',
      this.handleClear,
      false,
    );

    this.poolSelectTarget.removeEventListener(
      'change',
      this.handlePoolChange,
      false,
    );
  }

  handleShowModal = e => {
    const ids = (e.relatedTarget.dataset.ids || '').split(',');

    this.counterTargets.forEach(el => (el.textContent = ids.length));

    this.hiddenFieldsTarget.innerHTML = '';

    ids.forEach(id => {
      $(this.hiddenFieldsTarget).append(
        `<input type="hidden" name="loans[]" value="${id}" />`,
      );
    });
  };

  handleClear = () => {
    window.dispatchEvent(new CustomEvent('BulkSelectButton:clear'));
    window.dispatchEvent(new CustomEvent('BulkSelectForm:clear'));

    $(this.element).modal('hide');
  };

  handlePoolChange = e => {
    for (const field of this.formTarget.querySelectorAll('input, select')) {
      if (field !== e.target) {
        field.disabled = !e.target.value;
      }
    }
  };
}
