import { Controller } from 'stimulus';

export default class PorfolioInsights extends Controller {
  connect() {
    $('.caret-toggle').click(function() {
      $(this)
        .find('.caret')
        .toggleClass('portfolio-insights__caret-down');
    });
  }
}
