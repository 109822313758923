import { Controller } from 'stimulus';

import { buildEndpoint } from '@ftf/lib/api';

export default class InvestmentsAdminTable extends Controller {
  static targets = ['column'];

  sort = {
    column: undefined,
    direction: undefined,
  };

  get paginationLinks() {
    return $(this.element).find('.pagination a[data-remote]');
  }

  connect() {
    this.initSortingParams();

    this.paginationLinks
      .on('ajax:before', this.showLoadingIndication)
      .on('ajax:complete', this.hideLoadingIndication)
      .on('ajax:success', this.handlePageUpdate);

    window.addEventListener(
      'InvestmentsList:onUpdated',
      this.handleInvestmentListUpdate,
    );

    this.columnTargets.forEach(column =>
      column.addEventListener('click', this.handleColumnSortingChange),
    );
  }

  disconnect() {
    this.paginationLinks
      .off('ajax:before', this.showLoadingIndication)
      .off('ajax:complete', this.hideLoadingIndication)
      .off('ajax:success', this.handlePageUpdate);

    window.removeEventListener(
      'InvestmentsList:onUpdated',
      this.handleInvestmentListUpdate,
    );

    this.columnTargets.forEach(column =>
      column.removeEventListener('click', this.handleColumnSortingChange),
    );
  }

  handlePageUpdate = (_, data) => {
    this.updateHtml(data);
  };

  handleInvestmentListUpdate = ({ detail: html }) => {
    this.updateHtml(html);
  };

  updateHtml = html => {
    this.element.outerHTML = html;
  };

  showLoadingIndication = () => {
    this.element.classList.add('is-loading');
  };

  hideLoadingIndication = () => {
    this.element.classList.remove('is-loading');
  };

  initSortingParams = () => {
    let { sort, direction } = this.element.dataset;

    const isValidDirection = ['asc', 'desc'].includes(direction);

    this.sort = {
      column: sort || undefined,
      direction: isValidDirection ? direction : undefined,
    };
  };

  handleColumnSortingChange = ({ target: element }) => {
    const column = element.dataset.sort;
    let direction = 'asc';

    const isAlreadySortedByTheColumn =
      column === this.sort.column && this.sort.direction;

    if (isAlreadySortedByTheColumn) {
      direction = this.sort.direction === 'asc' ? 'desc' : 'asc';
    }

    this.sort = { column, direction };

    this.fetchNewData();
  };

  fetchNewData = async () => {
    const url = buildEndpoint(this.element.dataset.path, {
      sort: this.sort.column,
      direction: this.sort.direction,
    });

    this.showLoadingIndication();

    const result = await fetch(url);

    if (result.ok) {
      const html = await result.text();
      this.updateHtml(html);
    }

    this.hideLoadingIndication();
  };
}
