import { Application } from 'stimulus';

import { html } from '@ftf/lib/templateTags';

import Alerts from '../alerts';

function renderApp() {
  document.body.innerHTML = html`
    <div data-controller="Alerts"></div>
  `;

  const app = Application.start();
  app.register('Alerts', Alerts);
}

function getBlock() {
  return document.body.querySelector('[data-controller="Alerts"]');
}

describe('Alerts block', () => {
  beforeEach(() => {
    renderApp();
  });

  afterEach(() => {
    document.body.innerHTML = '';
  });

  it('handles Alerts:add event', () => {
    const block = getBlock();

    expect(block.children.length).toBe(0);

    window.dispatchEvent(
      new CustomEvent('Alerts:add', {
        detail: { message: 'New Message' },
      }),
    );

    expect(block.children.length).toBe(1);
  });
});
