import { Controller } from 'stimulus';
import moment from 'moment';

const TIME_TO_COMPLETE_INVESTMENT = 5 * 60; // 5 minutes

export default class CountdownTimer extends Controller {
  static targets = ['time'];

  connect() {
    window.addEventListener('CountdownTimer:start', this.start);
    window.addEventListener('CountdownTimer:stop', this.stop);
  }

  disconnect() {
    window.removeEventListener('CountdownTimer:start', this.start);
    window.removeEventListener('CountdownTimer:stop', this.stop);
  }

  start = () => {
    this.expirationTime = moment().add(TIME_TO_COMPLETE_INVESTMENT, 'seconds');

    this.tick();
    this.timer = setInterval(this.tick, 1000);
  };

  stop = () => {
    clearInterval(this.timer);
  };

  tick = () => {
    const now = moment();
    const isExpired = now.isSameOrAfter(this.expirationTime, 'seconds');

    if (isExpired) {
      window.dispatchEvent(new Event('CountdownTimer:expired'));
      this.stop();
    }
  };
}
