import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class ClosingDealsTable extends Controller {
  initialize() {
    let nodes = document.querySelectorAll('th');
    let last = nodes[nodes.length - 1];
    let button = document.createElement('button');
    last.innerHTML = '';
    button.classList.add(
      'btn',
      'btn-sm',
      'btn-secondary',
      'soft-edges',
      'btn-checkall',
    );
    button.setAttribute(
      'data-action',
      'click->ClosingDealsTable#toggleCheckAll',
    );
    button.innerText = 'Select all';
    last.appendChild(button);
  }

  handleSubmit(e) {
    e.preventDefault();
    let urlParams = window.location.search;
    let checkboxes = document.querySelectorAll('.export-checkbox');
    let checked = [];

    checkboxes.forEach(item => {
      if (item.checked) {
        checked.push(item.value);
      }
    });

    Rails.ajax({
      type: 'get',
      url: `/admin/legal/deals/csv_export?deal_ids=${checked}`,
    });

    window.location = `/admin/legal/deals/csv_export?deal_ids=${checked}`;

    setTimeout(() => {
      window.location = `/admin/legal/deals${urlParams}`;
    }, 2000);
  }

  toggleCheckAll() {
    let button = document.querySelector('.btn-checkall');
    let checkboxes = document.querySelectorAll('.export-checkbox');
    if (button.innerText === 'Select all') {
      checkboxes.forEach(item => {
        if (item.checked === false) {
          item.checked = true;
        }
        button.innerText = 'Unselect all';
      });
    } else {
      checkboxes.forEach(item => {
        item.checked = false;
      });
      button.innerText = 'Select all';
    }
  }
}
