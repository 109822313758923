import { Controller } from 'stimulus';

export default class Carousel extends Controller {
  static targets = ['slide'];

  connect() {
    if (this.slideTargets.length > 1) {
      $(this.element).carousel({
        interval: false,
      });
    }

    $(this.element).on('slide.bs.carousel', this.handleSlideChange);
  }

  disconnect() {
    $(this.element).off('slide.bs.carousel', this.handleSlideChange);
  }

  handleSlideChange = e => {
    const img = $(e.relatedTarget).find('img[data-src]');

    if (img.attr('src').length > 0) {
      return;
    }

    img.attr('src', img.data('src'));
  };
}
