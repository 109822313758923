import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
import { debounce } from 'lodash';

export default class NewEntityModal extends Controller {
  static targets = ['form'];

  initialize() {
    this.debouncedHandleInput = debounce(this.checkForEntity, 2000);
  }

  connect() {
    $(this.formTarget).on('ajax:success', this.handleEntityCreate);
  }

  disconnect() {
    $(this.formTarget).off('ajax:success', this.handleEntityCreate);
    $(this.element).off('input', this.debouncedHandleInput);
  }

  handleEntityCreate = (e, data) => {
    window.dispatchEvent(
      new CustomEvent('NewEntityModal:success', {
        detail: data,
      }),
    );

    $(this.element).modal('hide');
  };

  checkForEntity = e => {
    let user = e.target.dataset.user;
    let ein = e.target.value;
    let dealId = window.location.pathname.replace(/[\D]/g, '');

    if (e.target.value.length >= 10) {
      Rails.ajax({
        type: 'get',
        url: `/admin/users/${user}/entities/${ein}/check_for_entity?deal_id=${dealId}`,
      });
    }
  };
}
