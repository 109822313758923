import { Controller } from 'stimulus';
import flatpickr from 'flatpickr';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';

export default class ServicingForm extends Controller {
  static targets = ['datePicker'];

  connect() {
    this.flatpickrInstance = flatpickr(this.datePickerTarget, {
      minDate: '2000-01',
      allowInput: true,
      plugins: [
        new monthSelectPlugin({
          dateFormat: 'Y-m',
          shorthand: true,
        }),
      ],
    });
  }

  disconnect() {
    if (this.flatpickrInstance) {
      this.flatpickrInstance.destroy();
    }
  }
}
